<template>
    <div class="d-flex flex-column flex-column-fluid" data-select2-id="select2-data-131-6dxb">
        <div class="app-toolbar py-3 py-lg-6" data-select2-id="select2-data-kt_app_toolbar">
            <div class="app-container container-xxl d-flex flex-stack"
                 data-select2-id="select2-data-kt_app_toolbar_container">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                        Menu</h1>
                </div>
                <div class="d-flex align-items-center gap-2 gap-lg-3">
                    <!--                    <a href="#" class="btn btn-sm fw-bold btn-primary" @click="addShow">Add New</a>-->
                </div>
            </div>
        </div>

        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl pt-4">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-body">
                                <h3>Menu List</h3>
                                <page-loader v-model:active="loader" v-bind="page_loader_config"/>
                                <!--begin::Table-->
                                <div class="dataTables_wrapper dt-bootstrap4 no-footer pe-2 overflow-scroll" style="height: calc(100vh - 270px)">
                                    <div v-for="menu in data_content">
                                        <div
                                            class="d-flex justify-content-between mb-2 py-2 border-bottom border-bottom-1">
                                            <div class="d-flex">
                                                <unicon :name="menu.icon" width="20" height="20"></unicon>
                                                <div class="ms-2">{{ menu.name }}</div>
                                            </div>
                                            <div class="text-theme fw-bold cursor-pointer" @click="loadDetail(menu)">
                                                edit
                                            </div>
                                        </div>
                                        <div v-if="menu.children">
                                            <div v-for="child in menu.children">
                                                <div
                                                    class="d-flex justify-content-between ms-6 mb-1 py-2 border-bottom">
                                                    <div class="d-flex">
                                                        <unicon name="angle-right" width="20" height="20"></unicon>
                                                        <div>{{ child.name }}</div>
                                                    </div>
                                                    <div>
                                                        <div class="text-theme fw-bold cursor-pointer"
                                                             @click="loadDetail(child)">
                                                            edit
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-body">
                                <h3 class="text-">Detail Menu</h3>
                                <div v-if="!data_detail.data.id">
                                    <i>Silakan pilih menu</i>
                                </div>
                                <div v-if="data_detail.data.id">
                                    <div class="mb-4">
                                        <div class="mb-2">
                                            <label>Nama</label>
                                            <input type="text" class="form-control" v-model="data_detail.data.name">
                                        </div>
                                        <div class="mb-2">
                                            <label>Link</label>
                                            <input type="text" class="form-control" v-model="data_detail.data.link">
                                        </div>
                                        <div class="mb-2">
                                            <label>Icon</label>
                                            <input type="text" class="form-control" v-model="data_detail.data.icon">
                                        </div>
                                    </div>

                                    <b>Akses Menu</b>
                                    <table class="table">
                                        <tr>
                                            <th>Menu</th>
                                            <th>Akses</th>
                                        </tr>
                                        <tr v-for="role in data_detail.roles">
                                            <td>{{ role.name }}</td>
                                            <td>
                                                <input type="checkbox" v-model="role.access">
                                            </td>
                                        </tr>
                                    </table>
                                    <div>
                                        <button class="btn btn-sm btn-primary" @click="updateRole"
                                                style="width: 100%">
                                            Simpan perubahan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loader: false,
            disabled: false,
            edit_mode: false,
            form: new form({
                id: '',
                name: '',
                desc: '',
            }),
            data_content: {},
            data_detail: {
                data: {},
                roles: [],
            },
            filter: {
                page: 1,
                per_page: 10,
                name: '',
            }
        }
    },
    methods: {
        loadDataContent(page = 1) {
            this.filter.page = page
            this.loader = true
            this.authGet('panel/menus', this.filter)
                .then((data) => {
                    this.data_content = data.result
                    this.loader = false
                })
        },
        updateRole() {
            this.authPost('panel/menu-roles', this.data_detail)
                .then((data) => {
                    this.toaster({title: data.message})
                    this.emitter.emit('reload-menu')
                    location.reload()
                })
        },
        addShow() {
            this.edit_mode = false;
            this.form.reset()
            $('#kt_modal_add_customer')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                }).modal('show')
        },
        addData() {
            this.disabled = true;
            this.form.post(this.base_api + 'roles', this.setHeader())
                .then(({data}) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#kt_modal_add_customer').modal('hide')
                        this.loadDataContent()
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        editModal(data) {
            this.edit_mode = true;
            this.form.fill(data)
            $('#kt_modal_add_customer')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                }).modal('show')
        },
        updateData() {
            this.disabled = true;
            this.form.patch(this.base_api + 'roles/' + this.form.id, this.setHeader())
                .then(({data}) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#kt_modal_add_customer').modal('hide')
                        this.loadDataContent()
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        deleteData() {
            if (confirm('Delete data?')) {
                this.disabled = true;
                this.form.delete(this.base_api + 'users/' + this.form.id, this.setHeader())
                    .then(({data}) => {
                        this.disabled = false;
                        if (data.status) {
                            $('#kt_modal_add_customer').modal('hide')
                            this.loadDataContent()
                        }
                    })
            }
        },
        loadDetail(data) {
            this.authGet('panel/menus/' + data.id)
                .then((data) => {
                    this.data_detail = data.result
                    console.log(data.result)
                })
        }
    },
    created() {
        this.loadDataContent();
    }
}
</script>