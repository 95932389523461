<template>
    <div class="d-flex flex-column flex-column-fluid" data-select2-id="select2-data-131-6dxb">
        <div class="app-toolbar py-4 py-lg-5">
            <div class="app-container container-xxl">
                <div class="d-flex">
                    <router-link to="/panel/transactions" class="ms-1 text-theme"> Transaksi</router-link>
                    <div class="ms-1">/</div>
                    <div class="ms-1"> Detail </div>
                </div>
                <div class="d-flex flex-stack my-3">
                    <div class="d-flex align-items-center">
                        <a v-if="data_detail.user_profile" :href="data_detail.user_profile.image" target="_blank">
                            <div class="profile-img"
                                 :style="'background-image: url(' + data_detail.user_profile.image + ')'"></div>
                        </a>
                        <div class="ms-3">
                            <div style="font-weight: 600;">{{ data_detail.user_name }}</div>
                            <div style="font-weight: 400;" class="color-theme d-flex align-items-baseline">
                                <unicon name="user" fill="#F78F2D" width="12" height="12" class="mb-1 me-1"></unicon>
                                <div style="font-size: 12px; line-height: 21px;" v-if="data_detail.user">
                                    {{ data_detail.user.id_display }}
                                </div>
                            </div>
                            <div class="color-theme d-flex align-items-baseline">
                                <unicon name="phone" fill="#F78F2D" width="12" height="12" class="mb-1 me-1"></unicon>
                                <div>
                                    {{data_detail.trx_phone}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="badge badge-primary">{{ data_detail.status_label }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="px-3">
            <div class="row">
                <div class="col" style="max-width: 800px; margin-left: auto; margin-right: auto;">
                    <div class="py-6">
                        <h4>Detail Transaksi</h4>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div>
                                <b>ID Transaksi: {{ data_detail.number }}</b>
                            </div>
                            <div>
                                <table class="table align-middle">
                                    <tr>
                                        <th>Qty</th>
                                        <th>Item</th>
                                        <th>Informasi</th>
                                        <th class="text-right">Harga</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            1
                                        </td>
                                        <td>
                                            {{ data_detail.relation_name }}
                                        </td>
                                        <td>

                                        </td>
                                        <td class="text-right">
                                            {{ $filters.currency(data_detail.price) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Pajak</td>
                                        <td>
                                            {{ data_detail.tax_percent }}%
                                        </td>
                                        <td class="text-right">
                                            {{ $filters.currency(data_detail.tax_amount) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Biaya penanganan</td>
                                        <td>

                                        </td>
                                        <td class="text-right">
                                            {{ $filters.currency(data_detail.service_fee) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Discount</td>
                                        <td>

                                        </td>
                                        <td class="text-right">
                                            <span v-if="data_detail.discount_amount">({{
                                                    $filters.currency(data_detail.discount_amount)
                                                }})</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <b class="p-0">Total</b>
                                        </td>
                                        <td class="text-right">
                                            <b class="p-0">{{ $filters.currency(data_detail.total) }}</b>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="row">
                                <div class="col-md-6 px-5">
                                    <table style="width: 100%;">
                                        <tr>
                                            <td class="text-bold">Date</td>
                                            <td class="text-right">{{
                                                    $filters.formatDate(data_detail.created_at)
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-bold">Time</td>
                                            <td class="text-right">{{
                                                    $filters.formatTime(data_detail.created_at)
                                                }}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-6  px-5">
                                    <table style="width: 100%;">
                                        <tr>
                                            <td class="text-bold">Metode Pembayaran</td>
                                            <td class="text-right">{{ data_detail.payment_method }}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-bold">Status</td>
                                            <td class="text-right">{{ data_detail.status_label }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-3">
                        <div class="card-body">
                            <div class="mb-3"><b>Detail Produk</b></div>
                            <div class="row mb-3">
                                <div class="col-md-6 p-2" v-if="data_detail.transaction_details" v-for="detail in data_detail.transaction_details">
                                    <table class="border rounded w-100">
                                        <tr>
                                            <td>Nama</td>
                                            <td>:</td>
                                            <td>
                                                <span v-if="detail">{{detail.model_name}}</span>
                                            </td>
                                        </tr>
                                        <tr v-if="detail.active_at">
                                            <td>Tanggal</td>
                                            <td>:</td>
                                            <td>
                                                <span>{{$filters.formatDateTime(detail.active_at)}}</span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-6 p-2" v-if="data_detail.type === 'training'">
                                    <table class="border rounded w-100">
                                        <tr>
                                            <td>Tanggal</td>
                                            <td>:</td>
                                            <td>
                                                <span v-if="data_detail.relation.product_detail">
                                                    {{$filters.formatDateTime(data_detail.relation.product_detail.date_start)}}
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-3" v-if="data_detail.pg_response !== null">
                        <div class="card-body">
                            <div class="mb-3"><b>Pembayaran Payment Gateway</b></div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <table>
                                        <tr>
                                            <td>Nama</td>
                                            <td>:</td>
                                            <td>
                                                <span v-if="data_detail.pg_response">{{data_detail.pg_response.name}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Tipe</td>
                                            <td>:</td>
                                            <td>
                                                <span v-if="data_detail.pg_response">{{data_detail.payment_method}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Kode</td>
                                            <td>:</td>
                                            <td>
                                                <span v-if="data_detail.pg_response">{{data_detail.pg_response.bank_code}}</span>
                                                <span v-if="data_detail.pg_response">{{data_detail.pg_response.channel_code}}</span>
                                            </td>
                                        </tr>

                                    </table>
                                </div>
                                <div class="col-md-6">
                                    <table>
                                        <tr>
                                            <td>Expired Session</td>
                                            <td>:</td>
                                            <td>
                                                <span v-if="data_detail.pg_response">{{$filters.formatDateTime(data_detail.pg_response.expiration_date)}}</span>
                                                <span v-if="data_detail.pg_response">{{data_detail.pg_response.expires_at}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Number</td>
                                            <td>:</td>
                                            <td>
                                                <span v-if="data_detail.pg_response">{{data_detail.pg_response.reference_id}}</span>
                                            </td>
                                        </tr>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-3" v-if="data_detail.payment_method === 'manual_transfer'">
                        <div class="card-body">
                            <div class="mb-3"><b>Pembayaran</b></div>
                            <div class="row mb-3" v-if="data_detail.payment">
                                <div class="col-md-6">
                                    <table style="width: 100%;">
                                        <tr>
                                            <td class="">Metode Pembayaran</td>
                                            <td class="text-right">{{ data_detail.payment.payment_method }}</td>
                                        </tr>
                                        <tr>
                                            <td class="">Status</td>
                                            <td class="text-right">{{ data_detail.payment.status_label }}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-md-6" v-if="data_detail.payment && data_detail.payment.photo_link">
                                    <a :href="data_detail.payment.photo_link" target="_blank">
                                        <img :src="data_detail.payment.photo_link" style="max-height: 100px;"
                                             alt="photo_link">
                                    </a>
                                </div>
                            </div>
                            <div class="text-right ">
                                <button class="btn btn-theme" @click="approvePayment()"
                                        v-if="data_detail.status === 111">
                                    Setujui
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            disabled: false,
            loader: false,
            data_detail: {},
            title: 'Transaksi'
        }
    },
    methods: {
        loadData() {
            this.loader = true;
            this.authGet('panel/transactions/' + this.$route.params.id)
                .then((data) => {
                    this.data_detail = data.result
                    this.loader = false;
                }).catch(() => {
                this.loader = false;
            })
        },
        approvePayment() {
            this.disabled = true;
            if (confirm('Transaksi diterima?')) {
                this.authPatch('panel/payment-approve/' + this.data_detail.payment.id)
                    .then((data) => {
                        if (data.status) {
                            this.loadData()
                        }
                        this.disabled = false;
                        this.toaster({title: data.message})
                    }).catch(() => {
                    this.toaster({title: 'something wrong'})
                    this.disabled = false;
                })
            }
        }
    },
    created() {
        this.loadData()
    }
}
</script>

<style scoped>
td {
    vertical-align: top;
}

.profile-img {
    width: 50px;
    height: 50px;
    background: #111;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(247, 143, 45);
}

.dot-online {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    top: 3px;
    right: 2px
}
.td{
    padding: 3px
}
</style>