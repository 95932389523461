<template>
    <div class="d-flex flex-column flex-column-fluid" data-select2-id="select2-data-131-6dxb">
        <div class="app-toolbar pt-4 pt-lg-5 mb-6">
            <div class="app-container container-xxl">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 class="page-heading d-flex text-dark fw-bold flex-column justify-content-center my-0">
                        {{ title }}
                    </h1>
                </div>
                <div class="d-flex justify-content-between my-3">
                    <div style="width: 40%">
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control" placeholder="Cari.."
                                   @keyup.enter="loadDataContent"
                                   v-model="filter.s">
                            <button class="btn btn-theme" @click="loadDataContent">
                                Cari
                            </button>
                        </div>
                    </div>
                    <div class="text-right">
                        <button @click="addModal"
                                class="btn btn-sm mx-1 btn-theme">Tambah
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl">
                <div class="card mb-5 mb-xl-8">
                    <div class="card-body py-8">
                        <page-loader v-model:active="loader" loader="dots" :is-full-page="false"/>
                        <div class="table-responsive">
                            <table class="table align-middle">
                                <thead>
                                <tr class="fw-bold text-muted bg-light">
                                    <th class="ps-4 text-center w-50px rounded-start">No</th>
                                    <th style="width: 30%">Nama</th>
                                    <th style="width: 30%">Slug</th>
                                    <th class="text-right">Aksi</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="data_content.total === 0">
                                    <td colspan="9" class="text-center">
                                        <i>tidak ada data</i>
                                    </td>
                                </tr>
                                <tr v-for="(data, i) in data_content.data" :key="i">
                                    <td class="ps-4 text-center border-bottom-1">
                                            <span class="text-dark fw-bold">
                                            {{ (data_content.current_page - 1) * data_content.per_page + i + 1 }}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="text-dark fw-bold">{{ data.name }}</span>
                                    </td>
                                    <td>
                                        {{ data.slug }}
                                    </td>
                                    <td class="text-right">
                                        <button @click="editModal(data)"
                                                class="m-1 btn btn-sm btn-theme">
                                            Edit
                                        </button>
                                        <button class="m-1 btn btn-xs btn-outline-theme" @click="deleteData(data)">
                                            Hapus
                                        </button>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="border-top-1 p-4 d-flex justify-content-end align-items-center">
                        <div class="me-3">
                            Total {{ data_content.total }}
                        </div>
                        <div>
                            <select class="form-control form-control-sm" v-model="filter.per_page"
                                    @change="loadDataContent">
                                <option value="10">10/Page</option>
                                <option value="25">25/Page</option>
                                <option value="50">50/Page</option>
                                <option value="100">100/Page</option>
                            </select>
                        </div>
                        <div class="mx-2">
                            <laravel-pagination :data="data_content" :limit="2"
                                                @paginationChangePage="loadDataContent"></laravel-pagination>
                        </div>
                        <div class="me-3">
                            Go to
                        </div>
                        <div>
                            <select class="form-control form-control-sm select-center" style="min-width: 80px;"
                                    v-model="filter.page">
                                <option :value="i" v-for="i in data_content.last_page">{{ i }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="add_product">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header py-3 px-5" id="kt_modal_add_customer_header">
                        <h3 class="fw-bold" v-if="!edit_mode">Tambah Produk</h3>
                        <h3 class="fw-bold" v-if="edit_mode">Perbarui Produk</h3>
                        <button data-bs-dismiss="modal"
                                class="btn close btn-icon btn-sm btn-active-icon-primary">
                                    <span class="svg-icon svg-icon-1">
                                        <unicon name="times"></unicon>
                                    </span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="form-label">Nama</label>
                                    <input type="text" class="form-control form-control-solid"
                                           v-model="form.name">
                                    <div class="error-text" v-if="parseErrors('name')">{{
                                            parseErrors('name', 'm')
                                        }}</div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="form-label">Slug</label>
                                    <input type="text" class="form-control form-control-solid"
                                           v-model="form.slug">
                                    <div class="error-text" v-if="parseErrors('slug')">{{
                                            parseErrors('slug', 'm')
                                        }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer flex-center">
                        <button @click="addData" v-if="!edit_mode" :disabled="disabled"
                                class="btn btn-theme">
                            <BounceLoader :loading="disabled" color="#fff" size="22px"></BounceLoader>
                            <span v-if="!disabled" class="indicator-label">Tambah</span>
                        </button>
                        <button @click="updateData" v-if="edit_mode" :disabled="disabled"
                                class="btn btn-theme">
                            <BounceLoader :loading="disabled" color="#fff" size="22px"></BounceLoader>
                            <span v-if="!disabled" class="indicator-label">Perbarui</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            edit_mode: false,
            loader: false,
            disabled: false,
            errors: [],
            form: new form({
                id: '',
                name: '',
                slug: '',
                status: 1,
                section: 'package',
            }),
            data_content: {},
            filter: {
                page: 1,
                per_page: 25,
                name: '',
                status: 1,
                s: '',
                section: 'package',
            },
            title: 'Produk Legal 2 Pro'
        }
    },
    methods: {
        loadDataContent(page = 1) {
            this.filter.page = page
            this.loader = true
            this.authGet('panel/products', this.filter)
                .then((data) => {
                    this.data_content = data
                    this.loader = false
                })
        },
        deleteData(data) {
            if (confirm('Delete data?')) {
                this.disabled = true;
                this.authDelete('panel/products/' + data.id)
                    .then((data) => {
                        if (data.status) {
                            this.toaster({title: data.message})
                            this.loadDataContent()
                        }
                        this.disabled = false;
                    }).catch(() => {
                    this.disabled = false;
                })
            }
        },
        addModal() {
            this.form = {
                id: '',
                name: '',
                slug: '',
                status: 1,
                section: 'package',
            }
            this.edit_mode = false;
            $('#add_product')
                .modal('show')
        },
        addData() {
            this.disabled = true;
            this.authPost('panel/products', this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({title: data.message})
                        $('#add_product').modal('hide')
                        this.loadDataContent()
                    }else {
                        this.errors = data.errors
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        updateData() {
            this.disabled = true;
            this.authPatch('panel/products/' + this.form.id, this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({title: data.message})
                        $('#add_product').modal('hide')
                        this.loadDataContent()
                    } else {
                        this.errors = data.errors
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        editModal(data) {
            this.form = data
            this.edit_mode = true;

            $('#add_product')
                .modal('show')
        },
        parseErrors(field, type = 'status') {
            let has = false;
            let message = '';
            if (this.errors[field]) {
                message = this.errors[field][0];
                has = true;
            }

            if (type === 'status') {
                return has;
            } else {
                return message;
            }
        },
    },
    created() {
        this.loadDataContent()
    },
}
</script>

<style scoped>

</style>