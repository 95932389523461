<template>
    <div>
        <div class="card  mb-3">
            <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                    <h3>Kategori Harga</h3>
                    <button class="btn btn-sm btn-theme" @click="addModal">Tambah</button>
                </div>
                <div class="row mt-3">
                    <div class="col-md-4 mb-4" v-for="detail in product_details">
                        <div class="border border-theme p-4 rounded">
                            <div class="d-flex justify-content-between">
                                <b>{{ detail.name }}</b>
                                <div class="cursor-pointer text-theme text-bold" @click="modalEdit(detail)">edit</div>
                            </div>
                            <div>
                                Rp {{ $filters.currency(detail.price) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade show" id="modal_edit" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header py-3 px-5" id="kt_modal_add_customer_header">
                        <h3 class="fw-bold" v-if="edit_mode">Perbarui Harga</h3>
                        <h3 class="fw-bold" v-if="!edit_mode">Tambah Harga</h3>
                        <button data-bs-dismiss="modal"
                                class="btn close btn-icon btn-sm btn-active-icon-primary">
                            <span class="svg-icon svg-icon-1">
                                <unicon name="times"></unicon>
                            </span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Nama</label>
                            <input type="text" class="form-control" v-model="form.name">
                        </div>
                        <div class="form-group">
                            <label>Harga</label>
                            <money3 v-bind="money_config" class="form-control" v-model="form.price"></money3>
                        </div>
                        <div class="text-right">
                            <button class="btn btn-sm btn-theme mx-1" v-if="edit_mode" :disabled="disabled"
                                    @click="updateData">
                                <span v-if="!disabled">Perbarui</span>
                                <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
                            </button>
                            <button class="btn btn-sm btn-outline-theme mx-1" v-if="edit_mode" :disabled="disabled"
                                    @click="deleteData">
                                <span v-if="!disabled">Hapus</span>
                                <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
                            </button>
                            <button class="btn btn-sm btn-theme" v-if="!edit_mode" :disabled="disabled"
                                    @click="createData">
                                <span v-if="!disabled">Tambah</span>
                                <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: ['product_id'],
    data() {
        return {
            disabled: false,
            edit_mode: false,
            product_details: [],
            form: {
                id: '',
                product_id: '',
                name: '',
                price: '',
            }
        }
    },
    methods: {
        loadData() {
            this.authGet('panel/product-details', {
                product_id: this.$route.params.product_id,
            })
                .then((data) => {
                    this.product_details = data.result
                    this.loader = false
                })
        },
        modalEdit(data) {
            this.form = data
            this.edit_mode = true
            $('#modal_edit')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                })
                .modal('show')
        },
        addModal() {
            this.form = {}
            this.edit_mode = false
            $('#modal_edit')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                }).modal('show')
        },
        updateData() {
            this.disabled = true;
            this.authPatch('panel/product-details/' + this.form.id, this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#modal_edit').modal('hide')
                        this.loadData()
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        createData() {
            this.disabled = true;
            this.form.product_id = this.product_id
            this.authPost('panel/product-details', this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#modal_edit').modal('hide')
                        this.loadData()
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        deleteData() {
            if (confirm('Hapus data?')) {
                this.authDelete('panel/product-details/' + this.form.id)
                    .then((data)=>{
                        $('#modal_edit').modal('hide')
                        this.loadData()
                    })
            }
        }
    },
    created() {
        this.loadData()
    }
}
</script>