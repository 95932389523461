<template>
    <div class="d-flex flex-column flex-column-fluid" data-select2-id="select2-data-131-6dxb">
        <div class="app-toolbar pt-4 pt-lg-5 mb-6">
            <div class="app-container container-xxl">
                <div class="me-3">
                    <router-link to="/panel/members" class="text-theme cursor-pointer">Informasi Pengguna</router-link>
                    / Detail
                </div>
                <div class="d-flex my-4 justify-content-between">
                    <div class="d-flex my-0 align-items-center">
                        <div>
                            <a :href="data_detail.image" target="_blank">
                                <div class="profile-img"
                                     :style="'background-image: url(' + data_detail.image +')'"></div>
                            </a>
                        </div>
                        <div class="ms-3">
                            <span class="fw-bold">{{ data_detail.name }}</span> <br>
                            <small>ID: {{ data_detail.id_display }}</small>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </div>
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl">
                <page-loader v-model:active="loader" loader="dots" :is-full-page="false"/>
                <div class="card mb-4 mb-xl-8">
                    <div class="card-body py-8">
                        <h4>Detail Pengguna</h4>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Nama</label>
                                    <div class="form-control">{{ data_detail.name }}</div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Email</label>
                                    <div class="form-control">{{ data_detail.email }}</div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Tanggal Registrasi</label>
                                    <div class="form-control">{{ $filters.formatDate(data_detail.created_at) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-4 mb-xl-8">
                    <div class="card-body py-8">
                        <h4>Paket Berlangganan</h4>
                        <div v-if="!active_package">
                            <div class="form-control">Belum berlangganan</div>
                        </div>
                        <div class="row" v-if="active_package">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Nama Paket</label>
                                    <div class="form-control">{{ active_package.relation_name }}</div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Masa Berlaku</label>
                                    <div class="form-control">{{
                                            $filters.formatDate(active_package.deactivate_at)
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-4 mb-xl-8" v-if="active_package">
                    <div class="card-body py-8">
                        <h4>Informasi Akses</h4>
                        <div class="row">
                            <div class="col-md-4 my-2" v-for="detail in active_package.transaction_details">
                                <div class="border rounded p-3 border-theme">
                                    <div class="d-flex justify-content-between">
                                        <b>{{ detail.model_name }}</b>
                                        <small class="fw-bold cursor-pointer text-theme" v-if="detail.quota > 0"
                                               @click="editModal(detail)">Edit</small>
                                    </div>
                                    <div v-if="detail.quota < 0" class="text-theme fs-2">
                                        Unlimited
                                    </div>
                                    <div v-if="detail.quota > 0" class="fw-bold fs-2">
                                        <span class="text-theme">{{ detail.used_quota }}</span> / {{ detail.quota }}
                                    </div>
                                    <div>{{ $filters.formatDate(detail.deactivate_at) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="card mb-4 mb-xl-8">
                    <div class="card-body py-8">
                        <h4>Aktifitas Pengguna</h4>
                        <div class="line">
                            <div class="d-flex timeline-container mb-5" v-for="log in data_logs">
                                <div class="profile-img timeline" v-if="log.image"
                                     :style="'background-image: url('+ log.image +')'"></div>
                                <div class="profile-img timeline" v-if="!log.image"
                                     :style="'background-image: url('+ default_image +')'">
                                </div>
                                <div class="ms-3">
                                    <div>{{ log.activity }}</div>
                                    <small class="text-gray-700">{{ $filters.formatDateTime(log.created_at) }}</small>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="modal fade" id="modal_edit" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header py-3 px-5" id="kt_modal_add_customer_header">
                        <h3 class="fw-bold">Update Layanan</h3>
                        <button data-bs-dismiss="modal"
                                class="btn close btn-icon btn-sm btn-active-icon-primary">
                            <span class="svg-icon svg-icon-1">
                                <unicon name="times"></unicon>
                            </span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <h3 class="border-bottom border-bottom-2">{{ form.model_name }}</h3>
                        <div
                            class="d-flex justify-content-between py-2 align-items-center border-bottom border-bottom-2">
                            <div>
                                Kuota
                            </div>
                            <div class="d-flex align-items-center">
                                <button class="btn btn-sm btn-outline-theme mx-1" @click="form.quota--">-</button>
                                <div class="mx-2 fs-2">{{ form.quota }}</div>
                                <button class="btn btn-sm btn-outline-theme mx-1" @click="form.quota++">+</button>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between py-2 align-items-center  border-bottom-1">
                            <div>
                                Kuota Terpakai
                            </div>
                            <div class="d-flex">
                                <button class="btn btn-sm btn-outline-theme mx-1" @click="useQuota('sub')">-</button>
                                <div class="mx-2 fs-2">{{ form.used_quota }}</div>
                                <button class="btn btn-sm btn-outline-theme mx-1" @click="useQuota('add')">+</button>
                            </div>
                        </div>
                        <div class="mt-3">
                            <button @click="updateData" :disabled="disabled"
                                    class="btn btn-sm btn-theme" style="width: 100%">
                                <BounceLoader :loading="disabled" color="#fff" size="22px"></BounceLoader>
                                <span v-if="!disabled" class="indicator-label">Perbarui</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loader: false,
            disabled: false,
            edit_mode: false,
            data_logs: false,
            active_package: null,
            default_image: 'https://l2us-res.untaravc.my.id//assets/images/demo/img43.png',
            data_detail: {
                created_at: '',
                image: '',
            },
            filter: {
                limit: 10
            },
            form: {
                id: "",
                relation_name: "",
                quota: "",
                used_quota: "",
            }
        }
    },
    methods: {
        loadData() {
            this.loader = true
            this.authGet('panel/users/' + this.$route.params.user_id)
                .then((data) => {
                    this.data_detail = data.result
                    this.loader = false
                })
        },
        loadLogs() {
            this.authGet('panel/user-logs/' + this.$route.params.user_id, this.filter)
                .then((data) => {
                    this.data_logs = data.result
                })
        },
        loadActivePackage() {
            this.authGet('panel/user-active-package/' + this.$route.params.user_id)
                .then((data) => {
                    this.active_package = data.result
                })
        },
        editModal(data) {
            $("#modal_edit").modal("show")
            this.form = data
        },
        updateData() {
            this.disabled = true;
            this.authPatch('panel/user-transaction-detail/' + this.form.id, {
                quota: this.form.quota,
                used_quota: this.form.used_quota,
            })
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#modal_edit').modal('hide')
                        this.loadActivePackage()
                        this.toaster({title: data.message})
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        useQuota(type) {
            if (type === 'sub') {
                if (this.form.used_quota > 0) {
                    this.form.used_quota--
                }
            } else {
                if (this.form.used_quota < this.form.quota) {
                    this.form.used_quota++
                }
            }
        }
    },
    created() {
        this.loadData()
        this.loadLogs()
        this.loadActivePackage()
    },
    watch: {},
    computed: {
        register_date() {
            return this.data_detail.created_at.slice(0, 10)
        }
    }
}
</script>

<style scoped>
.profile-img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: gray;
}

.line {
    position: relative;
}
</style>