<template>
    <div class="d-flex flex-column flex-column-fluid" data-select2-id="select2-data-131-6dxb">
        <div class="app-toolbar pt-4 pt-lg-5 mb-6">
            <div class="app-container container-xxl">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    Legal 2 Pro
                </div>
                <div class="d-flex flex-stack my-3">
                    <h1 class="page-heading d-flex text-dark fw-bold flex-column justify-content-center my-0">
                        Daftar Paket
                    </h1>
                    <div>
                        <button class="btn btn-outline-theme mx-1" @click="deleteData">
                            Hapus
                        </button>
                        <button class="btn btn-theme mx-1" @click="addModal">
                            Tambah Paket
                        </button>
                    </div>
                </div>
                <div>
                    <div class="">
                        <button v-for="pack in data_content" class="btn btn-sm" style="border-radius: 0;" :class="filter.package_id == pack.id ? 'btn-tab' : ''"
                            @click="loadData(pack.id)">{{ pack.name }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl">
                <div class="card mb-5 mb-xl-8">
                    <div class="card-body py-8">
                        <div class="d-flex justify-content-between">
                            <h5>Informasi Paket</h5>
                            <b class="text-theme cursor-pointer" @click="editModal(data_detail)">Edit</b>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Nama Paket</label>
                                    <input type="text" disabled v-model="data_detail.name" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Harga</label>
                                    <money3 v-bind="money_config" disabled v-model="data_detail.monthly_price" class="form-control"></money3>
                                </div>
                            </div>
                            <div class="col-md-4"></div>
                            <div class="col-md-12">
                                <label>Deskripsi</label>
                                <div v-html="data_detail.desc"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Detail @reload-pacage="loadData" :package_details="data_detail.package_details" :package_id="data_detail.id"></Detail>
            </div>
        </div>

        <div class="modal fade" id="add_package">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header py-3 px-5" id="kt_modal_add_customer_header">
                        <h3 class="fw-bold" v-if="!edit_mode">Tambah Paket</h3>
                        <h3 class="fw-bold" v-if="edit_mode">Perbarui Paket</h3>
                        <button data-bs-dismiss="modal"
                                class="btn close btn-icon btn-sm btn-active-icon-primary">
                                    <span class="svg-icon svg-icon-1">
                                        <unicon name="times"></unicon>
                                    </span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-label">Nama</label>
                                    <input type="text" class="form-control form-control-solid"
                                           v-model="form.name">
                                    <span class="error-text" v-if="parseErrors('name')">{{ parseErrors('name', 'm') }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-label">Harga</label>
                                    <money3 v-bind="money_config" class="form-control form-control-solid"
                                            v-model="form.monthly_price"></money3>
                                    <span class="error-text" v-if="parseErrors('monthly_price')">{{ parseErrors('monthly_price', 'm') }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-label">Nomor Level</label>
                                    <input type="number" class="form-control form-control-solid"
                                           v-model="form.hierarchy_number">
                                    <span class="error-text" v-if="parseErrors('hierarchy_number')">{{ parseErrors('hierarchy_number', 'm') }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-label">Tampilkan tag "termasuk paket sebelumnya."</label>
                                    <select class="form-control form-control-solid"
                                           v-model="form.is_hierarchy_role">
                                        <option value="1">Ya</option>
                                        <option value="0">Tidak</option>
                                    </select>
                                    <span class="error-text" v-if="parseErrors('is_hierarchy_role')">{{ parseErrors('is_hierarchy_role', 'm') }}</span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <label class="form-label">Deskripsi</label>
                                <VueEditor v-model="form.desc"></VueEditor>
                                <span class="error-text" v-if="parseErrors('desc')">{{ parseErrors('desc', 'm') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer flex-center">
                        <button @click="addData" v-if="!edit_mode" :disabled="disabled"
                                class="btn btn-theme">
                            <BounceLoader :loading="disabled" color="#fff" size="22px"></BounceLoader>
                            <span v-if="!disabled" class="indicator-label">Tambah</span>
                        </button>
                        <button @click="updateData" v-if="edit_mode" :disabled="disabled"
                                class="btn btn-theme">
                            <BounceLoader :loading="disabled" color="#fff" size="22px"></BounceLoader>
                            <span v-if="!disabled" class="indicator-label">Perbarui</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Detail from './Detail'
import {VueEditor} from "vue3-editor";
export default {
    components:{Detail, VueEditor},
    data() {
        return {
            loader: false,
            disabled: false,
            edit_mode: false,
            pswd_show: false,
            errors: {},
            value: '',
            selected_radio: 1,
            form: {
                id: '',
                name: '',
                monthly_price: '',
                hierarchy_number: '',
                is_hierarchy_role: '',
                status: 1,
                desc: '',
            },
            data_content: {},
            data_detail: {
                package_details:[]
            },
            data_raw: {
                categories: [],
            },
            filter: {
                package_id: 1,
            },
            title: 'Informasi Pengguna'
        }
    },
    methods: {
        loadDataContent(first_row = null) {
            this.loader = true
            this.authGet('panel/packages', this.filter)
                .then((data) => {
                    this.data_content = data.data
                    this.loader = false

                    if(first_row != null){
                        this.filter.package_id = data.data[0].id
                    }

                    this.loadData(this.filter.package_id)
                })
        },
        loadData(package_id) {
            this.filter.package_id = package_id;
            this.authGet('panel/packages/' + package_id)
                .then((data) => {
                    this.data_detail = data.result
                })
        },
        addModal() {
            this.edit_mode = false;
            this.form = {
                id: '',
                name: '',
                monthly_price: '',
                hierarchy_number: '',
                is_hierarchy_role: '',
                status: 1,
            }

            $('#add_package')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                }).modal('show')
        },
        addData() {
            this.disabled = true;
            this.authPost('panel/packages', this.form)
                .then(( data ) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#add_package').modal('hide')
                        this.loadDataContent()
                    } else {
                        this.errors = data.errors
                    }
                }).catch(() => {
                    this.disabled = false;
                })
        },
        editModal(data) {
            this.edit_mode = true;
            this.form = data

            $('#add_package')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                }).modal('show')
        },
        updateData() {
            this.disabled = true;
            this.authPatch('panel/packages/' + this.form.id, this.form)
                .then(( data ) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#add_package').modal('hide')
                        this.toaster({title: data.message})
                        this.loadDataContent()
                    } else {
                        this.errors = data.errors
                    }
                }).catch(() => {
                    this.disabled = false;
                })
        },
        deleteData() {
            if (confirm('Delete data?')) {
                this.disabled = true;
                this.authDelete('panel/packages/' + this.data_detail.id)
                    .then(( data ) => {
                        this.disabled = false;
                        if (data.status) {
                            $('#add_package').modal('hide')
                            this.loadDataContent(true)
                        }
                    })
            }
        },
        pswdTogle() {
            this.pswd_show = !this.pswd_show
        },
        updateTitle() {
            let name = this.$route.query.section
            if (name) {
                this.title = name.replace('_', ' ')
            }

            switch (name) {
                case 'Ruang_Kelas':
                    this.filter.section = 'class_room'
                    break;
                case 'Pelatihan':
                    this.filter.section = 'training'
                    break;
                default:
                    this.filter.section = 'article'
                    break;
            }
        },
        parseErrors(field, type = 'status') {
            let has = false;
            let message = '';
            if (this.errors[field]) {
                message = this.errors[field][0];
                has = true;
            }

            if (type === 'status') {
                return has;
            } else {
                return message;
            }
        }
    },
    created() {
        this.updateTitle()
        this.loadDataContent()
    },
    watch: {
        $route(to) {
            this.filter.div = to.query.div
            this.updateTitle()
            this.loadDataContent()
        },
        'filter.status'(val) {
            this.loadDataContent()
        }
    }
}
</script>

<style scoped>


.profile-img {
    width: 35px;
    height: 35px;
    background: #111;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.dot-online {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    top: 3px;
    right: 2px
}
</style>