<template>
    <div class="d-flex flex-column flex-column-fluid">
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl p-5">
                <div class="card mb-5 p-5">
                    <div class="border-bottom-2 border-bottom p-3" v-for="data in data_content">
                        <div>Tipe: {{data.type}}</div>
                        <div>Status: {{data.status}}</div>
                        <div>{{$filters.formatDateTime(data.created_at)}}</div>
                        <div class="p-3">
                            <vue-json-pretty :data="data.body"></vue-json-pretty>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
export default {
    data() {
        return {
            data_content: []
        }
    },
    components:{VueJsonPretty},
    methods: {
        loadDataContent() {
            this.authGet('panel/activity-logs')
                .then((data) => {
                    this.data_content = data.result
                })
        }
    },
    created() {
        this.loadDataContent()
    }
}
</script>