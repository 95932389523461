<template>
    <div class="d-flex flex-column flex-root" style="background-color: #9450F2; height: 100vh" id="kt_app_root">
        <div class="d-flex justify-content-center">
            <div
                class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
                <div class="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-450px p-15">
                    <div class="d-flex flex-center flex-column flex-column-fluidpb-15 pb-lg-20">
                        <div class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                            <div class="text-center mb-11">
                                <img src="/assets/img/logo-sqr-orange.png" style="max-height: 80px" alt="">
                            </div>

                            <div>
                                <h1>Masuk</h1>
                                <p>Selamat datang di Web Admin Legal2Us. Silakan masuk menggunakan email & kata sandi
                                    yang telah didaftarkan.</p>
                            </div>


                            <div class="fv-row mb-8 fv-plugins-icon-container">
                                <label for="">Email</label>
                                <input type="text" placeholder="Email" name="email" autocomplete="off"
                                       v-model="email"
                                       class="form-control bg-transparent">
                                <div class="fv-plugins-message-container invalid-feedback"></div>
                            </div>

                            <div class="fv-row mb-8 fv-plugins-icon-container">
                                <label for="">Password</label>
                                <input type="password" placeholder="Password" name="password" autocomplete="off"
                                       v-model="password"
                                       class="form-control bg-transparent">
                                <div class="fv-plugins-message-container invalid-feedback"></div>
                            </div>
                            <div class="d-grid mb-10">
                                <button :disabled="disabled" class="btn btn-theme" @click="login">
                                    <span v-if="!disabled" class="indicator-label">Masuk</span>
                                    <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
                                </button>
                            </div>

                        </div>

                    </div>

                </div>
                <!--end::Card-->
            </div>
            <!--end::Body-->
        </div>
    </div>

</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
    components: {PulseLoader},
    data() {
        return {
            disabled: false,
            email: '',
            password: '',
            error: '',
        }
    },
    methods: {
        login() {
            this.error = '';
            this.disabled = true;
            this.apiPost('panel/login', {email: this.email, password: this.password})
                .then((data) => {
                    if (data.status) {
                        localStorage.setItem('user_token', data.result.token)
                        localStorage.setItem('user', JSON.stringify({
                            id: data.result.user.id,
                            name: data.result.user.name,
                        }))
                        window.location = '/panel/home'
                    } else {
                        alert('Email atau password salah!');
                    }
                    this.disabled = false;
                })
        },
    },
    created() {
        // this.apiPost('test')
    }
}
</script>

<style>
.login-bg {
    background-image: url('../../assets/img/bg4.jpg');
    min-height: 100vh;
    background-position: top;
}
</style>