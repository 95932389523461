<template>
    <div>
        <a href="/panel/home"></a>
    </div>
</template>
<script>
export default {
    created() {
        this.$router.push('/panel/home')
    }
}
</script>