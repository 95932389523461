<template>
    <div class="d-flex flex-column flex-column-fluid p-4">
        <div class="card mb-20">
            <div class="card-body">
                <h3 v-if="!edit_mode">Tambah Pusat Data</h3>
                <h3 v-if="edit_mode">Edit Pusat Data</h3>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group mb-5">
                            <div class="mb-2">Kategori</div>
                            <select v-model="form.category_id" class="form-control" style="">
                                <option :value="cat.id" v-for="cat in data_raw.categories">{{ cat.name }}</option>
                            </select>
                            <span class="error-text" v-if="parseErrors('category_id')">{{
                                parseErrors('category_id', 'm')
                                }}</span>
                        </div>
                        <div class="mb-2">Tipe Akses</div>
                        <div class="d-flex">
                            <div class="form-group me-5">
                                <input type="radio" id="html" name="fav_language" value="public"
                                    v-model="data_raw.type_access">
                                <label for="html" class="ms-2">Publik</label>
                            </div>
                            <div class="form-group me-5">
                                <input type="radio" id="css" name="fav_language" value="member"
                                    v-model="data_raw.type_access">
                                <label for="css" class="ms-2">Member</label>
                            </div>
                            <div class="form-group me-5">
                                <input type="radio" id="javascript" name="fav_language" value="pro"
                                    v-model="data_raw.type_access">
                                <label for="javascript" class="ms-2">Pro</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Judul</label>
                            <textarea class="form-control" v-model="form.title" style=""></textarea>
                            <span class="error-text" v-if="parseErrors('title')">{{
                                parseErrors('title', 'm')
                                }}</span>
                        </div>
                        <div class="form-group" v-if="form.category_id == jurnal_id">
                            <label>Penulis</label>
                            <textarea class="form-control" v-model="form.post_detail.authors"></textarea>
                        </div>
                        <div class="form-group" v-if="form.category_id != jurnal_id">
                            <label>Sub Judul</label>
                            <textarea class="form-control" v-model="form.subtitle"></textarea>
                            <span class="error-text" v-if="parseErrors('subtitle')">{{
                                parseErrors('subtitle', 'm')
                            }}</span>
                        </div>
                        <div class="form-group" v-if="form.category_id == jurnal_id">
                            <label>Kata Kunci</label>
                            <textarea class="form-control" v-model="form.post_detail.keyword"></textarea>
                        </div>
                        <div class="form-group" v-if="form.category_id == jurnal_id">
                            <label>Abstract</label>
                            <textarea class="form-control" v-model="form.post_detail.abstract" rows="10"></textarea>
                        </div>
                        <div class="form-group position-relative" v-if="form.category_id != jurnal_id">
                            <label>File</label> <br>
                            <a :href="form.post_detail.file" target="_blank" class="btn btn-sm btn-outline-theme my-2"
                                v-if="form.post_detail.file">
                                {{ $filters.truncate(form.post_detail.file, 30, '...', false) }}
                            </a>
                            <label for="file-upload" class="btn btn-theme btn-sm mx-1">Upload File (.pdf)</label>
                            <page-loader v-model:active="upload_loader" loader="dots" :is-full-page="false" />
                            <input type="file" class="form-control form-control-sm" hidden accept="application/pdf"
                                id="file-upload" style="max-width: 300px" @change="uploadFile">
                            <span v-if="upload_loader">({{ upload_percentage }}%)</span>
                            <div class="error-text" v-if="parseErrors('post_detail.file')">{{
                                parseErrors('post_detail.file', 'm')
                                }}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-6" v-if="form.category_id != jurnal_id">
                                <div class="form-group">
                                    <label>Ditetapkan</label>
                                    <input type="date" class="form-control" v-model="form.post_detail.active_at">
                                </div>
                            </div>
                            <div class="col-md-6" v-if="form.category_id != jurnal_id">
                                <div class="form-group">
                                    <label>Berlaku</label>
                                    <input type="date" class="form-control" v-model="form.post_detail.release_at">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Diterbitkan</label>
                                    <input type="date" class="form-control" v-model="form.release_date">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Status</label>
                                    <select v-model="form.status" class="form-control">
                                        <option value="1">Publish</option>
                                        <option value="0">Draft</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6" v-if="form.category_id == jurnal_id">
                        <div class="form-group">
                            <label>Tipe Jurnal</label>
                            <select class="form-control mb-2" v-model="form.category_type_id">
                                <option :value="type.id" v-for="type in data_raw.jurnal_types">
                                    {{ type.name }}
                                </option>
                            </select>
                            <div v-if="data_raw.jurnal_type_image" class="text-center">
                                <img :src="data_raw.jurnal_type_image" style="height: 70px;" alt="">
                            </div>
                        </div>
                        <div class="form-group">
                            <label>No Issue</label>
                            <input type="text" class="form-control" v-model="form.post_detail.resource_version">
                        </div>
                        <div class="form-group">
                            <label>p-ISSN</label>
                            <input type="text" class="form-control" v-model="form.post_detail.resource_number_p">
                        </div>
                        <div class="form-group">
                            <label>e-ISSN</label>
                            <input type="text" class="form-control" v-model="form.post_detail.resource_number_e">
                        </div>
                        <div class="form-group">
                            <label>Info Penerbit</label>
                            <input type="text" class="form-control" v-model="form.post_detail.resource_name">
                        </div>
                        <div class="form-group">
                            <label>Tautan Asal</label>
                            <input type="text" class="form-control" v-model="form.post_detail.resource_link">
                        </div>
                        <div class="form-group">
                            <label>Tautan Penulis</label>
                            <input type="text" class="form-control" v-model="form.post_detail.resource_name_link">
                        </div>
                        <div class="form-group position-relative" v-if="form.category_id == jurnal_id">
                            <label>File</label> <br>
                            <a :href="form.post_detail.file" target="_blank" class="btn btn-sm btn-outline-theme my-2"
                                v-if="form.post_detail.file">
                                {{ $filters.truncate(form.post_detail.file, 30, '...', false) }}
                            </a>
                            <label for="file-upload" class="btn btn-theme btn-sm mx-1">Upload File (.pdf)</label>
                            <page-loader v-model:active="upload_loader" loader="dots" :is-full-page="false" />
                            <span v-if="upload_loader">({{ upload_percentage }}%)</span>
                            <input type="file" class="form-control form-control-sm" hidden accept="application/pdf"
                                id="file-upload" style="max-width: 300px" @change="uploadFile">
                            <div class="error-text" v-if="parseErrors('post_detail.file')">{{
                                parseErrors('post_detail.file', 'm')
                                }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white position-fixed text-right shadow py-3 pe-10" style="left: 0; bottom: 0; width: 100%;">
            <button @click="$router.go(-1)" class="btn btn-sm btn-outline-theme mx-1">Kembali</button>
            <button class="btn btn-sm btn-theme mx-1" v-if="!edit_mode" :disabled="disabled" @click="createData">
                <span v-if="!disabled">Tambah</span>
                <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
            </button>
            <button class="btn btn-sm btn-theme mx-1" v-if="edit_mode" :disabled="disabled" @click="updateData">
                <span v-if="!disabled">Perbarui</span>
                <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
            </button>
        </div>
    </div>
</template>

<script>
import { VueEditor } from "vue3-editor";

export default {
    data() {
        return {
            edit_mode: false,
            disabled: false,
            upload_loader: false,
            upload_percentage: 0,
            data_raw: {
                type_access: 'public',
                categories: [],
                jurnal_types: [],
                link: '',
                jurnal_type_image: '',
            },
            data_detail: {
                post_detail: {}
            },
            form: {
                id: '',
                title: '',
                body: '',
                section: 'data_center',
                release_date: '',
                is_pro: '',
                is_login: '',
                image: '',
                author_id: '',
                author_name: '',
                category_id: '',
                category_type_id: '',
                status: 1,
                file: '',
                active_at: '',
                release_at: '',
                post_detail: {
                    post_id: '',
                    file: '',
                    files: '',
                    active_at: '',
                    release_at: '',
                    abstract: '',
                    keyword: '',
                    authors: '',
                    resource_link: '',
                    resource_version: '',
                    resource_number: '',
                    resource_number_e: '',
                    resource_number_p: '',
                    resource_name: '',
                    resource_name_link: '',
                    resource_logo: '',
                }
            },
            errors: {}
        }
    },
    methods: {
        loadData(post_id = null) {
            this.authGet('panel/posts/' + (post_id != null ? post_id : this.$route.params.post_id))
                .then((data) => {
                    this.form = data.result
                    this.parseData()
                    this.loadCat()
                });
        },
        loadCat() {
            this.authGet('panel/categories-list?section=data_center')
                .then((data) => {
                    this.data_raw.categories = data.result
                });

            this.authGet('panel/categories-list?section=jurnal-type')
                .then((data) => {
                    this.data_raw.jurnal_types = data.result
                    this.parseCat()
                });
        },
        parseData() {
            let is_pro = this.form.is_pro;
            let is_login = this.form.is_login;

            if (is_pro) {
                this.data_raw.type_access = 'pro';
            } else if (is_login) {
                this.data_raw.type_access = 'member';
            } else {
                this.data_raw.type_access = 'public';
            }

            this.parseCat()
        },
        handleImageAdded(file, Editor, cursorLocation, resetUploader) {
            var formData = new FormData();
            formData.append("file", file);
            formData.append("save_method", 'upload');

            this.authPost('panel/docs', formData)
                .then((data) => {
                    const url = data.result.link; // Get url from response
                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                })
                .catch(err => {

                });
        },
        uploadFile() {
            this.upload_loader = true;
            let file = document.getElementById("file-upload").files[0];
            if (file) {
                let form_data = new FormData();

                form_data.append('save_method', 'upload')
                form_data.append('file', file)

                let token = localStorage.getItem('user_token')

                this.$axios.post(this.base_api + 'panel/docs', form_data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: 'Bearer ' + token
                    },
                    onUploadProgress: function (progressEvent) {
                        this.upload_percentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                    }.bind(this)
                }).then(({ data }) => {
                    this.form.post_detail.file = data.result.link;
                    this.upload_loader = false;
                }).catch((e) => {
                    this.upload_loader = false;
                });
            } else {
                this.upload_loader = false;
            }
        },
        updateData() {
            this.disabled = true;
            this.parseForm();
            this.authPatch('panel/posts/' + this.form.id, this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({ title: data.message })
                        this.$router.push('/panel/posts-pusat-data')
                    } else {
                        this.errors = data.errors
                    }
                }).catch(() => {
                    this.disabled = false;
                })
        },
        createData() {
            this.disabled = true;
            this.parseForm();
            this.authPost('panel/posts', this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({ title: data.message })
                        this.$router.push('/panel/posts-pusat-data')
                        this.errors = {};
                    } else {
                        this.errors = data.errors
                    }
                }).catch(() => {
                    this.disabled = false;
                })
        },
        parseForm() {
            switch (this.data_raw.type_access) {
                case 'pro':
                    this.form.is_pro = 1;
                    this.form.is_login = 1;
                    break;
                case 'member':
                    this.form.is_pro = 0;
                    this.form.is_login = 1;
                    break;
                case 'public':
                    this.form.is_pro = 0;
                    this.form.is_login = 0;
                    break;
            }
        },
        parseErrors(field, type = 'status') {
            let has = false;
            let message = '';
            if (this.errors[field]) {
                message = this.errors[field][0];
                has = true;
            }

            if (type === 'status') {
                return has;
            } else {
                return message;
            }
        },
        parseCat() {
            let val = this.form.category_type_id
            if (val) {
                let cat = this.data_raw.jurnal_types.find(item => {
                    return item.id === val;
                })
                if (cat) {
                    this.data_raw.jurnal_type_image = cat.image
                }
            }
        },
        getJornalId() {
            this.authGet('panel/get-journal-id')
                .then((data) => {
                    this.jurnal_id = data.result
                })
        }

    },
    created() {
        let param = this.$route.params.post_id
        if (param !== 'create') {
            this.loadData();
            this.edit_mode = true;
        } else {
            this.edit_mode = false;
            this.loadCat();
        }
        this.getJornalId()
    },
    watch: {
        'data_raw.type_access'() {
            this.parseForm();
        },
        'form.category_type_id'(val) {
            this.parseCat()
        }
    },
    components: { VueEditor }
}
</script>
<style>
.text-sm {
    font-size: 12px;
    font-weight: 600;
}

.text-sm span {
    color: rgb(119, 119, 119);
}
</style>