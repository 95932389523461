<template>
    <div class="d-flex flex-column flex-column-fluid" data-select2-id="select2-data-131-6dxb">
        <div class="app-toolbar pt-4 pt-lg-5 mb-6">
            <div class="app-container container-xxl">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 class="page-heading d-flex text-dark fw-bold flex-column justify-content-center my-0">
                        {{ title }}
                    </h1>
                </div>
                <div class="d-flex flex-stack my-3">
                    <div>
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control form-control-sm" placeholder="Recipient's username"
                                aria-label="Cari Disini" aria-describedby="button-addon2">
                            <button class="btn btn-theme" type="button" id="button-addon2">
                                Cari
                            </button>
                        </div>
                    </div>
                    <div class="text-right">
                        <button class="btn btn-sm mx-1 btn-outline-theme ">Kelola Kategori</button>
                        <button class="btn btn-sm mx-1 btn-theme">Tambah</button>
                    </div>
                </div>
                <div>
                    <div class="btn-group">
                        <button class="btn btn-sm btn-tab" style="border-radius: 0;">Semua (80)</button>
                        <button class="btn btn-sm">Draft (2)</button>
                        <button class="btn btn-sm">Publish (75)</button>
                        <button class="btn btn-sm" style="border-radius: 0;">Archive (3)</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl">
                <div class="card mb-4 mb-xl-4">
                    <div class="card-body py-5">
                        <div class="row">
                            <div class="col-md-3">
                                <select class="form-control form-control-sm">
                                    <option value="">Kategori</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <select class="form-control form-control-sm">
                                    <option value="">Tipe</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <input type="date" class="form-control form-control-sm">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-5 mb-xl-8">
                    <div class="card-body py-8">
                        <page-loader v-model:active="loader" loader="dots" :is-full-page="false" />
                        <div class="table-responsive">
                            <table class="table align-middle">
                                <thead>
                                    <tr class="fw-bold text-muted bg-light">
                                        <th class="ps-4 text-center w-50px rounded-start">No</th>
                                        <th class="min-w-125px">Judul</th>
                                        <th>Deskripsi</th>
                                        <th>Kategori</th>
                                        <th>Tanggal Publis</th>
                                        <th>Tipe</th>
                                        <th class="action">Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(data, i) in data_content" :key="i">
                                        <td class="ps-4 text-center border-bottom-1">
                                            <span class="text-dark fw-bold">
                                                {{ (data_content.current_page - 1) * data_content.per_page + i + 1 }}
                                            </span>
                                        </td>
                                        <td>
                                            <span class="text-dark fw-bold">{{ data.name }}</span>
                                        </td>
                                        <td class="action">
                                            <button class="btn btn-sm btn-primary" data-bs-toggle="modal"
                                                @click="editModal(data)" data-bs-target="#kt_modal_view_users">
                                                Ubah
                                                <!--                                            <BounceLoader :loading="true" color="#fff" size="20px"></BounceLoader>-->
                                            </button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="border-top-1 p-4 d-flex justify-content-end align-items-center">
                        <div class="me-3">
                            Total 0
                        </div>
                        <div>
                            <select class="form-control form-control-sm">
                                <option value="25">25/Page</option>
                                <option value="50">50/Page</option>
                                <option value="100">100/Page</option>
                            </select>
                        </div>
                        <div>
                            <ul class="pagination">
                                <li class="paginate_button page-item previous disabled">
                                    <a href="#" aria-controls="kt_inbox_listing" data-dt-idx="0" tabindex="0"
                                        class="page-link">
                                        <i class="previous"></i>
                                    </a>
                                </li>
                                <li class="paginate_button page-item active"><a href="#" class="page-link">1</a></li>
                                <li class="paginate_button page-item"><a href="#" class="page-link">2</a></li>
                                <li class="paginate_button page-item"><a href="#" class="page-link">3</a></li>
                                <li class="paginate_button page-item"><a href="#" class="page-link">4</a></li>
                                <li class="paginate_button page-item next">
                                    <a href="#" class="page-link">
                                        <i class="next"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="me-3">
                            Go to
                        </div>
                        <div>
                            <select class="form-control form-control-sm select-center">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loader: false,
            disabled: false,
            edit_mode: false,
            pswd_show: false,
            value: '',
            selected_radio: 1,
            form: new form({
                id: '',
                name: '',
                email: '',
                role_id: '',
                institution_id: '',
                password: '',
                password_confirmation: '',
            }),
            data_content: {},
            data_raw: {
                institutions: [],
                roles: [],
            },
            filter: {
                page: 1,
                per_page: 10,
                name: '',
            },
            title: 'Artikel'
        }
    },
    methods: {
        loadDataContent(page = 1) {
            this.filter.page = page
            this.loader = true
            this.authGet('users', this.filter)
                .then((data) => {
                    this.data_content = data
                    this.loader = false
                })
        },
        loadInstitution() {
            this.authGet('institutions-list')
                .then((data) => {
                    this.data_raw.institutions = data.result
                })
        },
        loadRoles() {
            this.authGet('roles-list')
                .then((data) => {
                    this.data_raw.roles = data.result
                })
        },
        addShow() {
            this.edit_mode = false;
            this.form.reset()
            $('#kt_modal_add_customer')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                }).modal('show')
            this.form.errors.clear()
        },
        addData() {
            this.disabled = true;
            this.form.post(this.base_api + 'users', this.setHeader())
                .then(({ data }) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#kt_modal_add_customer').modal('hide')
                        this.loadDataContent()
                    }
                }).catch(() => {
                    this.disabled = false;
                })
        },
        editModal(data) {
            this.edit_mode = true;
            this.form.fill(data)
            $('#kt_modal_add_customer')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                }).modal('show')
            this.form.errors.clear()
        },
        updateData() {
            this.disabled = true;
            this.form.patch(this.base_api + 'users/' + this.form.id, this.setHeader())
                .then(({ data }) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#kt_modal_add_customer').modal('hide')
                        this.loadDataContent()
                    }
                }).catch(() => {
                    this.disabled = false;
                })
        },
        deleteData() {
            if (confirm('Delete data?')) {
                this.disabled = true;
                this.form.delete(this.base_api + 'users/' + this.form.id, this.setHeader())
                    .then(({ data }) => {
                        this.disabled = false;
                        if (data.status) {
                            $('#kt_modal_add_customer').modal('hide')
                            this.loadDataContent()
                        }
                    })
            }
        },
        pswdTogle() {
            this.pswd_show = !this.pswd_show
        },
        updateTitle() {
            let name = this.$route.query.section
            if (name) {
                this.title = name.replace('_', ' ')
            }
        }
    },
    created() {

    },
    watch: {
        $route(to) {
            this.filter.div = to.query.div
            this.updateTitle()
        },
    }
}
</script>

<style scoped>

</style>