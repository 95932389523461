<template>
    <div class="d-flex flex-column flex-column-fluid" data-select2-id="select2-data-131-6dxb">
        <div class="app-toolbar pt-4 pt-lg-5 mb-6">
            <div class="app-container container-xxl">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 class="page-heading d-flex text-dark fw-bold flex-column justify-content-center my-0">
                        {{ title }}
                    </h1>
                </div>
                <div class="d-flex flex-stack my-3">
                    <div>
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control form-control-sm" placeholder="Cari" @keyup.enter="loadDataContent" v-model="filter.s"
                                   aria-label="Cari Disini" aria-describedby="button-addon2">
                            <button class="btn btn-theme" type="button" id="button-addon2">
                                Cari
                            </button>
                        </div>
                    </div>
                    <div class="text-right">
                        <router-link :to="'/panel/categories?section=' + filter.section"
                                     class="btn btn-sm mx-1 btn-outline-theme" >Kelola Kategori</router-link>
                        <router-link class="btn btn-sm mx-1 btn-theme"
                                     :to="'/panel/services-edit-add/create?section=' + filter.section">Tambah
                        </router-link>
                    </div>
                </div>
                <div>
                    <div class="btn-group">
                        <button class="btn btn-sm" :class="filter.status === 0 ? 'btn-tab' : ''"
                                @click="filter.status = 0">Draft
                        </button>
                        <button class="btn btn-sm" :class="filter.status === 1 ? 'btn-tab' : ''"
                                @click="filter.status = 1">Publish
                        </button>
                        <button class="btn btn-sm" :class="filter.status === 2 ? 'btn-tab' : ''"
                                @click="filter.status = 2"
                                style="border-radius: 0;">Archive
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl">
                <div class="card mb-4 mb-xl-4">
                    <div class="card-body py-5">
                        <div class="row">
                            <div class="col-md-3">
                                <select class="form-control form-control-sm" v-model="filter.category_id"
                                        @change="loadDataContent">
                                    <option value="">Semua Kategori</option>
                                    <option v-for="cat in data_raw.categories" :value="cat.id">{{ cat.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-5 mb-xl-8">
                    <div class="card-body py-8">
                        <page-loader v-model:active="loader" loader="dots" :is-full-page="false"/>
                        <div class="table-responsive">
                            <table class="table align-middle">
                                <thead>
                                <tr class="fw-bold text-muted bg-light">
                                    <th class="ps-4 text-center w-50px rounded-start">No</th>
                                    <th >Nama Produk</th>
                                    <th style="width: 15%" >Kategori</th>
                                    <th style="width: 15%" class="text-right">Harga Rendah</th>
                                    <th style="width: 15%" class="text-right">Harga Tinggi</th>
                                    <th style="width: 15%" class="text-right">Aksi</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="data_content.total === 0">
                                    <td colspan="9" class="text-center">
                                        <i>tidak ada data</i>
                                    </td>
                                </tr>
                                <tr v-for="(data, i) in data_content.data" :key="i">
                                    <td class="ps-4 text-center border-bottom-1">
                                        <span class="text-dark fw-bold">{{ i + 1 }}</span>
                                    </td>
                                    <td>
                                        <span class="text-dark fw-bold">{{ data.name }}</span>
                                    </td>
                                    <td>
                                        <span v-if="data.category">{{ data.category.name }}</span>
                                    </td>
                                    <td class="text-right">
                                        {{ $filters.currency(data.min_price) }}
                                    </td>
                                    <td class="text-right">
                                        {{ $filters.currency(data.max_price) }}
                                    </td>
                                    <td class="text-right">
                                        <router-link :to="'/panel/services/' + data.id"
                                                     class="btn btn-sm btn-outline-theme">
                                            Detail
                                        </router-link>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="border-top-1 p-4 d-flex justify-content-end align-items-center">
                        <div class="me-3">
                            Total {{ data_content.total }}
                        </div>
                        <div>
                            <select class="form-control form-control-sm" v-model="filter.per_page"
                                    @change="loadDataContent">
                                <option value="10">10/Page</option>
                                <option value="25">25/Page</option>
                                <option value="50">50/Page</option>
                                <option value="100">100/Page</option>
                            </select>
                        </div>
                        <div class="mx-2">
                            <laravel-pagination :data="data_content" :limit="2"
                                                @paginationChangePage="loadDataContent"></laravel-pagination>
                        </div>
                        <div class="me-3">
                            Go to
                        </div>
                        <div>
                            <select class="form-control form-control-sm select-center" style="min-width: 80px;"
                                    v-model="filter.page">
                                <option :value="i" v-for="i in data_content.last_page">{{ i }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loader: false,
            disabled: false,
            edit_mode: false,
            pswd_show: false,
            value: '',
            selected_radio: 1,
            form: new form({
                id: '',
                name: '',
                email: '',
                role_id: '',
                institution_id: '',
                password: '',
                password_confirmation: '',
            }),
            data_content: {},
            data_raw: {
                categories: [],
            },
            filter: {
                page: 1,
                per_page: 10,
                name: '',
                status: 1,
                section: '',
                category_id: '',
                s: '',
            },
            title: ''
        }
    },
    methods: {
        loadDataContent(page = 1) {
            this.filter.page = page
            this.loader = true
            this.authGet('panel/products', this.filter)
                .then((data) => {
                    this.data_content = data
                    this.loader = false
                })
        },
        loadCategories() {
            this.authGet('panel/categories-list', this.filter)
                .then((data) => {
                    this.data_raw.categories = data.result
                })
        },
        addShow() {
            this.edit_mode = false;
            this.form.reset()
            $('#kt_modal_add_customer')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                }).modal('show')
            this.form.errors.clear()
        },
        addData() {
            this.disabled = true;
            this.form.post(this.base_api + 'users', this.setHeader())
                .then(({data}) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#kt_modal_add_customer').modal('hide')
                        this.loadDataContent()
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        editModal(data) {
            this.edit_mode = true;
            this.form.fill(data)
            $('#kt_modal_add_customer')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                }).modal('show')
            this.form.errors.clear()
        },
        updateData() {
            this.disabled = true;
            this.form.patch(this.base_api + 'users/' + this.form.id, this.setHeader())
                .then(({data}) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#kt_modal_add_customer').modal('hide')
                        this.loadDataContent()
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        deleteData() {
            if (confirm('Delete data?')) {
                this.disabled = true;
                this.form.delete(this.base_api + 'users/' + this.form.id, this.setHeader())
                    .then(({data}) => {
                        this.disabled = false;
                        if (data.status) {
                            $('#kt_modal_add_customer').modal('hide')
                            this.loadDataContent()
                        }
                    })
            }
        },
        pswdTogle() {
            this.pswd_show = !this.pswd_show
        },
        updateTitle() {
            let name = this.$route.query.section
            if (name) {
                this.title = name.replace('_', ' ')
            }
            switch (name) {
                case 'litigation':
                    this.filter.section = 'litigation'
                    this.title = 'Litigasi'
                    break;
                case 'literacy':
                    this.filter.section = 'literacy'
                    this.title = 'Literasi Hukum'
                    break;
                case 'consultation':
                    this.filter.section = 'consultation'
                    this.title = 'Konsultasi Hukum'
                    break;
                default:
                    this.filter.section = 'consultation'
                    break;
            }
            this.loadCategories()
        }
    },
    created() {
        this.updateTitle()
        this.loadDataContent()

        this.$store.commit('set_section', this.$route.query.section)
    },
    watch: {
        $route(to) {
            this.updateTitle()
            this.loadDataContent()
            let section = this.$route.query.section
            if (section) {
                this.$store.commit('set_section', section)
            }
        },
        'filter.status'(val) {
            this.loadDataContent()
        }
    }
}
</script>

<style scoped>

</style>