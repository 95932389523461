<template>
    <div class="d-flex flex-column flex-column-fluid pb-10" data-select2-id="select2-data-131-6dxb">
        <div class="app-toolbar py-4 pt-lg-5 mb-6">
            <div class="app-container container-xxl">
                <div class="mb-3">
                    <router-link :to="'/panel/products?section=' + form.section" class="text-theme">
                        <span v-if="form.section === 'training'"> Pelatihan</span>
                        <span v-if="form.section === 'class_room'"> Kelas</span>
                    </router-link>
                    /
                    <span v-if="edit_mode">Edit</span>
                    <span v-if="!edit_mode">Tambah</span>
                </div>
                <div class="d-flex align-items-center">
                    <div style="width: 100%;" class="me-3">
                        <h3>
                            <span v-if="form.section === 'training'"> Pelatihan</span>
                            <span v-if="form.section === 'class_room'"> Kelas</span>
                        </h3>
                    </div>
                    <button v-if="edit_mode" class="btn btn-sm btn-outline-theme mx-1" @click="deleteData">Hapus</button>
                    <button v-if="detail_mode" class="btn btn-sm btn-theme mx-1" @click="detail_mode = false">Edit</button>
                </div>
            </div>
        </div>
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl">
                <div class="card mb-4 mb-xl-4">
                    <div class="card-body">
                        <h3>Informasi Umum</h3>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="mt-3 form-group text-center position-relative">
                                    <page-loader v-model:active="upload_loader" loader="dots" :is-full-page="false"/>
                                    <div class="d-flex justify-content-center">
                                        <img :src="form.image" v-if="form.image" alt=""
                                             style="max-height: 150px; width: 100%" class="mb-3">
                                    </div>
                                    <label v-if="!detail_mode" for="file-cover" class="btn btn-theme btn-xs">Pilih Cover</label>
                                    <input type="file" class="form-control form-control-sm"
                                           hidden accept="image/*" id="file-cover" @change="uploadCover">
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Nama</label>
                                            <input :disabled="detail_mode" type="text" class="form-control" v-model="form.name">
                                            <span class="error-text"
                                                  v-if="parseErrors('name')">{{ parseErrors('name', 'm') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Kategori</label>
                                            <select :disabled="detail_mode" class="form-control" v-model="form.category_id">
                                                <option :value="cat.id" v-for="cat in data_raw.categories">
                                                    {{ cat.name }}
                                                </option>
                                            </select>
                                            <span class="error-text" v-if="parseErrors('category_id')">{{
                                                    parseErrors('category_id', 'm')
                                                }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="form.section === 'training'">
                                        <div class="form-group">
                                            <label>Tipe</label>
                                            <select :disabled="detail_mode" class="form-control" v-model="form.category_type_id">
                                                <option :value="type.id" v-for="type in data_raw.category_types">
                                                    {{ type.name }}
                                                </option>
                                            </select>
                                            <span class="error-text" v-if="parseErrors('category_type_id')">{{
                                                    parseErrors('category_type_id', 'm')
                                                }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="form.section === 'training'">
                                        <div class="form-group">
                                            <label>Waktu</label>
                                            <input :disabled="detail_mode" type="datetime-local" class="form-control"
                                                   v-model="form.product_detail.date_start">
                                            <span class="error-text"
                                                  v-if="parseErrors('product_detail.date_start')">
                                                {{ parseErrors('product_detail.date_start', 'm') }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Status</label>
                                            <select :disabled="detail_mode" class="form-control" v-model="form.status">
                                                <option value="1">Publish</option>
                                                <option value="0">Draft</option>
                                            </select>
                                            <span class="error-text"
                                                  v-if="parseErrors('status')">{{ parseErrors('status', 'm') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Pemateri</label>
<!--                                            <select :disabled="detail_mode" class="form-control" v-model="form.product_detail.speaker_id">-->
<!--                                                <option :value="speaker.id" v-for="speaker in data_raw.speakers">-->
<!--                                                    {{ speaker.name }}-->
<!--                                                </option>-->
<!--                                            </select>-->
                                            <input :disabled="detail_mode" type="text" class="form-control"
                                                   v-model="form.product_detail.speaker">
                                            <span class="error-text" v-if="parseErrors('product_detail.speaker')">{{
                                                    parseErrors('product_detail.speaker', 'm')
                                                }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Deskripsi</label>
                                            <textarea :disabled="detail_mode" type="text" class="form-control" v-model="form.desc"></textarea>
                                            <span class="error-text"
                                                  v-if="parseErrors('desc')">{{ parseErrors('desc', 'm') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 border-top mt-6">
                                <h5>Informasi Harga</h5>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Harga Digunakan</label>
                                    <money3 v-bind="money_config" :disabled="detail_mode" class="form-control"
                                            v-model="form.product_detail.price"></money3>
                                    <span class="error-text" v-if="parseErrors('product_detail.price')">{{
                                            parseErrors('product_detail.price', 'm')
                                        }}</span>
                                </div>
                            </div>
                            <div class="col-md-6"></div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Harga Diskon (dicoret)</label>
                                    <money3 v-bind="money_config" :disabled="detail_mode" class="form-control"
                                            v-model="form.product_detail.price_crossed_out"></money3>
                                    <span class="error-text"
                                          v-if="parseErrors('product_detail.price_crossed_out')">{{
                                            parseErrors('product_detail.price_crossed_out', 'm')
                                        }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Diskon Teks</label>
                                    <input :disabled="detail_mode" type="text" class="form-control"
                                           v-model="form.product_detail.promo_text">
                                    <span class="error-text" v-if="parseErrors('product_detail.promo_text')">{{
                                            parseErrors('product_detail.promo_text', 'm')
                                        }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-6" v-if="form.section === 'training'">
                            <h5>Informasi Lokasi</h5>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Lokasi</label>
                                    <select :disabled="detail_mode" class="form-control"
                                            v-model="form.product_detail.place_type">
                                        <option value="online">Online</option>
                                        <option value="onsite">Onsite</option>
                                    </select>
                                    <span class="error-text" v-if="parseErrors('product_detail.place_type')">{{
                                            parseErrors('product_detail.place_type', 'm')
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Link</label>
                                    <input :disabled="detail_mode" type="text" class="form-control"
                                           v-model="form.product_detail.place_link">
                                    <span class="error-text" v-if="parseErrors('product_detail.place_link')">{{
                                            parseErrors('product_detail.place_link', 'm')
                                        }}</span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Alamat</label>
                                    <textarea :disabled="detail_mode" type="text" class="form-control"
                                              v-model="form.product_detail.place"></textarea>
                                    <span class="error-text" v-if="parseErrors('product_detail.place')">{{
                                            parseErrors('product_detail.place', 'm')
                                        }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="edit_mode && form.section === 'class_room'">
                    <div class="col-md-6">
                        <MediaYoutube :product_id="$route.params.product_id" data_type="video"></MediaYoutube>
                    </div>
                    <div class="col-md-6">
                        <Media :product_id="$route.params.product_id" data_type="ebook"></Media>
                    </div>
                </div>
            </div>

        </div>

        <div class="bg-white position-fixed text-right shadow py-3 pe-10" v-if="!detail_mode" style="left: 0; bottom: 0; width: 100%;">
            <button class="btn btn-sm btn-theme" v-if="edit_mode" :disabled="disabled"
                    @click="updateData">
                <span v-if="!disabled">Simpan</span>
                <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
            </button>
            <button class="btn btn-sm btn-theme" v-if="!edit_mode" :disabled="disabled"
                    @click="createData">
                <span v-if="!disabled">Simpan</span>
                <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
            </button>
        </div>
    </div>
</template>

<script>
import Media from "@/pages/products/Media";
import MediaYoutube from "@/pages/products/MediaYoutube";

export default {
    components: {MediaYoutube, Media},
    data() {
        return {
            detail_mode: true,
            edit_mode: true,
            disabled: false,
            upload_loader: false,
            errors: {},
            data_raw: {
                categories: [],
                category_types: [],
                speakers: [],
            },
            form: {
                id: '',
                name: '',
                image: '',
                section: '',
                category_id: '',
                price: '',
                status: 1,
                desc: '',
                product_detail: {
                    price: '',
                    promo_text: '',
                    price_crossed_outed_out: '',
                    speaker: '',
                    date_start: '',
                    place: '',
                    place_type: '',
                    place_link: '',
                }
            },
        }
    },
    methods: {
        loadData() {
            this.authGet('panel/products/' + this.$route.params.product_id)
                .then((data) => {
                    this.form = data.result
                    this.loadCategories()
                });
        },
        loadCategories() {
            this.authGet('panel/categories-list?section=' + this.form.section)
                .then((data) => {
                    this.data_raw.categories = data.result
                })
            if (this.form.section === 'training') {
                this.authGet('panel/categories-list?section=training-type')
                    .then((data) => {
                        this.data_raw.category_types = data.result
                    })
            }
        },
        loadSpeaker() {
            this.authGet('panel/partners-list')
                .then((data) => {
                    this.data_raw.speakers = data.result
                })
        },
        updateData() {
            this.disabled = true;
            this.authPatch('panel/products/' + this.form.id, this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({title: data.message})
                        this.$router.push('/panel/products?section=' + this.form.section)
                    } else {
                        this.errors = data.errors
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        createData() {
            this.disabled = true;
            this.authPost('panel/products', this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({title: data.message})
                        this.$router.push('/panel/products?section=' + this.form.section)
                    } else {
                        this.errors = data.errors
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        deleteData() {
            if (confirm('Delete data?')) {
                this.disabled = true;
                this.authDelete('panel/products/' + this.form.id)
                    .then((data) => {
                        this.disabled = false;
                        if (data.status) {
                            this.toaster({title: data.message})
                            this.$router.push('/panel/products?section=' + this.form.section)
                        }
                    })
            }
        },
        uploadCover() {
            this.upload_loader = true;
            let file = document.getElementById("file-cover").files[0];
            if (file) {
                let form_data = new FormData();

                form_data.append('save_method', 'upload')
                form_data.append('file', file)

                this.authPost('panel/docs', form_data)
                    .then((data) => {
                        this.form.image = data.result.link;
                        this.upload_loader = false;
                    }).catch((e) => {
                    this.upload_loader = false;
                });
            } else {
                this.upload_loader = false;
            }
        },
        parseErrors(field, type = 'status') {
            let has = false;
            let message = '';
            if (this.errors[field]) {
                message = this.errors[field][0];
                has = true;
            }

            if (type === 'status') {
                return has;
            } else {
                return message;
            }
        },
    },
    created() {
        this.form.section = this.$route.query.section

        let param = this.$route.params.product_id
        if (param !== 'create') {
            this.loadData();
            this.edit_mode = true;
        } else {
            this.detail_mode = false;
            this.edit_mode = false;
        }

        // this.loadSpeaker()
        this.loadCategories()
    },
    watch: {}
}
</script>