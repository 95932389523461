import { createRouter, createWebHistory } from 'vue-router'

import Home from '../pages/home/Home.vue'
import AdminPanel from '../pages/AdminPanel.vue'
import LandingPage from '../pages/LandingPage.vue'
import User from '../pages/users/Index.vue'
import LoginPage from '../pages/auth/Login.vue'
import Role from '../pages/roles/Index.vue'
import WhiteListIP from '../pages/whitelist_ip/Index.vue'
import MenuRole from '../pages/menu-roles/Index.vue'
import Menu from '../pages/menu/Index.vue'
import Categories from '../pages/categories/Index.vue'
import Profile from '../pages/profile/Index.vue'
import Configuration from '../pages/configurations/Index.vue'

import Transactions from '../pages/transactions/Index.vue'
import TransactionShow from '../pages/transactions/Show.vue'

import Posts from '../pages/posts/Index.vue'
import PostsPusatData from '../pages/posts/IndexPusatData.vue'
import PostsDiscussion from '../pages/posts/IndexDiscussion.vue'
import PostShow from '../pages/posts/Show.vue'
import PostShowJurnal from '../pages/posts/ShowJurnal.vue'
import PostEditAdd from '../pages/posts/EditAdd.vue'
import PostEditAddPusatData from '../pages/posts/EditAddPusatData.vue'
import PostEditJurnal from '../pages/posts/EditAddJurnal.vue'

import Products from '../pages/products/Index.vue'
import ProductPackage from '../pages/products/IndexPackage'
import ProductsLegal from '../pages/products/IndexLegal'
import ProductsEditAdd from '../pages/products/EditAdd.vue'
// import ProductsEditAddTraining from '../pages/products/EditAddTraining.vue'

import Members from '../pages/members/Index.vue'
import MembersShow from '../pages/members/Show'
import PartnerDetail from '../pages/partners/Show.vue'
import Partners from '../pages/partners/Index.vue'
import Pages from '../pages/pages/Index.vue'

import Services from '../pages/services/Index.vue'
import ServicesKonsultasi from '../pages/services/IndexKonsultasi'
import ServicesShow from '../pages/services/Show'
import ServicesShowKonsultasi from '../pages/services/ShowKonsultasi'
import ServicesEditAdd from '../pages/services/EditAdd'

import CompanyProfile from '../pages/company-profile/Index.vue'
import Packages from '../pages/packages/Index.vue'

import PageNotFound from '../components/PageNotFound.vue'
import ActivityLogs from '../pages/activity-logs/Index'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: LandingPage },
        { path: '/login', component: LoginPage, name: 'login' },
        {
            path: '/panel',
            component: AdminPanel,
            meta: { requiredAuth: true },
            children: [
                // Index
                { path: 'home', component: Home, },
                { path: 'categories', component: Categories, meta: { title: 'L2us | Kategori' } },
                { path: 'users', component: User, meta: { title: 'L2us | User' } },
                { path: 'roles', component: Role, meta: { title: 'L2us | Role' } },
                { path: 'white-list-ip', component: WhiteListIP, meta: { title: 'L2us | WhiteListIP' } },
                { path: 'menu-roles', component: MenuRole, meta: { title: 'L2us | Menu Role' } },
                { path: 'menus', component: Menu, meta: { title: 'L2us | Menu' } },
                { path: 'profile', component: Profile, meta: { title: 'L2us | Profile' } },
                { path: 'activity-logs', component: ActivityLogs, meta: { title: 'L2us | Logs' } },

                { path: 'packages', component: Packages, meta: { title: 'Legal 2 Pro' } },

                { path: 'transactions', component: Transactions, meta: { title: 'Transaksi' } },
                { path: 'transactions/:id', component: TransactionShow, meta: { title: 'Transaksi' } },
                
                { path: 'posts', component: Posts, meta: { title: 'Posts' } },
                { path: 'posts-pusat-data', component: PostsPusatData, meta: { title: 'Pusat Data' } },
                { path: 'posts-discussion', component: PostsDiscussion, meta: { title: 'Diskusi' } },
                { path: 'posts/:post_id', component: PostShow, meta: { title: 'Post Detail' } },
                { path: 'posts-jurnal/:post_id', component: PostShowJurnal, meta: { title: 'Jurnal Detail' } },
                { path: 'posts-edit-add/:post_id', component: PostEditAdd, meta: { title: 'Post Update' } },
                { path: 'posts-edit-add-pusat-data/:post_id', component: PostEditAddPusatData, meta: { title: 'Pusat Data Update' } },
                { path: 'posts-edit-add-jurnal/:post_id', component: PostEditJurnal, meta: { title: 'Jurnal Update' } },

                { path: 'products', component: Products, meta: { title: 'Products' } },
                { path: 'products-package', component: ProductPackage, meta: { title: 'Product Package' } },
                { path: 'products-legal', component: ProductsLegal, meta: { title: 'Product Legal' } },
                { path: 'products-edit-add/:product_id', component: ProductsEditAdd, meta: { title: 'Product Update' } },
                // { path: 'products-edit-add-training/:product_id', component: ProductsEditAddTraining, meta: { title: 'Product Update' } },

                { path: 'members', component: Members, meta: { title: 'Members' } },
                { path: 'members/:user_id', component: MembersShow, meta: { title: 'Members Show' } },
                { path: 'partners', component: Partners, meta: { title: 'Partners' } },
                { path: 'partners/:user_id', component: PartnerDetail, meta: { title: 'Partner' } },

                { path: 'pages', component: Pages, meta: { title: 'Pages' } },
                { path: 'configurations', component: Configuration, meta: { title: 'Konfigurasi' } },
                { path: 'services', component: Services, meta: { title: 'Services' } },
                { path: 'services-konsultasi', component: ServicesKonsultasi, meta: { title: 'Konsultasi Hukum' } },
                { path: 'services/:product_id', component: ServicesShow, meta: { title: 'Services' } },
                { path: 'services-konsultasi/:product_id', component: ServicesShowKonsultasi, meta: { title: 'Services' } },
                { path: 'services-edit-add/:product_id', component: ServicesEditAdd, meta: { title: 'Services' } },
                { path: 'company-profile', component: CompanyProfile, meta: { title: 'CompanyProfile' } },

                // Detail

                { path: '/:afterUser(.*)', component: PageNotFound },
            ]
        },
        { path: '/:pathMatch(.*)*', component: PageNotFound },
    ]
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title ?? 'Legal 2 Us Admin Panel'
    if (to.meta.requiredAuth) {
        let ls_token = localStorage.user_token;
        if (ls_token == null) {
            next({
                path: '/login',
                replace: true
            })
        }
    }
    next();
})

export default router;