<template>
    <div class="d-flex flex-column flex-column-fluid" data-select2-id="select2-data-131-6dxb">
        <div class="app-toolbar pt-4 pt-lg-5 mb-6">
            <div class="app-container container-xxl">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 class="page-heading d-flex text-dark fw-bold flex-column justify-content-center my-0">
                        {{ title }}
                    </h1>
                </div>
                <div class="d-flex flex-stack my-3">
                    <div class="w-300px">
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control form-control-sm" v-model="filter.s"
                                   placeholder="Kata kunci.." @keyup.enter="loadDataContent" aria-label="Cari Disini"
                                   aria-describedby="button-addon2">
                            <button class="btn btn-theme" type="button" id="button-addon2" @click="loadDataContent">
                                Cari
                            </button>
                        </div>
                    </div>
                    <div class="text-right">
                        <router-link :to="'/panel/categories?section=' + filter.section"
                                     class="btn btn-sm mx-1 btn-outline-theme">Kelola Kategori
                        </router-link>
                        <router-link :to="'/panel/posts-edit-add/create?section=' + filter.section"
                                     class="btn btn-sm mx-1 btn-theme">Tambah
                        </router-link>
                    </div>
                </div>
                <div>
                    <div class="btn-group">
                        <button class="btn btn-sm rounded-0" :class="filter.status === 0 ? 'btn-tab' : ''"
                                @click="filter.status = 0">Draft
                        </button>
                        <button class="btn btn-sm rounded-0" :class="filter.status === 1 ? 'btn-tab' : ''"
                                @click="filter.status = 1">Publish
                        </button>
                        <button class="btn btn-sm rounded-0" :class="filter.status === 2 ? 'btn-tab' : ''"
                                @click="filter.status = 2"
                                style="border-radius: 0;">Archive
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl">
                <div class="card mb-4 mb-xl-4" v-if="!short_mode">
                    <div class="card-body py-5">
                        <div class="row">
                            <div class="col-md-3">
                                <select class="form-control form-control-sm" v-model="filter.category_id"
                                        @change="loadDataContent">
                                    <option value="">Semua Kategori</option>
                                    <option v-for="cat in data_raw.categories" :value="cat.id">{{ cat.name }}</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <select class="form-control form-control-sm" v-model="filter.type"
                                        @change="loadDataContent">
                                    <option value="">Semua Tipe</option>
                                    <option value="public">Publik</option>
                                    <option value="member">Member</option>
                                    <option value="pro">Pro</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <VueCtkDateTimePicker v-bind="date_range_option_global"
                                                      v-model="filter.dates"></VueCtkDateTimePicker>
                            </div>
                            <div class="col-md-3">
                                <button class="btn btn-sm btn-outline-theme" @click="resetFilter">
                                    Reset Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-5 mb-xl-8">
                    <div class="card-body py-8">
                        <page-loader v-model:active="loader" loader="dots" :is-full-page="false"/>
                        <div class="table-responsive">
                            <table class="table align-middle">
                                <thead>
                                <tr class="fw-bold text-muted bg-light">
                                    <th style="width: 8%" class="ps-4 text-center w-50px rounded-start">No</th>
                                    <th style="width: 8%"></th>
                                    <th >Judul</th>
                                    <th style="width: 10%">Dilihat</th>
                                    <th style="width: 10%" v-if="!short_mode">Kategori</th>
                                    <th style="width: 10%">Tanggal</th>
                                    <th style="width: 10%" v-if="!short_mode">Tipe</th>
                                    <th style="width: 15%" class="text-right">Aksi</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="data_content.total === 0">
                                    <td colspan="7" class="text-center">
                                        <i>tidak ada data</i>
                                    </td>
                                </tr>
                                <tr v-for="(data, i) in data_content.data" :key="i">
                                    <td class="ps-4 text-center border-bottom-1" :title="data.id">
                                        <span class="text-dark fw-bold">{{
                                                (data_content.current_page - 1) *
                                                data_content.per_page + i + 1
                                            }}</span>
                                    </td>
                                    <td class="text-center">
                                        <a :href="data.image" target="_blank">
                                            <img :src="data.image" style="height: 50px; width: auto;" alt="">
                                        </a>
                                    </td>
                                    <td>
                                        <span class="text-dark fw-bold">{{ data.title }}</span>
                                    </td>
                                    <td>
                                        <unicon name="eye" height="16" width="16" fill="orange" class="me-2"></unicon>{{data.counter}}
                                    </td>
                                    <td v-if="!short_mode">
                                        {{ data.category_name }}
                                    </td>
                                    <td>
                                        <span v-if="data.release_date">{{ $filters.formatDate(data.release_date) }}</span>
                                        <span v-else>{{ $filters.formatDate(data.created_at) }}</span>
<!--                                        <span>{{ $filters.formatDateTime(data.created_at) }}</span>-->
                                    </td>
                                    <td v-if="!short_mode">
                                        {{ parseInt(data.is_pro) === 1 ? "Pro" : parseInt(data.is_login) === 1 ? 'Member' : 'Public' }}
                                    </td>
                                    <td class="text-right">
                                        <button @click="deleteData(data)"
                                                     class="btn btn-sm btn-outline-theme mx-md-1 m-1">
                                            Hapus
                                        </button>
                                        <router-link :to="'/panel/posts/'+data.id"
                                                     class="btn btn-sm btn-theme mx-md-1 m-1">
                                            Detail
                                        </router-link>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="border-top-1 p-4 d-flex justify-content-end align-items-center">
                        <div class="me-3">
                            Total {{ data_content.total }}
                        </div>
                        <div>
                            <select class="form-control form-control-sm" v-model="filter.per_page"
                                    @change="loadDataContent">
                                <option value="10">10/Page</option>
                                <option value="25">25/Page</option>
                                <option value="50">50/Page</option>
                                <option value="100">100/Page</option>
                            </select>
                        </div>
                        <div class="mx-2">
                            <laravel-pagination :data="data_content" :limit="2"
                                                @paginationChangePage="loadDataContent"></laravel-pagination>
                        </div>
                        <div class="me-3">
                            Go to
                        </div>
                        <div>
                            <select class="form-control form-control-sm select-center" style="min-width: 80px;"
                                    v-model="filter.page">
                                <option :value="i" v-for="i in data_content.last_page">{{ i }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loader: false,
            disabled: false,
            edit_mode: false,
            pswd_show: false,
            value: '',
            selected_radio: 1,
            form: new form({
                id: '',
                name: '',
                email: '',
                role_id: '',
                institution_id: '',
                password: '',
                password_confirmation: '',
            }),
            data_content: {},
            data_raw: {
                categories: [],
                roles: [],
            },
            filter: {
                page: 1,
                per_page: 10,
                name: '',
                status: 1,
                section: '',
                category_id: '',
                type: '',
                dates: '',
            },
            title: 'Artikel'
        }
    },
    methods: {
        loadDataContent(page = 1) {
            this.filter.page = page
            this.loader = true
            this.authGet('panel/posts', this.filter)
                .then((data) => {
                    this.data_content = data
                    this.loader = false
                }).catch(() => {
                this.loader = false
            })
        },
        resetFilter(){
            this.filter.page = 1;
            this.filter.name = '';
            this.filter.status = 1;
            this.filter.category_id = '';
            this.filter.type = '';
            this.filter.dates = '';

            this.loadDataContent()
        },
        loadCategories() {
            this.authGet('panel/categories-list', this.filter)
                .then((data) => {
                    this.data_raw.categories = data.result
                })
        },
        loadRoles() {
            this.authGet('roles-list')
                .then((data) => {
                    this.data_raw.roles = data.result
                })
        },
        addShow() {
            this.edit_mode = false;
            this.form.reset()
            $('#kt_modal_add_customer')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                }).modal('show')
            this.form.errors.clear()
        },
        addData() {
            this.disabled = true;
            this.form.post(this.base_api + 'users', this.setHeader())
                .then(({data}) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#kt_modal_add_customer').modal('hide')
                        this.loadDataContent()
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        editModal(data) {
            this.edit_mode = true;
            this.form.fill(data)
            $('#kt_modal_add_customer')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                }).modal('show')
            this.form.errors.clear()
        },
        updateData() {
            this.disabled = true;
            this.form.patch(this.base_api + 'users/' + this.form.id, this.setHeader())
                .then(({data}) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#kt_modal_add_customer').modal('hide')
                        this.loadDataContent()
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        deleteData(data) {
            if (confirm('Delete data?')) {
                this.disabled = true;
                this.form.delete(this.base_api + 'panel/posts/' + data.id, this.setHeader())
                    .then(({data}) => {
                        this.disabled = false;
                        if (data.status) {
                            this.loadDataContent()
                        }
                    })
            }
        },
        pswdTogle() {
            this.pswd_show = !this.pswd_show
        },
        updateTitle() {
            let name = this.$route.query.section
            if (name) {
                this.title = name.replace('_', ' ')
            }
            switch (name) {
                case 'article':
                    this.filter.section = 'article'
                    this.title = 'Artikel'
                    break;
                case 'infographic':
                    this.filter.section = 'infographic'
                    this.title = 'Info Grafik'
                    break;
                case 'legal_analysis':
                    this.filter.section = 'legal_analysis'
                    this.title = 'Analisis Hukum'
                    break;
                case 'data_center':
                    this.filter.section = 'data_center'
                    this.title = 'Pusat Data'
                    break;
                case 'event_organizer':
                    this.filter.section = 'event_organizer'
                    this.title = 'Kelola Acara'
                    break;
                case 'testimony':
                    this.filter.section = 'testimony'
                    this.title = 'Testimoni'
                    break;
                case 'qna':
                    this.filter.section = 'qna'
                    this.title = 'Pertanyaan'
                    break;
                case 'client':
                    this.filter.section = 'client'
                    this.title = 'Klien Kami'
                    break;
                default:
                    this.filter.section = ''
                    break;
            }

            if (name) {
                localStorage.setItem('post_section', name)
            }
        }
    },
    computed: {
        short_mode() {
            switch (this.filter.section) {
                case 'qna':
                case 'client':
                case 'testimony':
                    return true;
                default:
                    return false;
            }
        }
    },
    created() {
        this.updateTitle()
        this.loadDataContent()
        this.loadCategories()
    },
    watch: {
        $route(to) {
            this.filter.div = to.query.div
            this.updateTitle()
            this.loadDataContent()
        },
        'filter.status'(val) {
            this.loadDataContent()
        },
        'filter.dates.start': function () {
            this.loadDataContent(1)
        },
        'filter.dates.end': function () {
            this.loadDataContent(1)
        },
    }
}
</script>

<style scoped>

</style>