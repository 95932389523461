<template>
    <div class="d-flex flex-column flex-column-fluid" style="position: relative">
        <div class="app-toolbar pt-4 pt-lg-5 mb-6">
            <div class="app-container container-xxl">
                <div class="me-3">
                    <small><span @click="$router.go(-1)" class="text-theme cursor-pointer">Layanan</span>
                        / Edit</small>
                </div>
                <div class="d-flex my-6 justify-content-between align-items-center">
                    <div class="d-flex my-0 align-items-center">
                        <div>
                            <h3 v-if="!edit_mode" class="fw-bold mt-3">Tambah Layanan</h3>
                            <h3 v-if="edit_mode" class="fw-bold mt-3">Edit Layanan</h3>
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-sm btn-theme mx-1" v-if="!edit_mode" :disabled="disabled"
                                @click="createData">
                            <span v-if="!disabled">Tambah</span>
                            <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
                        </button>
                        <button class="btn btn-sm btn-theme mx-1" v-if="edit_mode" :disabled="disabled"
                                @click="updateData">
                            <span v-if="!disabled">Perbarui</span>
                            <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl">
                <page-loader v-model:active="loader" loader="dots" :is-full-page="false"/>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Tipe</label>
                                    <select class="form-control" v-model="form.section">
                                        <option :value="section.name" v-for="section in data_raw.sections">
                                            {{ section.text }}
                                        </option>
                                    </select>
                                    <span class="error-text"
                                          v-if="parseErrors('sections')">{{ parseErrors('sections', 'm') }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Kategori</label>
                                    <select class="form-control" v-model="form.category_id">
                                        <option :value="cat.id" v-for="cat in data_raw.categories">{{ cat.name }}
                                        </option>
                                    </select>
                                    <span class="error-text"
                                          v-if="parseErrors('category_id')">{{ parseErrors('category_id', 'm') }}</span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Nama Layanan</label>
                                    <input class="form-control" v-model="form.name">
                                    <span class="error-text" v-if="parseErrors('name')">{{
                                            parseErrors('name', 'm')
                                        }}</span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Deskripsi</label>
                                    <textarea class="form-control" v-model="form.desc"></textarea>
                                    <span class="error-text" v-if="parseErrors('desc')">{{
                                            parseErrors('desc', 'm')
                                        }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Harga Terendah</label>
                                    <money3 v-bind="money_config" class="form-control" v-model="form.min_price"></money3>
                                    <span class="error-text"
                                          v-if="parseErrors('min_price')">{{ parseErrors('min_price', 'm') }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Harga Tertinggi</label>
                                    <money3 v-bind="money_config" class="form-control" v-model="form.max_price">
                                    </money3>
                                    <span class="error-text"
                                          v-if="parseErrors('max_price')">{{ parseErrors('max_price', 'm') }}</span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Text Whatsapp</label>
                                    <textarea class="form-control" v-model="form.direct_link"></textarea>
                                    <span class="error-text"
                                          v-if="parseErrors('direct_link')">{{ parseErrors('direct_link', 'm') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loader: false,
            disabled: false,
            edit_mode: false,
            form: {
                id: '',
                name: '',
                category_id: '',
                section: '',
                desc: '',
                min_price: '',
                max_price: '',
                direct_link: '',
                created_at: '',
                status: 1,
            },
            data_raw: {
                categories: [],
                sections: [
                    {
                        'name': 'litigation',
                        'text': 'Litigasi',
                    }, {
                        'name': 'literacy',
                        'text': 'Literasi',
                    }
                ],
            },
            errors: {}
        }
    },
    methods: {
        loadData(product_id = null) {
            this.loader = true
            this.authGet('panel/products/' + (product_id ? product_id : this.$route.params.product_id), this.filter)
                .then((data) => {
                    this.form = data.result
                    this.loader = false
                })
        },
        loadCategory() {
            this.authGet('panel/categories-list', {
                section: this.form.section
            }).then((data) => {
                this.data_raw.categories = data.result
            })
        },
        updateData() {
            this.disabled = true;
            this.authPatch('panel/products/' + this.form.id, this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({title: data.message})
                        this.$router.push('/panel/services?section=' + this.form.section)
                    } else {
                        this.errors = data.errors
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        createData() {
            this.disabled = true;
            this.authPost('panel/products', this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({title: data.message})
                        let product_id = data.result.id;
                        if (product_id) {
                            this.$router.push('/panel/services?section=' + this.form.section)
                        }
                        this.errors = {};
                    } else {
                        this.errors = data.errors
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        parseErrors(field, type = 'status') {
            let has = false;
            let message = '';
            if (this.errors[field]) {
                message = this.errors[field][0];
                has = true;
            }

            if (type == 'status') {
                return has;
            } else {
                return message;
            }
        }
    },
    created() {
        let id = this.$route.params.product_id
        if (id == 'create') {
            this.edit_mode = false
            this.form.section = this.$route.query.section
        } else {
            this.edit_mode = true
            this.loadData()
        }
    },
    watch: {
        'form.section'(val){
            if(val){
                this.loadCategory()
            }
        }
    },
    computed: {}
}
</script>

<style scoped>
.profile-img {
    width: 45px;
    height: 45px;
    background: #111;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.footer-bar {
    position: absolute;
    bottom: 0;
    background: white;
}
</style>