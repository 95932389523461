<template>
    <div class="card mb-5 mb-xl-8">
        <div class="card-body py-8">
            <h3>Komentar</h3>
            <page-loader v-model:active="loader" loader="dots" :is-full-page="false"/>
            <div class="table-responsive">
                <table class="table align-middle">
                    <thead>
                    <tr class="fw-bold text-muted bg-light">
                        <th class="ps-4 text-center w-50px rounded-start">No</th>
                        <th>Nama User</th>
                        <th>Komentar</th>
<!--                        <th class="action">Status</th>-->
                        <th class="pr-2">Hapus</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="data_content.total === 0">
                        <td colspan="5" class="text-center"><i>tidak ada komentar</i></td>
                    </tr>
                    <tr v-for="(data, i) in data_content.data" :key="i">
                        <td class="ps-4 text-center border-bottom-1" :title="data.id">
                            {{ (data_content.current_page - 1) * data_content.per_page + i + 1 }}
                        </td>
                        <td>
                            {{ data.user_name }}
                        </td>
<!--                        <td></td>-->
                        <td>
                            <div class="text-bold">{{ data.title }}</div>
                            {{ data.body }}
                        </td>
<!--                        <td>-->
<!--                            <div class="form-check form-switch">-->
<!--                                <input class="form-check-input" type="checkbox" @change="updateStatus(data.id, $event)"-->
<!--                                       :id="'switch_status_' + data.id"-->
<!--                                       :checked="data.status">-->
<!--                                <label class="form-check-label"-->
<!--                                       :for="'switch_status_' + data.id">{{ data.status_label }}</label>-->
<!--                            </div>-->
<!--                        </td>-->
                        <td  class="pr-2 text-center">
                            <div @click="deleteModal(data)">
                                <unicon name="trash" width="20px" height="20px" fill="orange"
                                        class="cursor-pointer">
                                </unicon>
                            </div>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </div>
        <div class="border-top-1 p-4 d-flex justify-content-end align-items-center">
            <div class="me-3">
                Total {{ data_content.total }}
            </div>
            <div>
                <select class="form-control form-control-sm" v-model="filter.per_page"
                        @change="loadComments">
                    <option value="10">10/Page</option>
                    <option value="25">25/Page</option>
                    <option value="50">50/Page</option>
                    <option value="100">100/Page</option>
                </select>
            </div>
            <div class="mx-2">
                <laravel-pagination :data="data_content" :limit="2"
                                    @paginationChangePage="loadComments"></laravel-pagination>
            </div>
            <div class="me-3">
                Go to
            </div>
            <div>
                <select class="form-control form-control-sm select-center" style="min-width: 80px;"
                        v-model="filter.page">
                    <option :value="i" v-for="i in data_content.last_page">{{ i }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loader: false,
            data_detail: {},
            data_content: {
                data: '',
            },
            filter: {
                page: 1,
                per_page: 25,
            },
        }
    },
    methods: {
        loadComments() {
            this.authGet('panel/comments', {
                relation_id: this.$route.params.post_id,
                model: 'post',
            })
                .then((data) => {
                    this.data_content = data
                });
        },
        updateStatus(comment_id, event) {
            let status = event.target.checked ? 1 : 0;
            this.authPatch('panel/comment-status-update', {
                'comment_id': comment_id,
                'status': status,
            }).then((data) => {
                this.loadComments()
            })
        },
        deleteModal(data) {
            if (confirm('Hapus Data?')) {
                this.authDelete('panel/comments/' + data.id)
                    .then((data) => {
                        if (data.status) {
                            this.toaster({title: data.message})
                            this.loadComments()
                        }
                    })
            }
        },
    },
    created() {
        this.loadComments();
    }
}
</script>
<style scoped>
.form-check-input:checked {
    background-color: orange;
    border-color: orange;
}
</style>