<template>
    <div class="d-flex flex-column flex-column-fluid" data-select2-id="select2-data-131-6dxb">
        <div class="app-toolbar pt-4 pt-lg-5 mb-6">
            <div class="app-container container-xxl">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">

                </div>
                <div class="d-flex flex-stack my-3">
                    <h1 class="page-heading d-flex text-dark fw-bold flex-column justify-content-center my-0">
                        {{ title }}
                    </h1>
                    <div>
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control form-control-sm" placeholder="Cari.."
                                aria-label="Cari Disini" aria-describedby="button-addon2" @keyup.enter="loadDataContent()"
                                v-model="filter.s">
                            <button class="btn btn-theme" type="button" id="button-addon2" @click="loadDataContent">
                                Cari
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="btn-group">
                        <button class="btn btn-sm" :class="filter.subscribed === 'all' ? 'btn-tab' : ''"
                            style="border-radius: 0;" @click="filter.subscribed = 'all'">Semua</button>
                        <button class="btn btn-sm" :class="filter.subscribed === 'no' ? 'btn-tab' : ''"
                            @click="filter.subscribed = 'no'">Umum</button>
                        <button class="btn btn-sm" :class="filter.subscribed === 'yes' ? 'btn-tab' : ''"
                            @click="filter.subscribed = 'yes'">Berlangganan</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl">
                <div class="card mb-5 mb-xl-8">
                    <div class="card-body py-8">
                        <page-loader v-model:active="loader" loader="dots" :is-full-page="false" />
                        <div class="table-responsive">
                            <table class="table align-middle">
                                <thead>
                                    <tr class="fw-bold text-muted bg-light">
                                        <th class="ps-4 text-center w-50px rounded-start">No</th>
                                        <td></td>
                                        <th class="min-w-125px">Nama</th>
                                        <th>Tipe Langganan</th>
                                        <th>Email</th>
                                        <th>Registrasi</th>
                                        <th class="action">Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(data, i) in data_content.data" :key="i">
                                        <td class="ps-4 text-center border-bottom-1">
                                            <span class="text-dark fw-bold">
                                                {{ (data_content.current_page - 1) * data_content.per_page + i + 1 }}
                                            </span>
                                        </td>
                                        <td>
                                            <div class="position-relative" style="height: 35px; width: 35px;">
                                                <div :style="'background-image: url(' + data.image + ')'"
                                                    class="profile-img">
                                                </div>
                                                <div v-if="data.online" class="position-absolute bg-theme dot-online"></div>
                                            </div>
                                        </td>
                                        <td>
                                            {{ data.name }}
                                        </td>
                                        <td>
                                            <span v-if="data.package_active">{{ data.package_active }}</span>
                                            <span v-else>-</span>
                                        </td>
                                        <td>
                                            {{ data.email }}
                                        </td>
                                        <td>
                                            {{ $filters.formatDate(data.created_at) }}
                                        </td>
                                        <td class="action">
                                            <router-link :to="'/panel/members/' + data.id" class="btn btn-sm btn-theme">
                                                Detail
                                            </router-link>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="border-top-1 p-4 d-flex justify-content-end align-items-center">
                        <div class="me-3">
                            Total {{ data_content.total }}
                        </div>
                        <div>
                            <select class="form-control form-control-sm" v-model="filter.per_page"
                                @change="loadDataContent">
                                <option value="10">10/Page</option>
                                <option value="25">25/Page</option>
                                <option value="50">50/Page</option>
                                <option value="100">100/Page</option>
                            </select>
                        </div>
                        <div class="mx-2">
                            <laravel-pagination :data="data_content" :limit="2"
                                @paginationChangePage="loadDataContent"></laravel-pagination>
                        </div>
                        <div class="me-3">
                            Go to
                        </div>
                        <div>
                            <select class="form-control form-control-sm select-center" style="min-width: 80px;"
                                v-model="filter.page">
                                <option :value="i" v-for="i in data_content.last_page">{{ i }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loader: false,
            disabled: false,
            edit_mode: false,
            pswd_show: false,
            value: '',
            selected_radio: 1,
            form: new form({
                id: '',
                name: '',
                email: '',
                role_id: '',
                institution_id: '',
                password: '',
                password_confirmation: '',
            }),
            data_content: {},
            data_raw: {
                categories: [],
            },
            filter: {
                page: 1,
                per_page: '10',
                type: 'member',
                order: 'DESC',
                order_field: 'id',
                s: '',
                subscribed: 'all',
            },
            title: 'Informasi Pengguna'
        }
    },
    methods: {
        loadDataContent(page = 1) {
            this.filter.page = page
            this.loader = true
            this.authGet('panel/users', this.filter)
                .then((data) => {
                    this.data_content = data
                    this.loader = false
                })
        },
        loadCategories() {
            this.authGet('panel/categories-list', this.filter)
                .then((data) => {
                    this.data_raw.categories = data.result
                })
        },
        pswdTogle() {
            this.pswd_show = !this.pswd_show
        },
    },
    created() {
        this.loadDataContent()
        this.loadCategories()
    },
    watch: {
        $route(to) {
            this.filter.div = to.query.div
            this.loadDataContent()
        },
        'filter.status'(val) {
            this.loadDataContent()
        },
        'filter.subscribed'(val) {
            this.loadDataContent()
        },
    }
}
</script>

<style scoped>


.profile-img {
    width: 35px;
    height: 35px;
    background: #111;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.dot-online {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    top: 3px;
    right: 2px
}
</style>