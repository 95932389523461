<template>
    <div class="d-flex flex-column flex-column-fluid" style="position: relative">
        <div class="app-toolbar pt-4 pt-lg-5 mb-6">
            <div class="app-container container-xxl">
                <div class="me-3">
                    <small><span @click="$router.go(-1)" class="text-theme cursor-pointer">Layanan</span>
                        / Detail</small>
                </div>
                <div class="d-flex my-4 justify-content-between align-items-center">
                    <div class="d-flex my-0 align-items-center">
                        <div>
                            {{ data_detail.category_name }}
                            <h3 class="fw-bold mt-3">{{ data_detail.name }}</h3>
                        </div>
                    </div>
                    <div>
                        <button @click="deleteModal" class="btn mx-1 btn-sm btn-outline-theme">Hapus</button>
                        <router-link :to="'/panel/services-edit-add/' + this.$route.params.product_id"
                                     class="btn mx-1 btn-sm btn-theme">Edit
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl">
                <page-loader v-model:active="loader" loader="dots" :is-full-page="false"/>
                <div class="card mb-3">
                    <div class="card-body">
                        <b>Deskripsi</b>
                        <div class="border border-theme p-4 mt-2 rounded">
                            {{ data_detail.desc }}
                        </div>
                    </div>
                </div>
                <div class="card  mb-3">
                    <div class="card-body">
                        <b>Informasi Harga</b>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Harga terendah</label>
                                <div class="border border-theme p-4 mt-2 rounded">
                                    Rp {{ $filters.currency(data_detail.min_price) }}
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label>Harga tertinggi</label>
                                <div class="border border-theme p-4 mt-2 rounded">
                                    Rp {{ $filters.currency(data_detail.max_price) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="card-body">
                        <b>Text Whatsapp</b>
                        <div class="border border-theme p-4 mt-2 rounded">
                            {{ data_detail.direct_link }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loader: false,
            disabled: false,
            edit_mode: false,
            data_detail: {
                created_at: '',
            }
        }
    },
    methods: {
        loadData() {
            this.loader = true
            this.authGet('panel/products/' + this.$route.params.product_id, this.filter)
                .then((data) => {
                    this.data_detail = data.result
                    this.loader = false
                })
        },
        deleteModal() {
            if (confirm('Hapus Data?')) {
                this.authDelete('panel/products/' + this.$route.params.product_id)
                    .then((data) => {
                        if (data.status) {
                            this.toaster({title: data.message})
                            this.$router.push('/panel/services?section=' + this.$store.state.section_text)
                        } else {

                        }
                    })
            }
        }
    },
    created() {
        this.loadData()
    },
    watch: {},
    computed: {
        register_date() {
            // return this.data_detail.created_at.slice(0,10)
        }
    }
}
</script>

<style scoped>
.profile-img {
    width: 45px;
    height: 45px;
    background: #111;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.footer-bar {
    position: absolute;
    bottom: 0;
    background: white;
}
</style>