<template>
    <div id="kt_app_sidebar" class="app-sidebar flex-column" data-kt-drawer="true" data-kt-drawer-name="app-sidebar"
         data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="225px"
         data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle">

        <div class="app-sidebar-logo px-6" style="background-color: #F78F2D;">
            <div class="d-flex align-items-center justify-content-center">
                <div>
                    <img alt="Logo" src="/assets/img/logo-legal2us-white.png" style="height: 40px"
                         class="app-sidebar-logo-default">
                    <img alt="Logo" src="/assets/img/logo-legal2us-sqr-white.png" style="width: 35px"
                         class="app-sidebar-logo-minimize">
                </div>
            </div>

            <div id="kt_app_sidebar_toggle"
                 class="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate"
                 data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body"
                 data-kt-toggle-name="app-sidebar-minimize">
                <span class="svg-icon svg-icon-2 rotate-180">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.5"
                              d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
                              fill="currentColor"></path>
                        <path
                            d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
                            fill="currentColor"></path>
                    </svg>
                </span>
            </div>

        </div>

        <div class="app-sidebar-menu overflow-hidden flex-column-fluid elevation-1">
            <div id="kt_app_sidebar_menu_wrapper" class="app-sidebar-wrapper hover-scroll-overlay-y my-5"
                 data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto"
                 data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
                 data-kt-scroll-wrappers="#kt_app_sidebar_menu" data-kt-scroll-offset="5px"
                 data-kt-scroll-save-state="true"
                 style="height: 755px;">
                <div class="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu"
                     data-kt-menu="true" data-kt-menu-expand="false">

                    <div v-for="menu in main_menu" :key="menu.id">
                        <div class="menu-item" v-if="menu.children == null">
                            <router-link class="menu-link" :to="menu.link">
                                <span class="menu-icon">
                                    <unicon :name="menu.icon" fill=""></unicon>
                                </span>
                                <span class="menu-title">{{ menu.name }}</span>
                            </router-link>
                        </div>

                        <div data-kt-menu-trigger="click" v-if="menu.children != null" class="menu-item menu-accordion">
                            <span class="menu-link">
                                <span class="menu-icon">
                                    <unicon :name="menu.icon" fill=""></unicon>
                                </span>
                                <span class="menu-title">{{ menu.name }}</span>
                                <span class="menu-arrow"></span>
                            </span>
                            <div class="menu-sub menu-sub-accordion" :kt-hidden-height="menu.height">
                                <div class="menu-item" v-for="submenu in menu.children" :key="submenu.id">
                                    <router-link class="menu-link d-flex justify-content-between" :to="submenu.link">
                                        <div class="d-flex">
                                            <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                            </span>
                                            <span class="menu-title">{{ submenu.name }}</span>
                                        </div>
                                        <div>
                                            <span class="notify" v-if="submenu.counter && submenu.counter > 0">{{submenu.counter}}</span>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            main_menu: [
                {
                    children: null,
                    icon: "dashboard",
                    id: 1,
                    link: "/panel/home",
                    name: "Dashboard",
                    parent_id: "0",
                    status: 1
                },
                {
                    children: null,
                    icon: "money-withdraw",
                    id: 1,
                    link: "/panel/transactions",
                    name: "Transaksi",
                    parent_id: "0",
                    status: 1
                },
                {
                    children: null,
                    icon: "user-plus",
                    id: 1,
                    link: "/panel/members",
                    name: "Informasi Pengguna",
                    parent_id: "0",
                    status: 1
                },
                {
                    children: null,
                    icon: "user-exclamation",
                    id: 1,
                    link: "/panel/partners",
                    name: "Mitra Konsultan",
                    parent_id: "0",
                    status: 1
                },
                {
                    children: null,
                    icon: "building",
                    id: 1,
                    link: "/panel/company-profile",
                    name: "Company Profile",
                    parent_id: "0",
                    status: 1
                },
                {
                    icon: "balance-scale",
                    id: 1,
                    link: "#",
                    name: "Legal 2 Pro",
                    parent_id: "0",
                    status: 1,
                    children: [
                        {
                            id: 1,
                            link: "/panel/packages",
                            name: "Paket",
                            parent_id: "0",
                            status: 1,
                        },
                        {
                            id: 1,
                            link: "/panel/products-legal",
                            name: "Produk",
                            parent_id: "0",
                            status: 1,
                        },
                    ]
                },
                {
                    icon: "file-contract",
                    id: 1,
                    link: "/panel/home",
                    name: "Layanan",
                    parent_id: "0",
                    status: 1,
                    children: [
                        {
                            id: 1,
                            link: "/panel/services?section=Litigasi",
                            name: "Litigasi",
                            parent_id: "0",
                            status: 1,
                        },
                        {
                            id: 1,
                            link: "/panel/services?section=Literasi_Hukum",
                            name: "Literasi Hukum",
                            parent_id: "0",
                            status: 1,
                        },
                        {
                            id: 1,
                            link: "/panel/services-konsultasi",
                            name: "Konsultasi Hukum",
                            parent_id: "0",
                            status: 1,
                        }
                    ]
                },
                {
                    icon: "video",
                    id: 1,
                    link: "/panel/home",
                    name: "Kelas",
                    parent_id: "0",
                    status: 1,
                    children: [
                        {
                            icon: "dashboard",
                            id: 1,
                            link: "/panel/products?section=Ruang_Kelas",
                            name: "Ruang Kelas",
                            parent_id: "0",
                            status: 1,
                        },
                        {
                            icon: "dashboard",
                            id: 1,
                            link: "/panel/products?section=Pelatihan",
                            name: "Pelatihan",
                            parent_id: "0",
                            status: 1,
                        },
                        {
                            icon: "dashboard",
                            id: 1,
                            link: "/panel/posts?section=Kelola_Acara",
                            name: "Acara",
                            parent_id: "0",
                            status: 1,
                        }
                    ]
                },
                {
                    icon: "apps",
                    id: 1,
                    link: "#",
                    name: "Kelola Konten",
                    parent_id: "0",
                    status: 1,
                    children: [
                        {
                            icon: "dashboard",
                            id: 1,
                            link: "/panel/posts?section=Artikel",
                            name: "Artikel",
                            parent_id: "0",
                            status: 1,
                        },
                        {
                            icon: "dashboard",
                            id: 1,
                            link: "/panel/posts?section=Info_Grafik",
                            name: "Info Grafik",
                            parent_id: "0",
                            status: 1,
                        },
                        {
                            icon: "dashboard",
                            id: 1,
                            link: "/panel/posts?section=Analisis_Hukum",
                            name: "Analisis Hukum",
                            parent_id: "0",
                            status: 1,
                        },
                        {
                            icon: "dashboard",
                            id: 1,
                            link: "/panel/posts-pusat-data",
                            name: "Pusat Data",
                            parent_id: "0",
                            status: 1,
                        },
                        {
                            icon: "dashboard",
                            id: 1,
                            link: "/panel/posts-discussion",
                            name: "Wawasan",
                            parent_id: "0",
                            status: 1,
                        },
                    ]
                },
                {
                    icon: "setting",
                    id: 1,
                    link: "/panel/home",
                    name: "Pengaturan",
                    parent_id: "0",
                    status: 1,
                    children: [
                        {
                            icon: "",
                            id: 1,
                            link: "/panel/categories",
                            name: "Kategori",
                            parent_id: "0",
                            status: 1,
                        },
                        {
                            icon: "",
                            id: 1,
                            link: "/panel/posts?section=Klien_Kami",
                            name: "Klien Kami",
                            parent_id: "0",
                            status: 1,
                        },
                        {
                            icon: "",
                            id: 1,
                            link: "/panel/posts?section=Pertanyaan",
                            name: "Pertanyaan",
                            parent_id: "0",
                            status: 1,
                        },
                        {
                            icon: "",
                            id: 1,
                            link: "/panel/posts?section=Testimoni",
                            name: "Testimoni",
                            parent_id: "0",
                            status: 1,
                        },
                        {
                            icon: "",
                            id: 1,
                            link: "/panel/configurations",
                            name: "Konfigurasi",
                            parent_id: "0",
                            status: 1,
                        },
                    ]
                },
                {
                    icon: "lock-access",
                    id: 1,
                    link: "/panel/superadmin",
                    name: "Managemen User",
                    parent_id: "0",
                    status: 1,
                    children: [
                        {
                            icon: "",
                            id: 1,
                            link: "/panel/users",
                            name: "Admin",
                            parent_id: "0",
                            status: 1,
                        },
                        {
                            icon: "",
                            id: 1,
                            link: "/panel/roles",
                            name: "Role",
                            parent_id: "0",
                            status: 1,
                        },
                        {
                            icon: "",
                            id: 1,
                            link: "/panel/menus",
                            name: "Menu",
                            parent_id: "0",
                            status: 1,
                        },
                    ],
                }
            ],
        }
    },
    methods: {
        getMenu() {
            this.authGet('panel/menus-list')
                .then((data) => {
                    this.main_menu = data.result
                });
        }
    },
    created() {
        this.getMenu();
        this.emitter.on("reload-menu", () => {
            this.getMenu()
        })

        setInterval(()=>{
            this.getMenu();
        }, 30000)
    }
}
</script>
<style>
.notify{
    font-size: smaller;
    background-color: darkorange;
    color: white;
    padding-right: 4px;
    padding-left: 4px;
    border-radius: 8px;
}
</style>