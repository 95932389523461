<template>
    <div class="d-flex flex-column flex-column-fluid" data-select2-id="select2-data-131-6dxb">
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl pt-16">
                <div class="row">
                    <div class="col-md-8">
                        <h1>Dashboard</h1>
                    </div>
                    <div class="col-md-4">

                    </div>
                    <div class="col-xl-5 mt-6">
                        <div class="row">
                            <div class="col-6 col-md-3 col-lg-6 my-2">
                                <div class="card px-4 py-6">
                                    <div class="d-flex justify-content-between">
                                        <div class="bg-secondary p-4 p-lg-8 p-md-6 rounded">
                                            <unicon name="exchange"></unicon>
                                        </div>
                                        <div>
                                            <unicon name="info-circle" fill="grey"></unicon>
                                        </div>
                                    </div>
                                    <h5 class="py-4">Total Transaksi</h5>
                                    <h1>{{ $filters.currency(stats.transaction_count) }}</h1>
                                    <span class="text-secondary">Transaksi</span>
                                </div>
                            </div>
                            <div class="col-6 col-md-3 col-lg-6 my-2">
                                <div class="card px-4 py-6">
                                    <div class="d-flex justify-content-between">
                                        <div class="bg-secondary p-4 p-lg-8 p-md-6 rounded">
                                            <unicon name="wallet"></unicon>
                                        </div>
                                        <div></div>
                                    </div>
                                    <h5 class="py-4">Pendapatan</h5>
                                    <h1>Rp {{ $filters.currency(stats.earning) }}</h1>
                                    <span class="text-secondary">Rupiah</span>
                                </div>
                            </div>
                            <div class="col-6 col-md-3 col-lg-6 my-2">
                                <div class="card px-4 py-6">
                                    <div class="d-flex justify-content-between">
                                        <div class="bg-secondary p-4 p-lg-8 p-md-6 rounded">
                                            <unicon name="rss-interface"></unicon>
                                        </div>
                                        <div></div>
                                    </div>
                                    <h5 class="py-4">Berlangganan</h5>
                                    <h1>{{ $filters.currency(stats.member_subscribe) }}</h1>
                                    <span class="text-secondary">User</span>
                                </div>
                            </div>
                            <div class="col-6 col-md-3 col-lg-6 my-2">
                                <div class="card px-4 py-6">
                                    <div class="d-flex justify-content-between">
                                        <div class="bg-secondary p-4 p-lg-8 p-md-6 rounded">
                                            <unicon name="users-alt"></unicon>
                                        </div>
                                        <div></div>
                                    </div>
                                    <h5 class="py-4">Total User</h5>
                                    <h1>{{ $filters.currency(stats.member_total) }}</h1>
                                    <span class="text-secondary">User</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-7 mt-6">
                        <div class="card p-6">
                            <div class="d-flex justify-content-between">
                                <div class="d-flex">
                                    <div>
                                        <div class="bg-secondary p-4 p-lg-8 p-md-6 rounded">
                                            <unicon name="shopping-cart"></unicon>
                                        </div>
                                    </div>
                                    <div class="ps-4">
                                        <h5 class="pb-2">Penjualan</h5>
                                        <h1>Rp {{ $filters.currency(stats.chart_total) }}</h1>
                                        <span class="text-secondary">Rupiah</span>
                                    </div>
                                </div>
                                <div>
                                    <VueCtkDateTimePicker right="false" v-bind="date_range_option_global" width="100%"
                                                          v-model="filter.dates"></VueCtkDateTimePicker>
                                </div>
                            </div>
                            <div>
                                <Line v-if="chart_loaded"
                                      id="my-chart-id"
                                      :options="chartOptions"
                                      :data="chartData"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8 mt-4">
                        <div class="card px-6 py-8">
                            <h4>Transaksi Terbaru</h4>
                            <table class="table" v-if="transactions.length > 0">
                                <tr v-for="trx in transactions">
                                    <td class="d-flex justify-content-center px-2 align-top py-2">
                                        <div class="position-relative" style="height: 40px; width: 40px;">
                                            <div class="profile-img" v-if="trx.user_profile"
                                                 :style="'background-image: url('+ trx.user_profile.image +')'"></div>
                                            <div class="profile-img" v-else></div>
                                            <!--                                            <div class="position-absolute bg-theme dot-online"></div>-->
                                        </div>
                                    </td>
                                    <td class="p-1 align-top py-2">
                                        <b class="p-0">{{ trx.user_name }}</b>
                                        <br>
                                        ID: <span v-if="trx.user">{{ trx.user.id_display }}</span>
                                    </td>
                                    <td class="align-top py-2">
                                        {{ trx.relation_name }}
                                        <br>
                                        <i>Rp {{ $filters.currency(trx.total) }}</i>
                                    </td>
                                    <td class="align-top py-2"></td>
                                    <td>{{ $filters.formatDateTime(trx.created_at) }}</td>
                                </tr>
                            </table>
                            <div class="italic text-secondary" v-if="transactions.length === 0">
                                tidak ada transaksi terbaru
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mt-4">
                        <div class="card px-6 py-8">
                            <MemberProList></MemberProList>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'
import {Line} from 'vue-chartjs'
import MemberProList from "./MemberProList.vue";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

export default {
    data() {
        return {
            data_chart: [],
            stats: {
                earning: '',
                member_subscribe: '',
                member_total: '',
                transaction_count: '',
                chart_total: '',
            },
            transactions: [],
            chart_loaded: false,
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: 'Trasaksi',
                        borderJoinStyle: 'round',
                        data: [],
                        borderColor: 'rgb(255,124,0)',
                        borderCapStyle: 'round',
                        pointBackgroundColor: '#FFF',
                        cubicInterpolationMode: 'monotone',
                        tension: 3
                    },
                    {
                        label: 'Transaksi Terdahulu',
                        borderJoinStyle: 'round',
                        data: [],
                        borderColor: 'rgb(75,74,74)',
                        borderCapStyle: 'round',
                        pointBackgroundColor: '#FFF',
                        cubicInterpolationMode: 'monotone',
                        borderDash: [6, 6],
                        borderDashOffset: 2,
                        tension: 3
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: true,
                borderJoinStyle: 'round',
            },
            filter: {
                dates: {
                    end: '',
                    start: '',
                    shortcut: '',
                }
            }
        }
    },
    components: {
        MemberProList,
        Line
    },
    methods: {
        loadStat() {
            this.authGet('panel/dashboard-stats')
                .then((data) => {
                    this.stats.earning = data.result.earning
                    this.stats.member_subscribe = data.result.member_subscribe
                    this.stats.member_total = data.result.member_total
                    this.stats.transaction_count = data.result.transaction_count
                })
        },
        loadTransaction() {
            this.authGet('panel/dashboard-transactions')
                .then((data) => {
                    this.transactions = data.result
                })
        },
        loadChart() {
            this.chart_loaded = false;
            this.authGet('panel/dashboard-chart', {
                start: this.filter.dates.start,
                end: this.filter.dates.end,
            })
                .then((data) => {
                    this.stats.chart_total = data.total

                    let label = [];
                    let data_set_1 = [];
                    let data_set_2 = [];
                    this.data_chart = data.result;
                    let length = Object.keys(data.result).length;

                    for (let i = 1; i <= length; i++) {
                        let day = data.result[i]['date'].substr(8, 2)
                        let data_1 = data.result[i].count
                        let data_2 = data.result[i].count_last
                        label.push(day)

                        data_set_1.push(data_1)
                        data_set_2.push(data_2)
                    }

                    this.chartData.labels = label
                    this.chartData.datasets[0].data = data_set_1
                    this.chartData.datasets[1].data = data_set_2
                    this.chart_loaded = true
                })
        }
    },
    created() {
        this.loadChart()
        this.loadStat()
        this.loadTransaction()
    },
    watch: {
        'filter.dates.end'(val) {
            if (val) {
                this.loadChart()
            }
        },
    }
}
</script>

<style scoped>
td {
    vertical-align: center;
}

.profile-img {
    width: 35px;
    height: 35px;
    background: #8a8a8a;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.dot-online {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    top: 3px;
    right: 2px
}

.text-secondary {
    color: grey !important;
}
</style>