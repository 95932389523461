<template>
    <div class="d-flex flex-column flex-column-fluid" data-select2-id="select2-data-131-6dxb">
        <div class="app-toolbar pt-4 pt-lg-5 mb-6">
            <div class="app-container container-xxl">
                <div class="page-title d-flex justify-content-between my-4">
                    <h1 class="page-heading text-dark my-0">
                        Konsultasi Hukum
                    </h1>
                </div>
            </div>
        </div>
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl">
                <div class="card mb-5 mb-xl-8">
                    <div class="card-body py-8">
                        <page-loader v-model:active="loader" loader="dots" :is-full-page="false"/>
                        <div class="table-responsive">
                            <table class="table align-middle">
                                <thead>
                                <tr class="fw-bold text-muted bg-light">
                                    <th class="ps-4 text-center w-50px rounded-start">No</th>
                                    <th class="min-w-125px">Nama Produk</th>
                                    <th>Deskripsi</th>
                                    <th class="text-center">Jumlah Item</th>
                                    <th class="text-right">Aksi</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(data, i) in data_content.data" :key="i">
                                    <td class="ps-4 text-center border-bottom-1">
                                        <span class="text-dark fw-bold">{{ i + 1 }}</span>
                                    </td>
                                    <td>
                                        <span class="text-dark fw-bold">{{ data.name }}</span>
                                    </td>
                                    <td>
                                        {{ data.desc }}
                                    </td>
                                    <td class="text-center">
                                        {{ data.product_details_count }}
                                    </td>
                                    <td class="text-right">
                                        <router-link :to="'/panel/services-konsultasi/' + data.id"
                                                     class="btn btn-sm btn-outline-theme">
                                            Detail
                                        </router-link>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="border-top-1 p-4 d-flex justify-content-end align-items-center">
                        <div class="me-3">
                            Total {{ data_content.total }}
                        </div>
                        <div>
                            <select class="form-control form-control-sm" v-model="filter.per_page"
                                    @change="loadDataContent">
                                <option value="10">10/Page</option>
                                <option value="25">25/Page</option>
                                <option value="50">50/Page</option>
                                <option value="100">100/Page</option>
                            </select>
                        </div>
                        <div class="mx-2">
                            <laravel-pagination :data="data_content" :limit="2"
                                                @paginationChangePage="loadDataContent"></laravel-pagination>
                        </div>
                        <div class="me-3">
                            Go to
                        </div>
                        <div>
                            <select class="form-control form-control-sm select-center" style="min-width: 80px;"
                                    v-model="filter.page">
                                <option :value="i" v-for="i in data_content.last_page">{{ i }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loader: false,
            disabled: false,
            edit_mode: false,
            pswd_show: false,
            value: '',
            selected_radio: 1,
            form: new form({
                id: '',
                name: '',
                email: '',
                role_id: '',
                institution_id: '',
                password: '',
                password_confirmation: '',
            }),
            data_content: {},
            data_raw: {
                categories: [],
            },
            filter: {
                page: 1,
                per_page: 10,
                name: '',
                status: '',
                category_id: '',
                sections: 'consultation-chat,consultation-video-call,consultation-tax',
                s: '',
            },
            title: ''
        }
    },
    methods: {
        loadDataContent(page = 1) {
            this.filter.page = page
            this.loader = true
            this.authGet('panel/products', this.filter)
                .then((data) => {
                    this.data_content = data
                    this.loader = false
                })
        },
    },
    created() {
        this.loadDataContent()
    },
    watch: {
        'filter.status'(val) {
            this.loadDataContent()
        }
    }
}
</script>

<style scoped>

</style>