<template>
    <div class="d-flex flex-column flex-column-fluid" data-select2-id="select2-data-131-6dxb">
        <div class="app-toolbar py-4 pt-lg-5 mb-6">
            <div class="app-container container-xxl">
                <div class="d-flex justify-content-between mx-3 align-items-center">
                    <div>
                        <span>
                            <router-link class="text-theme" to="/panel/posts-discussion"
                                         v-if="data_detail.section === 'discussion'">Diskusi </router-link>
                            <router-link class="text-theme" :to="'/panel/posts?section=' + data_detail.section" v-else>
                                <span v-if="data_detail.section === 'data_center'">Pusat Data </span>
                                <span v-else>Kelola Konten </span>
                            </router-link>
                        </span>
                        / Detail
                    </div>
                    <div>
                        <router-link :to="'/panel/posts-edit-add/' + this.$route.params.post_id"
                                     v-if="data_detail.section !== 'data_center'"
                                     class="btn btn-theme btn-sm mx-1">Edit
                        </router-link>
                        <router-link :to="'/panel/posts-edit-add-pusat-data/' + this.$route.params.post_id"
                                     v-if="data_detail.section === 'data_center'"
                                     class="btn btn-theme btn-sm mx-1">Edit
                        </router-link>
                        <button @click="deleteModal" class="btn btn-outline-theme btn-sm mx-1">Hapus</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl">
                <div class="card mb-4">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="d-flex p-6 align-items-top">
                                    <div @click="$router.go(-1)" class="me-3">
                                        <unicon name="arrow-circle-left" fill="orange" width="35px"
                                                height="35px"></unicon>
                                    </div>
                                    <div>
                                        <h3>{{ data_detail.title }}</h3>
                                        <h4 class="fw-normal mt-3 mb-4">{{ data_detail.subtitle }}</h4>
                                        <div class="d-flex">
                                            <div class="me-3" v-if="data_detail.section !== 'data_center'">
                                                Penulis :
                                                <b v-if="data_detail.author">{{ data_detail.author.name }} </b>
                                                <b v-if="!data_detail.author">{{ data_detail.author_name }} </b>
                                            </div>
                                            <div class="me-3">
                                                Kategori : <b>{{ data_detail.category_name }} </b>
                                            </div>
                                            <div class="me-3">
                                                Tipe Akses : <b> {{ data_detail.type_access }} </b>
                                            </div>
                                            <div class="me-3" v-if="data_detail.section !== 'data_center'">
                                                Dibuat Pada :<b> {{
                                                    $filters.formatDateTime(data_detail.created_at)
                                                }} </b>
                                            </div>
                                            <div class="me-3">
                                                Ditetapkan :<b v-if="data_detail.post_detail"> {{
                                                    $filters.formatDate(data_detail.post_detail.release_at)
                                                }} </b>
                                            </div>
                                            <div class="me-3">
                                                Berlaku :<b v-if="data_detail.post_detail"> {{
                                                    $filters.formatDate(data_detail.post_detail.active_at)
                                                }} </b>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div style="width: 100%;" class="mb-5 text-center"
                                     v-if="data_detail.section !== 'data_center'">
                                    <img :src="data_detail.image" style="max-width: 80%; max-height: 400px" alt="">
                                </div>

                                <div v-html="data_detail.body" v-if="data_detail.section !== 'data_center'"></div>

                                <div v-if="data_detail.category_id == jurnal_id" class="mb-6">
                                    <h5 class="text-theme">Abstract</h5>
                                    <div v-if="data_detail.post_detail" class="mb-10">
                                        {{ data_detail.post_detail.abstract }}
                                    </div>

                                    <h5 class="text-theme">Kata Kunci</h5>
                                    <div v-if="data_detail.post_detail">
                                        {{ data_detail.post_detail.keyword }}
                                    </div>
                                </div>

                                <div class="text-center" v-if="data_detail.section === 'data_center' && data_detail.category_id != jurnal_id">
                                    <embed v-if="data_detail.post_detail && data_detail.post_detail.file"
                                           :src="data_detail.post_detail.file"
                                           type="application/pdf" width="100%" height="600px">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div v-if="data_detail.post_detail && data_detail.category_id == jurnal_id">
                                    <div v-if="data_detail.category_type">
                                        <img class="mb-3" :src="data_detail.category_type.image" alt="" style="height: 50px">
                                        <h5 class="text-theme">{{data_detail.category_type.name}}</h5>
                                    </div>
                                    <div>
                                        <div class="my-1">{{data_detail.post_detail.resource_version}}</div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="my-1 mx-2">{{data_detail.post_detail.resource_number_p}}</div>
                                        <div class="my-1 mx-2">{{data_detail.post_detail.resource_number_e}}</div>
                                    </div>
                                    <div class="mb-4">
                                        <ul>
                                            <li>{{data_detail.post_detail.resource_name}}</li>
                                        </ul>
                                    </div>
                                    <div class="mb-4 border-top border-bottom border-secondary py-3">
                                        <a target="_blank" class="mx-2" :href="data_detail.post_detail.resource_link">Tautan Asal</a>
                                        <a target="_blank" class="mx-2" :href="data_detail.post_detail.resource_name_link">Tautan Penulis</a>
                                    </div>
                                    <div>
                                        <a :href="data_detail.post_detail.file" target="_blank" class="btn btn-theme btn-sm">
                                            Lihat File
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div v-if="data_detail.section !== 'data_center'">
                    <Comments></Comments>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Comments from "./Comments";

export default {
    data() {
        return {
            loader: false,
            data_detail: {},
        }
    },
    methods: {
        loadData() {
            this.authGet('panel/posts/' + this.$route.params.post_id)
                .then((data) => {
                    this.data_detail = data.result
                    console.log(this.data_detail.section)
                });
        },
        deleteModal() {
            if (confirm('Hapus Data?')) {
                this.authDelete('panel/posts/' + this.$route.params.post_id)
                    .then((data) => {
                        if (data.status) {
                            this.toaster({title: data.message})
                            if (this.data_detail.section === 'discussion') {
                                this.$router.push('/panel/posts-discussion')
                            } else {
                                this.$router.push('/panel/posts?section=' + localStorage.getItem('post_section'))
                            }
                        }
                    })
            }
        },
    },
    created() {
        this.loadData();
    },
    components: {
        Comments
    }
}
</script>
<style>
.text-sm {
    font-size: 12px;
    font-weight: 600;
}

.text-sm span {
    color: rgb(119, 119, 119);
}
</style>