<template>
    <div class="d-flex flex-column flex-column-fluid" style="position: relative">
        <div class="app-toolbar pt-4 pt-lg-5 mb-6">
            <div class="app-container container-xxl">
                <div class="me-3">
                    <small>
                        <span @click="$router.go(-1)" class="text-theme cursor-pointer">Layanan</span> /
                        <span @click="$router.go(-1)" class="text-theme cursor-pointer">Konsultasi Hukum</span> /
                        Edit
                    </small>
                </div>
                <div class="d-flex my-4 justify-content-between align-items-center">
                    <div class="d-flex my-0 align-items-center">
                        <div>
                            <h3 class="fw-bold mt-3">Edit Konsultasi Hukum</h3>
                        </div>
                    </div>
                    <div>
                        <button @click="updateData" :disabled="disabled"
                                class="btn mx-1 btn-sm btn-theme">
                            <span v-if="!disabled">Simpan Perubahan</span>
                            <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl">
                <page-loader v-model:active="loader" loader="dots" :is-full-page="false"/>
                <div class="card mb-3">
                    <div class="card-body">
                        <h3>Informasi Layanan</h3>
                        <div class="form-group">
                            <label>Nama</label>
                            <input class="form-control" v-model="data_detail.name">
                            <span class="error-text" v-if="parseErrors('name')">{{ parseErrors('name', 'm') }}</span>
                        </div>
                        <div class="form-group">
                            <label>Deskripsi</label>
                            <textarea class="form-control" rows="5" v-model="data_detail.desc"></textarea>
                        </div>
                        <div class="form-group">
                            <label>Text Whatsapp</label>
                            <textarea class="form-control" rows="3" v-model="data_detail.direct_link"></textarea>
                            <span class="error-text" v-if="parseErrors('direct_link')">{{ parseErrors('direct_link', 'm') }}</span>
                        </div>
                    </div>
                </div>
                <KonsultasiItem :product_id="this.$route.params.product_id"></KonsultasiItem>
            </div>
        </div>
    </div>
</template>

<script>
import KonsultasiItem from "./KonsultasiItem";
export default {
    components: {KonsultasiItem},
    data() {
        return {
            loader: false,
            disabled: false,
            edit_mode: false,
            product_details: [],
            form_errors: [],
            data_detail: {
                id: '',
                created_at: '',
            }
        }
    },
    methods: {
        loadData() {
            this.loader = true
            this.authGet('panel/products/' + this.$route.params.product_id, this.filter)
                .then((data) => {
                    this.data_detail = data.result
                    this.loader = false
                })
        },
        updateData() {
            this.disabled = true;
            this.authPatch('panel/products/' + this.data_detail.id, this.data_detail)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({title: data.message})
                        this.$router.push('/panel/services-konsultasi')
                    } else {
                        this.form_errors = data.errors
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        parseErrors(field, type = 'status') {
            let has = false;
            let message = '';
            if (this.form_errors[field]) {
                message = this.form_errors[field][0];
                has = true;
            }

            if (type === 'status') {
                return has;
            } else {
                return message;
            }
        }
    },
    created() {
        this.loadData()
    },
    watch: {},
}
</script>

<style scoped>
.profile-img {
    width: 45px;
    height: 45px;
    background: #111;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.footer-bar {
    position: absolute;
    bottom: 0;
    background: white;
}
</style>