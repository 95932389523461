<template>
    <div class="d-flex flex-column flex-column-fluid" data-select2-id="select2-data-131-6dxb">
        <div class="app-toolbar pt-4 pt-lg-5 mb-6">
            <div class="app-container container-xxl">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 class="page-heading d-flex text-dark fw-bold flex-column justify-content-center my-0">
                        {{ title }}
                    </h1>
                </div>
                <div class="d-flex flex-stack my-3">
                    <div>
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control form-control-sm" v-model="filter.s"
                                   placeholder="Kata kunci.." @keyup.enter="loadDataContent" aria-label="Cari Disini"
                                   aria-describedby="button-addon2">
                            <button class="btn btn-theme" type="button" id="button-addon2" @click="loadDataContent">
                                Cari
                            </button>
                        </div>
                    </div>
                    <div class="text-right">
                        <button class="btn btn-sm mx-1 btn-outline-theme" @click="resetFilter">
                            Reset Filter
                        </button>
                        <router-link :to="'/panel/categories?section=discussion'"
                                     class="btn btn-sm mx-1 btn-outline-theme">Kelola Kategori
                        </router-link>
                        <router-link to="/panel/posts-edit-add/create" class="btn btn-sm mx-1 btn-theme">Tambah
                        </router-link>
                    </div>
                </div>
                <div>
                    <div class="btn-group">
                        <button class="btn btn-sm" :class="filter.status === 0 ? 'btn-tab' : ''"
                                @click="filter.status = 0">Draft
                        </button>
                        <button class="btn btn-sm" :class="filter.status === 1 ? 'btn-tab' : ''"
                                @click="filter.status = 1">Publish
                        </button>
                        <button class="btn btn-sm" :class="filter.status === 2 ? 'btn-tab' : ''"
                                @click="filter.status = 2"
                                style="border-radius: 0;">Archive
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl">
                <div class="card mb-4 mb-xl-4">
                    <div class="card-body py-5">
                        <div class="row">
                            <div class="col-md-3">
                                <select class="form-control form-control-sm" v-model="filter.category_id"
                                        @change="loadDataContent">
                                    <option value="">Kategori</option>
                                    <option v-for="cat in data_raw.categories" :value="cat.id">{{ cat.name }}</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <select class="form-control form-control-sm" v-model="filter.type"
                                        @change="loadDataContent">
                                    <option value="">Tipe</option>
                                    <option value="public">Publik</option>
                                    <option value="member">Member</option>
                                    <option value="pro">Pro</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <VueCtkDateTimePicker v-bind="date_range_option"
                                                      v-model="filter.dates"></VueCtkDateTimePicker>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-5 mb-xl-8">
                    <div class="card-body py-8">
                        <page-loader v-model:active="loader" loader="dots" :is-full-page="false"/>
                        <div class="table-responsive">
                            <table class="table align-middle">
                                <thead>
                                <tr class="fw-bold text-muted bg-light">
                                    <th class="ps-4 text-center w-50px rounded-start">No</th>
                                    <th class="min-w-125px">Judul</th>
                                    <th>Kategori</th>
                                    <th>Komentar</th>
                                    <th>Date</th>
                                    <th>Konsultan</th>
                                    <th class="text-right">Aksi</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="data_content.total === 0">
                                    <td colspan="7" class="text-center">
                                        <i>tidak ditemukan</i>
                                    </td>
                                </tr>
                                <tr v-for="(data, i) in data_content.data" :key="i" v-if="data_content.total > 0">
                                    <td class="ps-4 text-center border-bottom-1" :title="data.id">
                                        <span class="text-dark fw-bold">
                                            {{ (data_content.current_page - 1) * data_content.per_page + i + 1 }}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="text-dark fw-bold">{{ data.title }}</span>
                                    </td>
                                    <td>
                                        {{ data.category_name }}
                                    </td>
                                    <td>
                                        <unicon name="comment" height="16" width="16" fill="orange"></unicon>
                                        {{ data.comments_count }}
                                    </td>
                                    <td>{{ $filters.formatDate(data.release_date) }}</td>
                                    <td>
                                        {{ data.author_name }}
                                    </td>
                                    <td class="text-right">
                                        <router-link :to="'/panel/posts-edit-add/' + data.id"
                                                     class="btn btn-sm btn-outline-theme m-1 mx-1">
                                            Edit
                                        </router-link>
                                        <router-link :to="'/panel/posts/'+data.id"
                                                     class="btn btn-sm m-1 btn-theme mx-1">
                                            Detail
                                        </router-link>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="border-top-1 p-4 d-flex justify-content-end align-items-center">
                        <div class="me-3">
                            Total {{ data_content.total }}
                        </div>
                        <div>
                            <select class="form-control form-control-sm" v-model="filter.per_page"
                                    @change="loadDataContent">
                                <option value="10">10/Page</option>
                                <option value="25">25/Page</option>
                                <option value="50">50/Page</option>
                                <option value="100">100/Page</option>
                            </select>
                        </div>
                        <div class="mx-2">
                            <laravel-pagination :data="data_content" :limit="2"
                                                @paginationChangePage="loadDataContent"></laravel-pagination>
                        </div>
                        <div class="me-3">
                            Go to
                        </div>
                        <div>
                            <select class="form-control form-control-sm select-center" style="min-width: 80px;"
                                    v-model="filter.page">
                                <option :value="i" v-for="i in data_content.last_page">{{ i }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loader: false,
            disabled: false,
            edit_mode: false,
            pswd_show: false,
            value: '',
            selected_radio: 1,
            date_range_option: {
                format: "YYYY-MM-DD",
                locale: "id",
                formatted: "ll",
                range: true,
                onlyDate: true,
                noShortcuts: true,
                noLabel: true,
                color: '#F78F2D',
                btnColor: '#F78F2D',
            },
            data_content: {},
            data_raw: {
                categories: [],
                roles: [],
            },
            filter: {
                page: 1,
                per_page: 10,
                name: '',
                status: 1,
                section: 'discussion',
                category_id: '',
                type: '',
            },
            title: 'Diskusi'
        }
    },
    methods: {
        loadDataContent(page = 1) {
            this.filter.page = page
            this.loader = true
            this.authGet('panel/posts', this.filter)
                .then((data) => {
                    this.data_content = data
                    this.loader = false
                }).catch(() => {
                this.loader = false
            })
        },
        loadCategories() {
            this.authGet('panel/categories-list', this.filter)
                .then((data) => {
                    this.data_raw.categories = data.result
                })
        },
        loadRoles() {
            this.authGet('roles-list')
                .then((data) => {
                    this.data_raw.roles = data.result
                })
        },
        addShow() {
            this.edit_mode = false;
            this.form.reset()
            $('#kt_modal_add_customer')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                }).modal('show')
            this.form.errors.clear()
        },
        addData() {
            this.disabled = true;
            this.form.post(this.base_api + 'users', this.setHeader())
                .then(({data}) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#kt_modal_add_customer').modal('hide')
                        this.loadDataContent()
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        editModal(data) {
            this.edit_mode = true;
            this.form.fill(data)
            $('#kt_modal_add_customer')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                }).modal('show')
            this.form.errors.clear()
        },
        updateData() {
            this.disabled = true;
            this.form.patch(this.base_api + 'users/' + this.form.id, this.setHeader())
                .then(({data}) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#kt_modal_add_customer').modal('hide')
                        this.loadDataContent()
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        deleteData() {
            if (confirm('Delete data?')) {
                this.disabled = true;
                this.form.delete(this.base_api + 'users/' + this.form.id, this.setHeader())
                    .then(({data}) => {
                        this.disabled = false;
                        if (data.status) {
                            $('#kt_modal_add_customer').modal('hide')
                            this.loadDataContent()
                        }
                    })
            }
        },
        resetFilter() {
            this.filter = {
                page: 1,
                per_page: 10,
                name: '',
                status: 1,
                section: 'discussion',
                category_id: '',
                type: '',
            }

            this.loadDataContent()
        },
    },
    created() {
        localStorage.setItem('post_section', 'discussion')

        this.loadDataContent()
        this.loadCategories()
    },
    watch: {
        'filter.dates.start': function () {
            this.loadDataContent(1)
        },
        'filter.dates.end': function () {
            this.loadDataContent(1)
        },
        'filter.status': function () {
            this.loadDataContent(1)
        },
    }
}
</script>