<template>
    <div class="d-flex">
        <div class="bg-white p-3"
             style="min-width: 250px; max-width: 400px; overflow-y: auto; height: calc(100vh - 70px);">
            <div data-kt-menu-trigger="click" class="menu-item menu-accordion" v-for="item in menu">
                <span class="menu-link">
                    <span class="menu-title">{{ item.title }}</span>
                </span>
                <div class="menu-sub menu-sub-accordion show">
                    <div class="menu-item">
                        <a href="#" class="menu-link" :class="active.slug === sub_item.slug ? 'active' : ''"
                           @click="loadData(sub_item)" v-if="item.children" v-for="sub_item in item.children">
                            <span class="menu-bullet">
                                <span class="bullet bullet-dot"></span>
                            </span>
                            <span class="menu-title">{{ sub_item.title }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-5" style="width: 100%; position: relative" v-if="active.title">
            <page-loader v-model:active="loader" loader="dots" :is-full-page="false"/>
            <div class="d-flex justify-content-between">
                <h5>Company Profile : {{ active.title }}</h5>
                <div class="text-right" v-if="edit_mode">
                    <button class="btn btn-sm btn-outline-theme mx-1" @click="edit_mode = false">
                        Batal
                    </button>
                    <button class="btn btn-sm btn-theme mx-1" @click="updateData" :disabled="disabled">
                        Simpan
                    </button>
                </div>
                <button class="btn btn-sm btn-outline-theme mx-1" v-if="!edit_mode" @click="edit_mode = true">edit
                </button>
            </div>
            <div class="row">
                <div class="col-md-12" v-if="active.slug !== 'privacy-policy'">
                    <div class="mb-3">
                        <label>Judul</label>
                        <input :disabled="!edit_mode" type="text" class="form-control" v-model="form.title">
                        <span class="error-text" v-if="parseErrors('title')">{{ parseErrors('title', 'm') }}</span>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="mb-3">
                        <label>Konten</label>
                        <VueEditor :disabled="!edit_mode" v-model="form.body" v-if="wyswyg"></VueEditor>
                        <textarea :disabled="!edit_mode" type="text" rows="8" v-if="!wyswyg" class="form-control"
                                  v-model="form.body"></textarea>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="mb-3">
                        <label>Status</label>
                        <select :disabled="!edit_mode" class="form-control" v-model="form.status">
                            <option value="0">Draft</option>
                            <option value="1">Publish</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {VueEditor} from "vue3-editor";

export default {
    components: {VueEditor},
    data() {
        return {
            loader: false,
            disabled: false,
            menu: [],
            wyswyg: false,
            active: {
                title: '',
                slug: '',
            },
            edit_mode: false,
            form: {
                id: '',
                title: '',
                body: '',
                slug: '',
                status: '',
            },
            form_errors:[]
        }
    },
    methods: {
        loadList() {
            this.authGet('panel/company-profile-list')
                .then((data) => {
                    this.menu = data.result

                    let first_menu = this.menu[0]
                    if (first_menu) {
                        console.log(first_menu)
                        let first_sub_menu = first_menu.children[0]
                        if (first_sub_menu) {
                            console.log(first_sub_menu)
                            this.loadData(first_sub_menu)
                        }
                    }
                })
        },
        loadData(data) {
            this.loader = true;
            this.active = data;
            this.form.slug = data.slug
            this.authGet('panel/company-profile', {
                slug: this.active.slug
            }).then((data) => {
                this.form.title = data.result.title;
                this.form.status = data.result.status;
                this.form.body = data.result.body;

                this.loader = false;
            })
        },
        updateData() {
            this.disabled = true;
            this.authPost('panel/company-profile', this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({title: data.message})
                        this.edit_mode = false;
                    } else {
                        this.form_errors = data.errors
                    }
                })
        },
        parseErrors(field, type = 'status') {
            let has = false;
            let message = '';
            if (this.form_errors[field]) {
                message = this.form_errors[field][0];
                has = true;
            }

            if (type === 'status') {
                return has;
            } else {
                return message;
            }
        }
    },
    created() {
        this.loadList()
    },
    watch: {
        'active.slug'(val) {
            let wyswyg_list = ['privacy-policy'];
            console.log(val)
            this.wyswyg = wyswyg_list.includes(val);
        }
    }
}
</script>
<style>
.menu-link {
    color: black;
}

.menu-link.active {
    color: orange;
    font-weight: bold;
}


</style>