<template>
    <div class="d-flex flex-column flex-column-fluid" data-select2-id="select2-data-131-6dxb">
        <div class="app-toolbar py-4 pt-lg-5 mb-6">
            <div class="app-container container-xxl">
                <div class="d-flex justify-content-between mx-3 align-items-center">
                    <div>Konten / Jurnal / Detail</div>
                    <div>
                        <button class="btn btn-theme btn-sm mx-1">Edit</button>
                        <button class="btn btn-outline-theme btn-sm mx-1">Hapus</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl">
                <div class="card mb-4 mb-xl-4">
                    <div class="d-flex p-6 align-items-center">
                        <div @click="$router.go(-1)" class="me-3">
                            <unicon name="arrow-circle-left" fill="orange" width="35px" height="35px"></unicon>
                        </div>
                        <div>
                            <h3>{{ data_detail.title }}</h3>
                            <div class="text-sm">
                                <span>Penulis :</span> {{ data_detail.author_name }}
                                <span>Kategori :</span> {{ data_detail.category_name }}
                                <span>Tipe Akses :</span> {{ data_detail.type }}
                                <span>Dibuat Pada :</span> {{ $filters.formatDateTime(data_detail.created_at) }}
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-9">
                                <div class="mb-4">
                                    <h3 class="text-theme">Abstract</h3>
                                    <div v-html="data_detail.body"></div>
                                </div>
                                <div>
                                    <h3 class="text-theme">Kata Kunci</h3>
                                    <div v-html="data_detail.subtitle"></div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <h3 class="text-theme">Jurnal Konstitusi</h3>
                                <div class="mb-2">
                                    <small>
                                        Issue No.2 Vol.19, Juni 2022 <br>
                                        p-ISSN 1829-7706 - e-ISSN 2548-1657
                                    </small>
                                </div>
                                <div class="mb-2">
                                    <small>
                                        Diterbitkan oleh The Registrar and Secretariat  General of The Constitutional Court of The Republic of Indonesia
                                    </small>
                                </div>
                                <div>
                                    <button class="btn btn-sm btn-theme">
                                        Lihat File
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data_detail: {},
        }
    },
    methods: {
        loadData() {
            this.authGet('panel/posts/' + this.$route.params.post_id)
                .then((data) => {
                    this.data_detail = data.result
                });
        }
    },
    created() {
        this.loadData();
    }
}
</script>
<style>
.text-sm {
    font-size: 12px;
    font-weight: 600;
}

.text-sm span {
    color: rgb(119, 119, 119);
}
</style>