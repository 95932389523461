<template>
    <div class="d-flex flex-column flex-column-fluid px-3">
        <div class="row">
            <div class="col-md-8 pt-12 px-6">
                <h3 v-if="edit_mode">Edit Konten</h3>
                <h3 v-if="!edit_mode">Tambah Konten</h3>
                <div class="w-100 mb-3">
                    <input type="text" v-model="form.title" class="form-control" placeholder="Masukkan Judul..">
                    <span class="error-text" v-if="parseErrors('title')">{{ parseErrors('title', 'm') }}</span>
                </div>
                <div class="mb-3">
                    <a :href="data_raw.link" target="_blank"
                       style="text-decoration: underline; font-style: italic; font-size: .9em ;">{{ data_raw.link }}</a>
                </div>
                <div>
                    <VueEditor v-model="form.body" useCustomImageHandler @image-added="handleImageAdded"></VueEditor>
                    <span class="error-text" v-if="parseErrors('body')">{{ parseErrors('body', 'm') }}</span>
                </div>
            </div>
            <div class="col-md-4 p-6 pb-20 border-secondary"
                 style="border-left: 1px solid grey; min-height: calc(100vh - 60px);">
                <div>
                    <b>Pengaturan</b>
                </div>
                <div class="mt-3 form-group" style="position: relative;">
                    <label>Cover</label> <br>
                    <page-loader v-model:active="upload_loader" loader="dots" :is-full-page="false"/>
                    <div class="d-flex justify-content-center">
                        <img :src="form.image" v-if="form.image" alt="" style="max-height: 100px;" class="mb-3">
                    </div>
                    <input type="file" class="form-control form-control-sm" accept="image/*" id="file-cover" @change="uploadCover">
                </div>
                <div class="form-group">
                    <label>Tipe</label>
                    <select class="form-control" v-model="form.section" disabled>
                        <option :value="section.slug" v-for="section in sections">{{section.name}}</option>
                    </select>
                    <span class="error-text" v-if="parseErrors('section')">{{ parseErrors('section', 'm') }}</span>
                </div>
                <div class="form-group" v-if="form.section !== 'discussion'">
                    <label>Penulis</label>
                    <input type="text" class="form-control" v-model="form.author_name">
                </div>
                <div class="form-group" v-if="form.section === 'discussion'">
                    <label>Mitra Konsultan</label>
                    <input type="text" class="form-control" v-model="form.author_name">
                </div>
<!--                <div class="form-group" v-if="form.section === 'discussion'">-->
<!--                    <label>Konsultan(Member)</label>-->
<!--                    <select class="form-control" v-model="form.author_id">-->
<!--                        <option value="">== pilih ==</option>-->
<!--                        <option :value="partner.id" v-for="partner in data_raw.partners">{{partner.name}}</option>-->
<!--                    </select>-->
<!--                </div>-->
                <div class="form-group">
                    <label>Tanggal Publish</label>
                    <input type="datetime-local" v-model="form.release_date" class="form-control">
                    <span class="error-text" v-if="parseErrors('release_date')">{{ parseErrors('release_date', 'm') }}</span>
                </div>
                <div class="form-group">
                    <label>Tipe Akses</label>
                    <br>
                    <input type="radio" id="html" name="fav_language" value="public" v-model="data_raw.type_access">
                    <label for="html" class="ms-2">Publik</label>
                    <br>
                    <input type="radio" id="css" name="fav_language" value="member" v-model="data_raw.type_access">
                    <label for="css" class="ms-2">Member</label>
                    <br>
                    <input type="radio" id="javascript" name="fav_language" value="pro" v-model="data_raw.type_access">
                    <label for="javascript" class="ms-2">Pro</label>
                </div>
                <div class="form-group" v-if="data_raw.categories.length > 0">
                    <label>Kategori</label>
                    <select v-model="form.category_id" class="form-control">
                        <option value="">-- pilih kategori --</option>
                        <option :value="cat.id" v-for="cat in data_raw.categories">{{ cat.name }}</option>
                    </select>
                    <span class="error-text" v-if="parseErrors('category_id')">{{ parseErrors('category_id', 'm') }}</span>
                </div>
                <div class="form-group">
                    <label>Status</label>
                    <select v-model="form.status" class="form-control">
                        <option value="1">Publish</option>
                        <option value="0">Draft</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="bg-white position-fixed text-right shadow py-3 pe-10" v-if="!detail_mode" style="left: 0; bottom: 0; width: 100%;">
            <button @click="$router.go(-1)" class="btn btn-sm btn-outline-theme mx-1">Kembali</button>
            <button class="btn btn-sm btn-theme mx-1" v-if="!edit_mode" :disabled="disabled"
                    @click="createData">
                <span v-if="!disabled">Tambah</span>
                <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
            </button>
            <button class="btn btn-sm btn-theme mx-1" v-if="edit_mode" :disabled="disabled" @click="updateData">
                <span v-if="!disabled">Perbarui</span>
                <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
            </button>
        </div>
    </div>
</template>

<script>
import {VueEditor} from "vue3-editor";

export default {
    data() {
        return {
            detail_mode: false,
            disabled: false,
            edit_mode: false,
            upload_loader: false,
            data_raw: {
                type_access: 'public',
                categories: [],
                partners: [],
                link: '',
            },
            sections:[
                {name: 'Artikel', slug: 'article'},
                {name: 'Info Grafik', slug: 'infographic'},
                {name: 'Analisis Hukum', slug: 'legal_analysis'},
                {name: 'Kelola Acara', slug: 'event_organizer'},
                {name: 'Testimoni', slug: 'testimony'},
                {name: 'Pertanyaan', slug: 'qna'},
                {name: 'Klien Kami', slug: 'client'},
                {name: 'Diskusi', slug: 'discussion'},
            ],
            form: {
                id: '',
                title: '',
                body: '',
                section: 'article',
                release_date: '',
                is_pro: '',
                is_login: '',
                image: '',
                author_id: '',
                author_name: '',
                status: 1,
                category_id: '',
            },
            errors: {},
        }
    },
    methods: {
        loadData(post_id = null) {
            this.authGet('panel/posts/' + (post_id != null  ? post_id : this.$route.params.post_id))
                .then((data) => {
                    this.form = data.result
                    this.parseData(data.result);
                });
        },
        loadCat(section) {
            this.authGet('panel/categories-list?section=' + section)
                .then((data) => {
                    this.data_raw.categories = data.result
                });
        },
        loadPartners(){
            this.authGet('panel/partners?per_page=1000')
                .then((data) => {
                    this.data_raw.partners = data.data
                });
        },
        handleImageAdded(file, Editor, cursorLocation, resetUploader) {
            var formData = new FormData();
            formData.append("file", file);
            formData.append("save_method", 'upload');

            this.authPost('panel/docs', formData)
                .then((data) => {
                    const url = data.result.link; // Get url from response
                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                }).catch(err => {

                });
        },
        updateData() {
            this.disabled = true;
            this.parseForm();
            this.authPatch('panel/posts/' + this.form.id, this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({title: data.message})
                        if(this.form.section === 'discussion' ){
                            this.$router.push('/panel/posts-discussion')
                        } else {
                            this.$router.push('/panel/posts?section=' + this.form.section)
                        }
                    } else {
                        this.errors = data.errors
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        createData() {
            this.disabled = true;
            this.parseForm();
            this.authPost('panel/posts', this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({title: data.message})
                        if(this.form.section === 'discussion' ){
                            this.$router.push('/panel/posts-discussion')
                        } else {
                            this.$router.push('/panel/posts?section=' + this.form.section)
                        }
                        this.errors = {};
                    } else {
                        this.errors = data.errors
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        parseData(data) {
            let is_pro = this.form.is_pro;
            let is_login = this.form.is_login;

            if (is_pro) {
                this.data_raw.type_access = 'pro';
            } else if (is_login) {
                this.data_raw.type_access = 'member';
            } else {
                this.data_raw.type_access = 'public';
            }

            if (this.form.slug) {
                this.data_raw.link = process.env.VUE_APP_WEB_URL + 'posts/' + this.form.slug
            }

            this.form.release_date = (data.release_date).substring(0,16)
        },
        parseForm() {
            switch (this.data_raw.type_access) {
                case 'pro':
                    this.form.is_pro = 1;
                    this.form.is_login = 1;
                    break;
                case 'member':
                    this.form.is_pro = 0;
                    this.form.is_login = 1;
                    break;
                case 'public':
                    this.form.is_pro = 0;
                    this.form.is_login = 0;
                    break;
            }
        },
        uploadCover() {
            this.upload_loader = true;
            let file = document.getElementById("file-cover").files[0];
            if(file.size > 3000000){
                alert('Ukuran file terlalu besar!')
                document.getElementById("file-cover").value = ''
                this.upload_loader = false;
                return;
            }
            if (file) {
                let form_data = new FormData();

                form_data.append('save_method', 'upload')
                form_data.append('file', file)

                this.authPost('panel/docs', form_data)
                    .then((data) => {
                        this.form.image = data.result.link;
                        this.upload_loader = false;
                    }).catch((e) => {
                    this.upload_loader = false;
                });
            } else {
                this.upload_loader = false;
            }
        },
        getUser() {
            let user = JSON.parse(localStorage.getItem('user'))
            this.form.author_id = user.id;
            this.form.author_name = user.name;
        },
        parseErrors(field, type = 'status') {
            let has = false;
            let message = '';
            if (this.errors[field]) {
                message = this.errors[field][0];
                has = true;
            }

            if (type === 'status') {
                return has;
            } else {
                return message;
            }
        }
    },
    created() {
        let param = this.$route.params.post_id
        if (param !== 'create') {
            this.edit_mode = true;
            this.loadData();
        } else {
            this.edit_mode = false;
            this.getUser();
            this.form.section = this.$route.query.section
        }

        this.form.section = localStorage.getItem('post_section')
        this.loadCat(this.form.section);
        this.loadPartners();
    },
    components: {VueEditor},
    watch: {
        'data_raw.type_access'(val) {
            this.parseForm();
        },
        'form.section'(val){
            this.loadCat(val);
        }
    }
}
</script>
<style>
.text-sm {
    font-size: 12px;
    font-weight: 600;
}

.text-sm span {
    color: rgb(119, 119, 119);
}
</style>