<template>
    <div class="px-3">
        <div class="row">
            <div class="col-md-8 pt-3  pb-8">
                <div class="d-flex justify-content-between align-items-center p-2">
                    <div>
                        <router-link to="/panel/partners">Mitra Konsultan</router-link> / Detail</div>
                    <div>
                        <button class="btn btn-sm btn-theme mx-1" v-if="edit_mode" :disabled="disabled"
                                @click="updateData">
                            <span v-if="!disabled">Simpan Perubahan</span>
                            <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
                        </button>
                        <button class="btn btn-sm btn-theme mx-1" v-if="!edit_mode" :disabled="disabled"
                                @click="createData">
                            <span v-if="!disabled">Tambah</span>
                            <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
                        </button>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="card-body">
                        <h4>Informasi Pribadi</h4>
                        <div class="row">
                            <div class="col-md-4 p-6 text-center" style="position: relative">
                                <page-loader v-model:active="upload_loader" loader="dots" :is-full-page="false"/>
                                <div class="img-container mb-3" v-if="form.user_profile && form.user_profile.image"
                                     :style="'background-image: url('+ form.user_profile.image +')'"></div>
                                <input hidden type="file" accept="image/*" @change="uploadCover" id="file-cover">
                                <label class="btn btn-outline-theme btn-sm" for="file-cover">
                                    <span v-if="edit_mode">Ganti Foto</span>
                                    <span v-if="!edit_mode">Tambah Foto</span>
                                </label>
                            </div>
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mb-3">
                                            <label>Nama</label>
                                            <input type="text" v-model="form.name" class="form-control ">
                                            <span class="error-text" v-if="parseErrors('name')">{{ parseErrors('name', 'm') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label>Lokasi</label>
                                            <input type="text" v-model="form.user_profile.address"
                                                   class="form-control  ">
                                            <span class="error-text" v-if="parseErrors('user_profile.address')">{{ parseErrors('user_profile.address', 'm') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label>Email</label>
                                            <input type="email" v-model="form.email"
                                                   class="form-control ">
                                            <span class="error-text" v-if="parseErrors('email')">{{ parseErrors('email', 'm') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label>No. Handphone</label>
                                            <input type="text" v-model="form.phone"
                                                   class="form-control  ">
                                            <span class="error-text" v-if="parseErrors('phone')">{{ parseErrors('phone', 'm') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label>Organisasi</label>
                                            <input type="text" v-model="form.user_profile.organization"
                                                   class="form-control ">
                                            <span class="error-text" v-if="parseErrors('user_profile.organization')">{{ parseErrors('user_profile.organization', 'm') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label>Tahun Mulai Bekerja</label>
                                            <input type="date" v-model="form.user_profile.career_start"
                                                   class="form-control ">
                                            <span class="error-text" v-if="parseErrors('user_profile.career_start')">{{ parseErrors('user_profile.career_start', 'm') }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label>Status</label>
                                            <select v-model="form.status" class="form-control ">
                                                <option value="1">Publish</option>
                                                <option value="0">Archive</option>
                                            </select>
                                            <span class="error-text" v-if="parseErrors('status')">{{ parseErrors('status', 'm') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h4>Pendidikan Terakhir</h4>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label>Jurusan</label>
                                    <input type="text" v-model="form.user_profile.major"
                                           class="form-control ">
                                    <span class="error-text" v-if="parseErrors('user_profile.major')">{{ parseErrors('user_profile.major', 'm') }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label>Universitas</label>
                                    <input type="text" v-model="form.user_profile.graduated_from"
                                           class="form-control ">
                                    <span class="error-text" v-if="parseErrors('user_profile.graduated_from')">{{ parseErrors('user_profile.graduated_from', 'm') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h4>Tentang Konsultan</h4>
                        <div class="mb-3">
                            <textarea type="text" rows="5" v-model="form.user_profile.desc"
                                      class="form-control"></textarea>
                            <span class="error-text" v-if="parseErrors('user_profile.desc')">{{ parseErrors('user_profile.desc', 'm') }}</span>
                        </div>
                    </div>
                </div>
                <div class="card mb-6">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <h4>Keahlian & Spesialisasi</h4>
                        </div>
                        <div>
                            <textarea class="form-control" v-model="form.user_profile.tags"></textarea>
                            <div class="error-text" v-if="parseErrors('user_profile.tags')">{{ parseErrors('user_profile.tags', 'm') }}</div>
                            <small>pisahkan dengan tanda koma</small>
                        </div>
                    </div>
                </div>
                <div class="text-right">
                    <button class="btn btn-sm btn-theme mx-1" v-if="edit_mode" :disabled="disabled"
                            @click="updateData">
                        <span v-if="!disabled">Simpan Perubahan</span>
                        <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
                    </button>
                </div>
            </div>
            <div class="col-md-4 bg-white" v-if="edit_mode" style="min-height: calc(100vh - 70px)">
                <Schedule :user_id="$route.params.user_id"></Schedule>
            </div>
        </div>
    </div>
</template>

<script>

import Schedule from "@/pages/partners/Schedule";

export default {
    components: {Schedule},
    data() {
        return {
            upload_loader: false,
            disabled: false,
            edit_mode: false,
            form_errors: [],
            form: {
                id: '',
                name: '',
                status: 1,
                user_profile: {
                    address: '',
                }
            }
        }
    },
    methods: {
        loadData(user_id = null) {
            this.authGet('panel/partners/' + (user_id != null ? user_id : this.$route.params.user_id))
                .then((data) => {
                    this.form = data.result
                });
        },
        uploadCover() {
            this.upload_loader = true;
            let file = document.getElementById("file-cover").files[0];
            if (file) {
                let form_data = new FormData();

                form_data.append('save_method', 'upload')
                form_data.append('file', file)

                this.authPost('panel/docs', form_data)
                    .then((data) => {
                        if(data.status) {
                            this.form.user_profile.image = data.result.link;
                        } else {
                            alert(data.message)
                        }
                        this.upload_loader = false;
                    }).catch((e) => {
                        alert(e.message)
                    this.upload_loader = false;
                });
            } else {
                this.upload_loader = false;
            }
        },
        updateData() {
            this.disabled = true;
            this.authPatch('panel/partners/' + this.form.id, this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({title: data.message})
                        this.$router.push('/panel/partners');
                    } else {
                        this.form_errors = data.errors
                        console.log(this.form_errors)
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        createData() {
            this.disabled = true;
            this.authPost('panel/partners', this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({title: data.message})
                        this.$router.push('/panel/partners')
                    } else if(data.errors){
                        this.form_errors = data.errors
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        parseErrors(field, type = 'status') {
            let has = false;
            let message = '';

            if (this.form_errors[field]) {
                message = this.form_errors[field][0];
                has = true;
            }

            if (type === 'status') {
                return has;
            } else {
                return message;
            }
        }
    },
    created() {
        let param = this.$route.params.user_id
        if (param !== 'create') {
            this.loadData();
            this.edit_mode = true;
        } else {
            this.edit_mode = false
        }
    },
    computed: {
        user_tags() {
            let tags = this.form.user_profile.tags;
            if (tags) {
                return tags.split(',');
            }
        }
    }
}
</script>
<style>
.img-container {
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    background-color: grey;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>