<template>
    <div class="d-flex flex-column flex-column-fluid" data-select2-id="select2-data-131-6dxb">
        <div class="app-toolbar pt-4 pt-lg-5 mb-6">
            <div class="app-container container-xxl">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 class="page-heading d-flex text-dark fw-bold flex-column justify-content-center my-0">
                        {{ title }}
                    </h1>
                </div>
                <div class="d-flex flex-stack my-3">
                    <div>
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control form-control-sm" placeholder="Cari.."
                                   @keyup.enter="loadDataContent"
                                   v-model="filter.s">
                            <button class="btn btn-theme" @click="loadDataContent">
                                Cari
                            </button>
                        </div>
                    </div>
                    <div class="text-right">
                        <router-link class="btn btn-sm mx-1 btn-outline-theme"
                                     :to="'/panel/categories?section=' + this.$route.query.section">Kelola Kategori
                        </router-link>
                        <router-link class="btn btn-sm mx-1 btn-outline-theme" v-if="filter.section === 'training'"
                                     :to="'/panel/categories?section=training-type'">Kelola Tipe
                        </router-link>
                        <router-link :to="'/panel/products-edit-add/create?section=' + filter.section"
                                     class="btn btn-sm mx-1 btn-theme">Tambah
                        </router-link>
                    </div>
                </div>
                <div>
                    <div class="btn-group">
                        <button class="btn btn-sm" :class="filter.status === 0 ? 'btn-tab' : ''"
                                @click="filter.status = 0">Draft
                        </button>
                        <button class="btn btn-sm" :class="filter.status === 1 ? 'btn-tab' : ''"
                                @click="filter.status = 1">Publish
                        </button>
                        <button class="btn btn-sm" :class="filter.status === 2 ? 'btn-tab' : ''"
                                @click="filter.status = 2"
                                style="border-radius: 0;">Archive
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl">
                <div class="card mb-4 mb-xl-4">
                    <div class="card-body py-5">
                        <div class="row">
                            <div class="col-md-3">
                                <select class="form-control form-control-sm" v-model="filter.category_id"
                                        @change="loadDataContent">
                                    <option value="">Semua Kategori</option>
                                    <option v-for="cat in data_raw.categories" :value="cat.id">{{ cat.name }}</option>
                                </select>
                            </div>
                            <div class="col-md-3" v-if="filter.section === 'training'">
                                <select class="form-control form-control-sm" v-model="filter.category_type_id"
                                        @change="loadDataContent">
                                    <option value="">Semua Tipe</option>
                                    <option v-for="type in data_raw.types" :value="type.id">{{ type.name }}</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <VueCtkDateTimePicker v-bind="date_range_option_global"
                                                      v-model="filter.dates"></VueCtkDateTimePicker>
                            </div>
                            <div class="col-md-3">
                                <button class="btn btn-sm mx-1 btn-outline-theme" @click="resetFilter">
                                    Reset Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-5 mb-xl-8">
                    <div class="card-body py-8">
                        <page-loader v-model:active="loader" loader="dots" :is-full-page="false"/>
                        <div class="table-responsive">
                            <table class="table align-middle">
                                <thead>
                                <tr class="fw-bold text-muted bg-light">
                                    <th class="ps-4 text-center w-50px rounded-start">No</th>
                                    <th></th>
                                    <th style="width: 30%">Nama</th>
                                    <th>Kategori</th>
                                    <th>Harga</th>
                                    <th v-if="filter.section === 'class_room'">Video</th>
                                    <th v-if="filter.section === 'class_room'">Ebook</th>
                                    <th v-if="filter.section === 'training'">Tipe</th>
                                    <th v-if="filter.section === 'training'">Lokasi</th>
                                    <th class="text-right">Aksi</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="data_content.total === 0">
                                    <td colspan="9" class="text-center">
                                        <i>tidak ada data</i>
                                    </td>
                                </tr>
                                <tr v-for="(data, i) in data_content.data" :key="i">
                                    <td class="ps-4 text-center border-bottom-1">
                                            <span class="text-dark fw-bold">
                                            {{ (data_content.current_page - 1) * data_content.per_page + i + 1 }}
                                        </span>
                                    </td>
                                    <td>
                                        <a :href="data.image" target="_blank">
                                            <div class="w-65px h-65px bgi-position-center bgi-size-cover bgi-no-repeat"
                                                 :style="'background-image: url('+data.image+');'">
                                            </div>
                                        </a>
                                    </td>
                                    <td>
                                        <span class="text-dark fw-bold">{{ data.name }}</span>
                                        <br>
                                        <span v-if="data.product_detail">Pemateri: {{
                                                data.product_detail.speaker
                                            }}</span>
                                    </td>
                                    <td>
                                        <span v-if="data.category">{{ data.category.name }}</span>
                                    </td>
                                    <td class="text-right">
                                            <span v-if="data.product_detail">{{
                                                    $filters.currency(data.product_detail.price)
                                                }}</span>
                                    </td>
                                    <td v-if="filter.section === 'class_room'">
                                        {{ data.video_count }}
                                    </td>
                                    <td v-if="filter.section === 'class_room'">
                                        {{ data.ebook_count }}
                                    </td>
                                    <td v-if="filter.section === 'training'">
                                        <span v-if="data.category_type">{{ data.category_type.name }}</span>
                                    </td>
                                    <td v-if="filter.section === 'training'">
                                        <span v-if="data.product_detail">{{ data.product_detail.place }}</span>
                                        <br>
                                        <span
                                            v-if="data.product_detail">{{ $filters.formatDateTime(data.product_detail.date_start) }}</span>
                                    </td>
                                    <td class="text-right">
                                        <router-link
                                            :to="'/panel/products-edit-add/' + data.id + '?section=' + data.section "
                                            class="m-1 btn btn-sm btn-theme">
                                            Detail
                                        </router-link>
                                        <button class="m-1 btn btn-xs btn-outline-theme" @click="deleteData(data)">
                                            Hapus
                                        </button>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="border-top-1 p-4 d-flex justify-content-end align-items-center">
                        <div class="me-3">
                            Total {{ data_content.total }}
                        </div>
                        <div>
                            <select class="form-control form-control-sm" v-model="filter.per_page"
                                    @change="loadDataContent">
                                <option value="10">10/Page</option>
                                <option value="25">25/Page</option>
                                <option value="50">50/Page</option>
                                <option value="100">100/Page</option>
                            </select>
                        </div>
                        <div class="mx-2">
                            <laravel-pagination :data="data_content" :limit="2"
                                                @paginationChangePage="loadDataContent"></laravel-pagination>
                        </div>
                        <div class="me-3">
                            Go to
                        </div>
                        <div>
                            <select class="form-control form-control-sm select-center" style="min-width: 80px;"
                                    v-model="filter.page">
                                <option :value="i" v-for="i in data_content.last_page">{{ i }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loader: false,
            disabled: false,
            edit_mode: false,
            pswd_show: false,
            value: '',
            selected_radio: 1,
            form: new form({
                id: '',
                name: '',
                email: '',
                role_id: '',
                institution_id: '',
                password: '',
                password_confirmation: '',
            }),
            data_content: {},
            data_raw: {
                categories: [],
                types: [],
            },
            filter: {
                page: 1,
                per_page: 25,
                name: '',
                status: 1,
                category_id: '',
                category_type_id: '',
                type: '',
                s: '',
                section: '',
                dates: '',
            },
            title: ''
        }
    },
    methods: {
        loadDataContent(page = 1) {
            this.filter.page = page
            this.loader = true
            this.authGet('panel/products', this.filter)
                .then((data) => {
                    this.data_content = data
                    this.loader = false
                })
        },
        deleteData(data) {
            if (confirm('Delete data?')) {
                this.disabled = true;
                this.form.delete(this.base_api + 'panel/products/' + data.id, this.setHeader())
                    .then(({data}) => {
                        this.disabled = false;
                        if (data.status) {
                            this.toaster({title: data.message})
                            this.loadDataContent()
                        }
                    })
            }
        },
        resetFilter(){
            this.filter.name = '';
            this.filter.category_id = '';
            this.filter.category_type_id = '';
            this.filter.type = '';
            this.filter.s = '';
            this.filter.dates = '';

            this.loadDataContent()
        },
        updateTitle() {
            let name = this.$route.query.section
            if (name)
                this.title = name.replace('_', ' ')
            this.title = this.title.replace('_', ' ')

            switch (name) {
                case 'class_room':
                    this.filter.section = 'class_room'
                    this.title = 'Ruang Kelas'
                    break;
                case 'training':
                    this.filter.section = 'training'
                    this.title = 'Pelatihan'
                    break;
                case 'package':
                    this.filter.section = 'package'
                    this.filter.type = 'package'
                    this.title = 'Relasi Produk Legal 2 Pro'
                    break;
                default:
                    // this.filter.section = 'class_room'
                    this.title = 'Legal 2 Us'
                    break;
            }
            this.loadCategories()
        },
        loadCategories() {
            this.authGet('panel/categories-list', this.filter)
                .then((data) => {
                    this.data_raw.categories = data.result
                })
        },
        loadType() {
            this.authGet('panel/categories-list', {
                section: 'training-type',
            })
                .then((data) => {
                    this.data_raw.types = data.result
                })
        },
    },
    created() {
        this.updateTitle()
        this.loadDataContent()
        this.loadType()
    },
    watch: {
        $route(to) {
            this.updateTitle()
            this.loadDataContent()
        },
        'filter.status'(val) {
            this.loadDataContent()
        },
        'filter.dates.start': function () {
            this.loadDataContent(1)
        },
        'filter.dates.end': function () {
            this.loadDataContent(1)
        },
    }
}
</script>

<style scoped>

</style>