<template>
    <div class="d-flex flex-column flex-column-fluid" data-select2-id="select2-data-131-6dxb">
        <div class="app-toolbar pt-4 pt-lg-5 mb-6">
            <div class="app-container container-xxl">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">

                </div>
                <div class="d-flex flex-stack my-3">
                    <h1 class="page-heading d-flex text-dark fw-bold flex-column justify-content-center my-0">
                        Transaksi {{ title }}
                    </h1>
                    <div>
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control form-control-sm" placeholder="Cari.."
                                   aria-label="Cari Disini" aria-describedby="button-addon2"
                                   @keyup.enter="loadDataContent()"
                                   v-model="filter.s">
                            <button class="btn btn-theme" type="button" id="button-addon2" @click="loadDataContent">
                                Cari
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="btn-group">
                        <button class="btn btn-sm" :class="filter.status === 100 ? 'btn-tab' : ''"
                                @click="filter.status = 100">Dalam Proses
                        </button>
                        <button class="btn btn-sm" :class="filter.status === 200 ? 'btn-tab' : ''"
                                @click="filter.status = 200">Berhasil
                        </button>
                        <button class="btn btn-sm" :class="filter.status === 300 ? 'btn-tab' : ''"
                                @click="filter.status = 300">Batal
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl">
                <div class="card mb-5 mb-xl-8">
                    <div class="card-body py-8">
                        <page-loader v-model:active="loader" loader="dots" :is-full-page="false"/>
                        <div class="table-responsive">
                            <table class="table align-middle">
                                <thead>
                                <tr class="fw-bold text-muted bg-light">
                                    <th class="ps-4 text-center w-50px rounded-start">No</th>
                                    <td></td>
                                    <th class="min-w-125px">Nama User</th>
                                    <th>Produk</th>
                                    <th>Harga</th>
                                    <th>Waktu Trs</th>
                                    <th>Status</th>
                                    <th v-if="filter.type !== 'package'">Penanganan</th>
                                    <th class="action">Aksi</th>
                                </tr>
                                </thead>
                                <tbody v-if="data_content.data.length > 0">
                                <tr v-for="(data, i) in data_content.data" :key="i">
                                    <td class="ps-4 text-center border-bottom-1">
                                        <span class="text-dark fw-bold">{{
                                                (data_content.current_page - 1) * data_content.per_page + i + 1
                                            }}</span>
                                    </td>
                                    <td class="d-flex justify-content-center">
                                        <div class="position-relative" v-if="data.user_profile"
                                             style="height: 35px; width: 35px;">
                                            <div :style="'background-image: url(' + data.user_profile.image + ')'"
                                                 class="profile-img">
                                            </div>
                                            <div class="position-absolute bg-theme dot-online" v-if="data.online"></div>
                                        </div>
                                    </td>
                                    <td>
                                        {{ data.user_name }}
                                    </td>
                                    <td>
                                        <b :title="data.relation_name">
                                            {{ $filters.truncate(data.relation_name) }}
                                        </b>
                                    </td>
                                    <td>
                                        <div>{{ $filters.currency(data.price) }}</div>
                                        <div>
                                            <span class="badge badge-primary" v-if="data.payment_method === 'midtrans'">{{
                                                    data.payment_method
                                                }}</span>
                                            <span class="badge badge-secondary" v-else>{{ data.payment_method }}</span>
                                        </div>
                                    </td>
                                    <td>
                                        {{ $filters.formatDateTime(data.created_at) }}
                                    </td>
                                    <td>
                                            <span class="badge badge-theme" v-if="data.status < 200">
                                                {{ data.status_label }}
                                            </span>
                                        <span class="badge badge-success" v-if="data.status > 200 && data.status < 300">
                                                {{ data.status_label }}
                                            </span>
                                        <span class="badge badge-danger" v-if="data.status > 300 && data.status < 400">
                                                {{ data.status_label }}
                                            </span>
                                    </td>
                                    <td v-if="filter.type !== 'package'">
                                        <select class="form-select" v-if="data.status > 200"
                                                @change="changeActionStatus($event, data)" v-model="data.action_status">
                                            <option :value="action.status"
                                                    v-for="action in data_raw.action_status_list_active">
                                                {{ action.label }}
                                            </option>
                                        </select>
                                    </td>
                                    <td class="action">
                                        <router-link :to="'/panel/transactions/' + data.id"
                                                     class="btn btn-xs btn-theme">
                                            Detail
                                        </router-link>
                                    </td>
                                </tr>
                                </tbody>
                                <tbody v-if="data_content.data.length === 0">
                                <tr>
                                    <td class="text-center" colspan="9">
                                        <i>tidak ada data</i>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="border-top-1 p-4 d-flex justify-content-end align-items-center">
                        <div class="me-3">
                            Total {{ data_content.total }}
                        </div>
                        <div>
                            <select class="form-control form-control-sm" v-model="filter.per_page"
                                    @change="loadDataContent()">
                                <option value="10">10/Page</option>
                                <option value="25">25/Page</option>
                                <option value="50">50/Page</option>
                                <option value="100">100/Page</option>
                            </select>
                        </div>
                        <div class="mx-2">
                            <laravel-pagination :data="data_content" :limit="2"
                                                @paginationChangePage="loadDataContent"></laravel-pagination>
                        </div>
                        <div class="me-3">
                            Go to
                        </div>
                        <div>
                            <select class="form-control form-control-sm select-center" style="min-width: 80px;"
                                    @change="loadDataContent(filter.page)"
                                    v-model="filter.page">
                                <option :value="page" v-for="page in data_content.last_page">{{ page }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loader: false,
            disabled: false,
            edit_mode: false,
            pswd_show: false,
            value: '',
            selected_radio: 1,
            form: new form({
                id: '',
                name: '',
                email: '',
                role_id: '',
                institution_id: '',
                password: '',
                password_confirmation: '',
            }),
            data_content: {
                data: [],
            },
            data_raw: {
                action_status_list: [],
                action_status_list_active: [],
            },
            filter: {
                page: 1,
                per_page: '10',
                type: '',
                order: 'DESC',
                order_field: 'id',
                s: '',
                status: 100,
            },
            title: ''
        }
    },
    methods: {
        loadDataContent(page = 1) {
            this.filter.page = page
            this.loader = true
            this.authGet('panel/transactions', this.filter)
                .then((data) => {
                    this.data_content = data
                    this.loader = false
                })
        },
        packageView(){
            this.authPost('panel/transaction-package-view')
                .then(()=>{
                    this.emitter.emit("reload-menu")
                })
        },
        addShow() {
            this.edit_mode = false;
            this.form.reset()
            $('#kt_modal_add_customer')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                }).modal('show')
            this.form.errors.clear()
        },
        addData() {
            this.disabled = true;
            this.form.post(this.base_api + 'users', this.setHeader())
                .then(({data}) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#kt_modal_add_customer').modal('hide')
                        this.loadDataContent()
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        editModal(data) {
            this.edit_mode = true;
            this.form.fill(data)
            $('#kt_modal_add_customer')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                }).modal('show')
            this.form.errors.clear()
        },
        updateData() {
            this.disabled = true;
            this.form.patch(this.base_api + 'users/' + this.form.id, this.setHeader())
                .then(({data}) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#kt_modal_add_customer').modal('hide')
                        this.loadDataContent()
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        deleteData() {
            if (confirm('Delete data?')) {
                this.disabled = true;
                this.form.delete(this.base_api + 'users/' + this.form.id, this.setHeader())
                    .then(({data}) => {
                        this.disabled = false;
                        if (data.status) {
                            $('#kt_modal_add_customer').modal('hide')
                            this.loadDataContent()
                        }
                    })
            }
        },
        pswdTogle() {
            this.pswd_show = !this.pswd_show
        },
        updateTitle() {
            let type = this.$route.query.type

            this.filter.type = type
            switch (type) {
                case 'package':
                    this.title = 'Paket Legal2Pro'
                    break;
                case 'class_room':
                    this.title = 'Kelas'
                    break;
                case 'training':
                    this.title = 'Pelatihan'
                    break;
                case 'chat':
                    this.title = 'Konsultasi Chat'
                    break;
                case 'video-call':
                    this.title = 'Konsultasi Video'
                    break;
            }
        },
        changeActionStatus(event, data) {
            let value = event.target.value
            this.authPost('panel/transaction-action', {
                transaction_id: data.id,
                action_status: value
            }).then((data) => {
                if (data.status) {
                    this.loadDataContent()
                    this.emitter.emit("reload-menu")
                }
            })
        },
        loadActionStatusList() {
            this.authGet('panel/transaction-status-list')
                .then((data) => {
                    this.data_raw.action_status_list = data.result
                    this.updateActionStatus()
                })
        },
        updateActionStatus() {
            this.data_raw.action_status_list_active =
                this.data_raw.action_status_list[this.filter.type]
        }
    },
    created() {
        this.updateTitle()
        this.loadDataContent()
        this.loadActionStatusList();
    },
    watch: {
        $route(to) {
            this.updateTitle()
            this.loadDataContent()
            this.updateActionStatus()
        },
        'filter.status'(val) {
            this.loadDataContent()

            if(this.filter.type === 'package' && val === 200){
                this.packageView()
            }
        }
    }
}
</script>

<style scoped>
.form-select {

}

.profile-img {
    width: 35px;
    height: 35px;
    background: #111;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.dot-online {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    top: 3px;
    right: 2px
}
</style>