<template>
    <div class="d-flex flex-column flex-column-fluid" data-select2-id="select2-data-131-6dxb">
        <div class="app-toolbar py-3 py-lg-6" data-select2-id="select2-data-kt_app_toolbar">
            <div class="app-container container-xxl d-flex flex-stack"
                 data-select2-id="select2-data-kt_app_toolbar_container">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                        Kategori</h1>
                </div>
                <div class="d-flex align-items-center gap-2 gap-lg-3">
                    <a href="#" class="btn btn-sm fw-bold btn-theme" @click="addShow">Tambah Baru</a>
                </div>
            </div>
        </div>

        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl pt-6">
                <div class="card">
                    <div class="card-body pt-6">
                        <div class="row ">
                            <div class="col-md-4">
                                <label for="section">Section</label>
                                <select class="form-control" v-model="filter.section" @change="loadDataContent">
                                    <option :value="section.name" v-for="section in data_raw.sections">
                                        {{ section.desc }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label for="section">Cari</label>
                                <input type="text" class="form-control" v-model="filter.name" @keyup.enter="loadDataContent">
                            </div>
                        </div>
                        <page-loader v-model:active="loader" :is-full-page="false" loader="dots"/>
                        <!--begin::Table-->
                        <div id="kt_customers_table_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div class="table-responsive">
                                <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                                       id="kt_customers_table">
                                    <!--begin::Table head-->
                                    <thead>
                                    <!--begin::Table row-->
                                    <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                        <th style="width: 20px">No</th>
                                        <th style="width: 80px">Ilustrasi</th>
                                        <th>Nama</th>
                                        <th>Section</th>
                                        <th style="text-align: right !important;">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody class="fw-semibold text-gray-600">
                                    <tr class="odd" v-for="(data,i) in data_content.data" :key="data.id">
                                        <td>{{ i + 1 }}</td>
                                        <td>
                                            <a :href="data.image" target="_blank">
                                                <div class="w-65px h-65px bgi-position-center bgi-size-cover bgi-no-repeat"
                                                     :style="'background-image: url('+data.image+');'">
                                                </div>
                                            </a>
                                        </td>
                                        <td>
                                            <b>{{ data.name }}</b>
                                        </td>
                                        <td>
                                            <span v-if="data.section_ref">{{ data.section_ref.desc }}</span>
                                        </td>
                                        <td class="text-right">
                                            <button class="btn btn-sm btn-theme" @click="editModal(data)">
                                                Edit
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row">
                                <div
                                    class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                                    <div class="dataTables_length">
                                        <select class="form-select form-select-sm" v-model="filter.per_page"
                                                @change="loadDataContent">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                                <div
                                    class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                                    <laravel-pagination :limit="2" size="small"
                                                        :data="data_content" @pagination-change-page="loadDataContent"
                                    ></laravel-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="kt_modal_add_customer" data-backdrop="static" tabindex="-1"
                     aria-hidden="true">
                    <div class="modal-dialog modal-lg mw-650px">
                        <div class="modal-content">
                            <div class="modal-header py-3 px-5" id="kt_modal_add_customer_header">
                                <h3 class="fw-bold" v-if="!edit_mode">Tambah Data</h3>
                                <h3 class="fw-bold" v-if="edit_mode">Perbarui Data</h3>
                                <button data-bs-dismiss="modal"
                                        class="btn close btn-icon btn-sm btn-active-icon-primary">
                                    <span class="svg-icon svg-icon-1">
                                        <unicon name="times"></unicon>
                                    </span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="four-three"
                                             :style="'background-image: url('+ form.image +')'"></div>
                                        <div class="text-center mt-3">
                                            <input type="file" id="image" accept="image/*" hidden @change="uploadFile"/>
                                            <label for="image" class="btn btn-sm btn-theme">
                                                Ilustrasi
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-label">Nama</label>
                                            <input type="text" class="form-control form-control-solid"
                                                   v-model="form.name">
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Bagian</label>
                                            <select class="form-control form-control-solid"
                                                   v-model="form.section">
                                                <option :value="section.name" v-for="section in data_raw.sections">{{section.desc}}</option>
                                            </select>
                                            <div v-if="form.errors.has('name')" v-html="form.errors.get('name')"/>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-center mt-3">
                                    <button @click="deleteData" v-if="edit_mode" :disabled="disabled"
                                            class="btn btn-danger btn-sm mx-1">
                                        <span class="indicator-label">Hapus</span>
                                    </button>
                                    <button @click="addData" v-if="!edit_mode" :disabled="disabled"
                                            class="btn btn-theme btn-sm mx-1">
                                        <BounceLoader :loading="disabled" color="#fff" size="22px"></BounceLoader>
                                        <span v-if="!disabled" class="indicator-label">Tambah</span>
                                    </button>
                                    <button @click="updateData" v-if="edit_mode" :disabled="disabled"
                                            class="btn btn-primary btn-sm mx-1">
                                        <BounceLoader :loading="disabled" color="#fff" size="22px"></BounceLoader>
                                        <span v-if="!disabled" class="indicator-label">Perbarui</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loader: false,
            upload_loader: false,
            disabled: false,
            edit_mode: false,
            pswd_show: false,
            value: '',
            selected_radio: 1,
            form: new form({
                id: '',
                name: '',
                section: '',
                image: '',
            }),
            data_content: {},
            data_raw: {
                sections: [],
            },
            filter: {
                page: 1,
                per_page: 10,
                name: '',
                section: '',
            },
        }
    },
    methods: {
        loadDataContent(page = 1) {
            this.filter.page = page
            this.loader = true
            this.authGet('panel/categories', this.filter)
                .then((data) => {
                    this.data_content = data
                    this.loader = false
                })
        },
        loadSections() {
            this.authGet('panel/sections-list')
                .then((data) => {
                    this.data_raw.sections = data.result
                })
        },
        addShow() {
            this.edit_mode = false;
            this.form.reset()
            $('#kt_modal_add_customer')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                }).modal('show')
            this.form.errors.clear()
            this.form.section = this.filter.section
        },
        addData() {
            this.disabled = true;
            this.form.post(this.base_api + 'panel/categories', this.setHeader())
                .then(({data}) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#kt_modal_add_customer').modal('hide')
                        this.loadDataContent()
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        editModal(data) {
            this.edit_mode = true;
            this.form.fill(data)
            $('#kt_modal_add_customer')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                }).modal('show')
            this.form.errors.clear()
        },
        updateData() {
            this.disabled = true;
            this.form.patch(this.base_api + 'panel/categories/' + this.form.id, this.setHeader())
                .then(({data}) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#kt_modal_add_customer').modal('hide')
                        this.loadDataContent()
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        deleteData() {
            if (confirm('Delete data?')) {
                this.disabled = true;
                this.form.delete(this.base_api + 'panel/categories/' + this.form.id, this.setHeader())
                    .then(({data}) => {
                        this.disabled = false;
                        if (data.status) {
                            $('#kt_modal_add_customer').modal('hide')
                            this.loadDataContent()
                        }
                    })
            }
        },
        parseSection() {
            let section = this.$route.query.section;
            if (section) {
                this.filter.section = section;
            } else {
                this.filter.section = 'article'
            }
        },
        uploadFile() {
            this.upload_loader = true;
            let file = document.getElementById("image" ).files[0];
            if (file) {
                let form_data = new FormData();

                form_data.append('save_method', 'upload')
                form_data.append('file', file)

                this.authPost('panel/docs', form_data)
                    .then((data) => {
                        this.form.image = data.result.link;
                        this.upload_loader = false;
                    }).catch((e) => {
                    this.upload_loader = false;
                });
            } else {
                this.upload_loader = false;
            }
        },
    },
    created() {
        this.parseSection();
        this.loadSections();
        this.loadDataContent();
    }
}
</script>