<template>
    <div class="card p-5">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h3 class="mb-0">{{ $filters.capitalize(data_type) }}</h3>
            <div>
                <button class="btn btn-sm btn-theme" @click="createModal">
                    Tambah
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-for="data in data_content">
                <div class="d-flex flex-nowrap justify-content-between mb-3 p-1 border-bottom">
                    <div class="d-flex">
                        <div class="min-w-80px">
                            <div class="square rounded bg-secondary"
                                 :style="'background-image: url('+ data.cover +')'">
                            </div>
                        </div>
                        <div class="ms-3">
                            <b class="mb-0">{{ data.desc }}</b> <br>
                            <a :href="data.link" target="_blank" class="text-theme">{{ $filters.truncate(data.link) }}</a>
                            <br>
                            <small>{{ $filters.formatDateTime(data.created_at) }}</small>
                        </div>
                    </div>
                    <div>
                        <div class="dropdown">
                            <button class="btn btn-secondary btn-sm dropdown-toggle"
                                    type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            </button>
                            <ul class="dropdown-menu">
                                <li>
                                    <button class="dropdown-item" @click="editModal(data)">Edit</button>
                                </li>
                                <li>
                                    <button class="dropdown-item text-danger" @click="deleteData(data)">Delete</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" :id="'modal_edit_add_' + data_type" style="display: none" data-backdrop="static" tabindex="-1"
             aria-hidden="false">
            <div class="modal-dialog modal-lg mw-650px">
                <div class="modal-content">
                    <div class="modal-header py-3 px-5 bg-theme ">
                        <h3 class="fw-bold text-white" v-if="!edit_mode">Tambah Data {{ $filters.capitalize(data_type) }}</h3>
                        <h3 class="fw-bold text-white" v-if="edit_mode">Edit Data {{ $filters.capitalize(data_type) }}</h3>
                        <button data-bs-dismiss="modal"
                                class="btn close btn-icon btn-sm btn-active-icon-primary">
                                <span class="svg-icon svg-icon-1">
                                    <unicon name="times" fill="white"></unicon>
                                </span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="four-three"
                                     :style="'background-image: url('+ form.cover +')'"></div>
                                <div class="text-center mt-3">
                                    <input type="file" :id="'upload_' + data_type" accept="image/*" hidden @change="uploadFile"/>
                                    <label :for="'upload_' + data_type" class="btn btn-sm btn-theme">
                                        Header Image
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="form-group">
                                    <label>Judul</label>
                                    <textarea class="form-control" v-model="form.desc"></textarea>
                                    <span class="error-text" v-if="parseErrors('desc')">{{
                                            parseErrors('desc', 'm')
                                        }}</span>
                                </div>
                                <div class="form-group">
                                    <label>Link</label>
                                    <textarea class="form-control" v-model="form.link"></textarea>
                                    <span class="error-text" v-if="parseErrors('link')">{{
                                            parseErrors('link', 'm')
                                        }}</span>
                                </div>
                            </div>
                            <div class="col-12 text-right">
                                <button class="btn btn-sm btn-theme mx-1" v-if="!edit_mode" :disabled="disabled"
                                        @click="createData">
                                    <span v-if="!disabled">Tambah</span>
                                    <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
                                </button>
                                <button class="btn btn-sm btn-theme mx-1" v-if="edit_mode" :disabled="disabled"
                                        @click="updateData">
                                    <span v-if="!disabled">Perbarui</span>
                                    <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: ['product_id', 'data_type'],
    data() {
        return {
            edit_mode: false,
            disabled: false,
            data_content: [],
            form: {
                save_method: 'link',
                type: '',
                desc: '',
                link: '',
                model: 'product',
                model_id: this.$route.params.product_id,
                cover: '',
            },
            errors: {}
        }
    },
    methods: {
        loadData() {
            this.authGet('panel/docs', {
                model: 'product',
                model_id: this.product_id,
                type: this.data_type
            }).then((data) => {
                this.data_content = data.result
            })
        },
        createModal() {
            $('#modal_edit_add_' + this.data_type).modal({
                backdrop: 'static',
                keyboard: false,
            }).modal('show')
            this.edit_mode = false;
            this.form = {
                save_method: 'link',
                type: this.data_type,
                desc: '',
                link: '',
                model: 'product',
                model_id: this.$route.params.product_id,
                cover: '',
            }
        },
        editModal(data) {
            $('#modal_edit_add_' + this.data_type).modal({
                backdrop: 'static',
                keyboard: false,
            }).modal('show')
            this.edit_mode = true;
            this.form = data
        },
        createData() {
            this.disabled = true;
            this.form.type = this.data_type;

            this.authPost('panel/docs', this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({title: data.message})
                        this.errors = {};
                        this.loadData()
                        $('#modal_edit_add_' + this.data_type).modal('hide')
                    } else {
                        this.errors = data.errors
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        updateData() {
            this.disabled = true;
            this.authPatch('panel/docs/' + this.form.id, this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({title: data.message})
                        this.loadData()
                        $('#modal_edit_add_' + this.data_type).modal('hide')
                    } else {
                        this.errors = data.errors
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        uploadFile() {
            this.upload_loader = true;
            let file = document.getElementById("upload_" + this.data_type ).files[0];
            if (file) {
                let form_data = new FormData();

                form_data.append('save_method', 'upload')
                form_data.append('file', file)

                this.authPost('panel/docs', form_data)
                    .then((data) => {
                        this.form.cover = data.result.link;
                        this.upload_loader = false;
                    }).catch((e) => {
                    this.upload_loader = false;
                });
            } else {
                this.upload_loader = false;
            }
        },
        parseErrors(field, type = 'status') {
            let has = false;
            let message = '';
            if (this.errors[field]) {
                message = this.errors[field][0];
                has = true;
            }

            if (type === 'status') {
                return has;
            } else {
                return message;
            }
        },
        deleteData(data) {
            if (confirm('Delete data?')) {
                this.disabled = true;
                this.authDelete( 'panel/docs/' + data.id)
                    .then((data) => {
                        this.disabled = false;
                        if (data.status) {
                            $('#modal_edit_add_' + this.data_type).modal('hide')
                            this.loadData()
                        }
                    })
            }
        },
    },
    created() {
        this.loadData();
    }
}
</script>
<style>
.square {
    position: relative;
    width: 90%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.square:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.four-three {
    position: relative;
    width: 90%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.four-three:after {
    content: "";
    display: block;
    padding-bottom: 75%;
}
</style>