// Vuex
import { createStore } from 'vuex'

const store = createStore({
    state () {
        return {
            section_text: '',
            post_current_section:'',
        }
    },
    mutations: {
        set_section(state, section) {
            state.section_text = section
        }
    }
})

export default store;