<template>
    <div>
        <div class="my-4 mx-2 d-flex justify-content-between align-items-center">
            <h4>Jadwal</h4>
            <button class="btn btn-sm btn-theme" @click="createModal">
                Tambah Jadwal
            </button>
        </div>
        <div>
            <div v-if="data_content.length === 0" class="text-secondary text-center mt-5">
                <i>belum ada jadwal</i>
            </div>
            <div class="border rounded p-4 mb-2" v-for="sch in data_content">
                <div class="d-flex justify-content-between">
                    <b>{{ sch.day_str }}</b>
                    <span @click="updateModal(sch)" class="cursor-pointer color-theme">edit</span>
                </div>
                <div>
                    {{ sch.time_start }} WIB
                </div>
                <div>{{ sch.label }}</div>
            </div>
        </div>
        <div class="modal fade" style="display: none" id="modal_edit_add" data-backdrop="static" tabindex="-1"
             aria-hidden="true">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header py-3 px-5" id="kt_modal_add_customer_header">
                        <h3 class="fw-bold" v-if="!edit_mode">Tambah Jadwal</h3>
                        <h3 class="fw-bold" v-if="edit_mode">Edit Jadwal</h3>
                        <button data-bs-dismiss="modal"
                                class="btn close btn-icon btn-sm btn-active-icon-primary">
                                    <span class="svg-icon svg-icon-1">
                                        <unicon name="times"></unicon>
                                    </span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Tanggal</label>
                                    <input type="date" class="form-control" v-model="form.date">
                                    <span class="error-text" v-if="parseErrors('date')">{{ parseErrors('date', 'm') }}</span>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Jam</label>
                                    <input type="time" class="form-control" v-model="form.time_start">
                                    <span class="error-text" v-if="parseErrors('time_start')">{{ parseErrors('time_start', 'm') }}</span>
                                </div>
                            </div>
<!--                            <div class="col-12">-->
<!--                                <div class="form-group">-->
<!--                                    <label>Jam Selesai</label>-->
<!--                                    <input type="time" class="form-control" v-model="form.time_end">-->
<!--                                    <span class="error-text" v-if="parseErrors('time_end')">{{ parseErrors('time_end', 'm') }}</span>-->
<!--                                </div>-->
<!--                            </div>-->
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Pengulangan</label>
                                    <select class="form-control" v-model="form.type">
                                        <option value="none">Tidak ada pengulangan</option>
                                        <option value="daily">Setiap hari</option>
                                        <option value="weekday">Hari Kerja (Senin-Jumat)</option>
                                        <option value="weekly">Mingguan di hari yang sama</option>
                                        <option value="monthly_date">Bulanan di tanggal yang sama</option>
                                        <option value="monthly_day">Bulanan di hari & minggu yang sama</option>
                                    </select>
                                    <span class="error-text" v-if="parseErrors('type')">{{ parseErrors('type', 'm') }}</span>
                                </div>
                            </div>
                            <div class="col-12">
                                <button @click="createData" style="width: 100%" v-if="!edit_mode" :disabled="disabled"
                                        class="btn btn-sm btn-theme my-1">
                                    <BounceLoader :loading="disabled" color="#fff" size="22px"></BounceLoader>
                                    <span v-if="!disabled" class="indicator-label">Tambah</span>
                                </button>
                                <button @click="updateData" style="width: 100%" v-if="edit_mode" :disabled="disabled"
                                        class="btn btn-sm btn-theme my-1">
                                    <BounceLoader :loading="disabled" color="#fff" size="22px"></BounceLoader>
                                    <span v-if="!disabled" class="indicator-label">Perbarui</span>
                                </button>
                                <button @click="deleteData" v-if="edit_mode" :disabled="disabled"
                                        class="btn btn-sm btn-outline-theme my-1" style="width: 100%">
                                    <span class="indicator-label">Delete</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['user_id'],
    data() {
        return {
            disabled: false,
            edit_mode: false,
            form: {
                id: '',
                user_id: '',
                type: '',
                date: '',
                time_start: '',
                time_end: '',
            },
            data_content: [],
            errors: {},
        }
    },
    methods: {
        loadData() {
            this.authGet('panel/user-schedules?user_id=' + this.user_id).then((data) => {
                this.data_content = data.result
            })
        },
        createModal() {
            $('#modal_edit_add').modal('show');
            this.edit_mode = false;
        },
        updateModal(data) {
            $('#modal_edit_add').modal('show');
            this.edit_mode = true;
            this.form = data
        },
        createData() {
            this.disabled = true;
            this.form.user_id = this.$route.params.user_id
            this.authPost('panel/user-schedules', this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({title: data.message})
                        this.loadData()
                        this.errors = {};
                        $('#modal_edit_add').modal('hide');
                    } else {
                        this.errors = data.errors
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        updateData(){
            this.disabled = true;
            this.authPatch('panel/user-schedules/' + this.form.id, this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({title: data.message})
                        this.loadData()
                        $('#modal_edit_add').modal('hide');
                        this.errors = {}
                    } else {
                        this.errors = data.errors
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        deleteData() {
            if (confirm('Delete data?')) {
                this.disabled = true;
                this.authDelete('panel/user-schedules/' + this.form.id)
                    .then((data) => {
                        this.disabled = false;
                        if (data.status) {
                            $('#modal_edit_add').modal('hide');
                            this.loadData()
                        }
                    })
            }
        },
        parseErrors(field, type = 'status') {
            let has = false;
            let message = '';
            if (this.errors[field]) {
                message = this.errors[field][0];
                has = true;
            }

            if (type == 'status') {
                return has;
            } else {
                return message;
            }
        }
    },
    created() {
        let param = this.$route.params.user_id
        if (param !== 'create') {
            this.loadData();
            this.edit_mode = true;
        } else {
            this.edit_mode = false
        }
    }
}
</script>