<template>
    <div class="d-flex flex-column flex-column-fluid" data-select2-id="select2-data-131-6dxb">
        <div class="app-toolbar pt-4 pt-lg-5 mb-6">
            <div class="app-container container-xxl">
                <div class="page-title d-flex justify-content-between me-3 align-items-center">
                    <h1 class="page-heading text-dark fw-bold my-0">
                        {{ title }}
                    </h1>
                    <div class="text-right">
                        <button @click="addModal" class="btn btn-sm mx-1 btn-theme">Tambah</button>
                    </div>
                </div>
                <div class="d-flex flex-stack my-3">
                    <div>

                    </div>

                </div>
            </div>
        </div>
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl">
                <div class="card mb-5 mb-xl-8">
                    <div class="card-body py-8">
                        <page-loader v-model:active="loader" loader="dots" :is-full-page="false"/>
                        <div class="table-responsive">
                            <table class="table align-middle">
                                <thead>
                                <tr class="fw-bold text-muted bg-light">
                                    <th class="ps-4 text-center w-50px rounded-start">No</th>
                                    <th class="min-w-125px">Nama</th>
                                    <th>Slug</th>
                                    <th class="action">Aksi</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(data, i) in data_content.data" :key="i">
                                    <td class="ps-4 text-center border-bottom-1">
                                        <span class="text-dark fw-bold">{{ i + 1 }}</span>
                                    </td>
                                    <td>
                                        <span class="text-dark fw-bold">{{ data.name }}</span>
                                    </td>
                                    <td>
                                        {{ data.slug }}
                                    </td>
                                    <td class="action">
                                        <button class="btn btn-sm btn-outline-theme" @click="editModal(data)">Edit</button>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="border-top-1 p-4 d-flex justify-content-end align-items-center">
                        <div class="me-3">
                            Total {{ data_content.total }}
                        </div>
                        <div>
                            <select class="form-control form-control-sm" v-model="filter.per_page"
                                    @change="loadDataContent">
                                <option value="10">10/Page</option>
                                <option value="25">25/Page</option>
                                <option value="50">50/Page</option>
                                <option value="100">100/Page</option>
                            </select>
                        </div>
                        <div class="mx-2">
                            <laravel-pagination :data="data_content" :limit="2"
                                                @paginationChangePage="loadDataContent"></laravel-pagination>
                        </div>
                        <div class="me-3">
                            Go to
                        </div>
                        <div>
                            <select class="form-control form-control-sm select-center" style="min-width: 80px;"
                                    v-model="filter.page">
                                <option :value="i" v-for="i in data_content.last_page">{{ i }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="editAddModal" data-backdrop="static" tabindex="-1"
             aria-hidden="true">
            <div class="modal-dialog modal-lg mw-650px">
                <div class="modal-content">
                    <div class="modal-header py-3 px-5" id="kt_modal_add_customer_header">
                        <h3 class="fw-bold" v-if="!edit_mode">Add Data</h3>
                        <h3 class="fw-bold" v-if="edit_mode">Update Data</h3>
                        <button data-bs-dismiss="modal"
                                class="btn close btn-icon btn-sm btn-active-icon-primary">
                                    <span class="svg-icon svg-icon-1">
                                        <unicon name="times"></unicon>
                                    </span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-6">
                                <label for="name" >Nama</label>
                                <input type="text" v-model="form.name" class="form-control">
                            </div>
                            <div class="col-md-6">
                                <label for="name" >Slug</label>
                                <input type="text" v-model="form.slug" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            loader: false,
            disabled: false,
            edit_mode: false,
            pswd_show: false,
            value: '',
            selected_radio: 1,
            form: new form({
                id: '',
                name: '',
                slug: '',
            }),
            data_content: {},
            data_raw: {
                categories: [],
            },
            filter: {
                page: 1,
                per_page: 10,
                name: '',
                status: '',
                category_id: '',
                type: '',
                section: 'package',
            },
            title: 'Paket Legal 2 Us'
        }
    },
    methods: {
        loadDataContent(page = 1) {
            this.filter.page = page
            this.loader = true
            this.authGet('panel/products', this.filter)
                .then((data) => {
                    this.data_content = data
                    this.loader = false
                })
        },
        loadCategories() {
            this.authGet('panel/categories-list', this.filter)
                .then((data) => {
                    this.data_raw.categories = data.result
                })
        },
        addModal() {
            this.edit_mode = false;
            this.form.reset()
            $('#editAddModal')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                }).modal('show')
            this.form.errors.clear()
        },
        addData() {
            this.disabled = true;
            this.form.post(this.base_api + 'users', this.setHeader())
                .then(({data}) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#kt_modal_add_customer').modal('hide')
                        this.loadDataContent()
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        editModal(data) {
            this.edit_mode = true;
            this.form.fill(data)
            $('#editAddModal')
                .modal({
                    backdrop: 'static',
                    keyboard: false,
                }).modal('show')
            this.form.errors.clear()
        },
        updateData() {
            this.disabled = true;
            this.form.patch(this.base_api + 'users/' + this.form.id, this.setHeader())
                .then(({data}) => {
                    this.disabled = false;
                    if (data.status) {
                        $('#kt_modal_add_customer').modal('hide')
                        this.loadDataContent()
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        deleteData() {
            if (confirm('Delete data?')) {
                this.disabled = true;
                this.form.delete(this.base_api + 'users/' + this.form.id, this.setHeader())
                    .then(({data}) => {
                        this.disabled = false;
                        if (data.status) {
                            $('#kt_modal_add_customer').modal('hide')
                            this.loadDataContent()
                        }
                    })
            }
        },
    },
    created() {
        this.loadDataContent()
    },
}
</script>

<style scoped>

</style>