<template>
    <div class="d-flex flex-column flex-column-fluid" data-select2-id="select2-data-131-6dxb">
        <div class="app-toolbar pt-4 pt-lg-5 mb-6">
            <div class="app-container container-xxl">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">

                </div>
                <div class="d-flex flex-stack my-3">
                    <h1 class="page-heading d-flex text-dark fw-bold flex-column justify-content-center my-0">
                        {{ title }}
                    </h1>
                </div>
                <div class="d-flex flex-stack my-3">
                    <div>
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control form-control-sm" v-model="filter.name"
                                   placeholder="Kata kunci.." @keyup.enter="loadDataContent" aria-label="Cari Disini"
                                   aria-describedby="button-addon2">
                            <button class="btn btn-theme" type="button" id="button-addon2" @click="loadDataContent">
                                Cari
                            </button>
                        </div>
                    </div>
                    <div class="text-right">
                        <router-link to="/panel/partners/create" class="btn btn-sm mx-1 btn-theme">Tambah</router-link>
                    </div>
                </div>
                <div>
                    <div class="btn-group">
                        <button class="btn btn-sm rounded-0" :class="filter.status === 1 ? 'btn-tab' : ''"
                                @click="filter.status = 1">Publish
                        </button>
                        <button class="btn btn-sm rounded-0" :class="filter.status === 0 ? 'btn-tab' : ''"
                                @click="filter.status = 0">Archive
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-content flex-column-fluid">
            <div class="app-container container-xxl">
                <div class="card mb-5 mb-xl-8">
                    <div class="card-body py-8">
                        <page-loader v-model:active="loader" loader="dots" :is-full-page="false"/>
                        <div class="table-responsive">
                            <table class="table align-middle">
                                <thead>
                                <tr class="fw-bold text-muted bg-light">
                                    <th class="ps-4 text-center w-50px rounded-start">No</th>
                                    <td></td>
                                    <th class="min-w-125px">Nama</th>
                                    <th>Organisasi</th>
                                    <th>Lokasi</th>
                                    <th>Pendidikan</th>
                                    <th>Keahlian</th>
                                    <th class="text-right" style="min-width: 150px">Aksi</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="data_content.total === 0">
                                    <td colspan="8" class="text-center">
                                        <i>tidak ada data</i>
                                    </td>
                                </tr>
                                <tr v-for="(data, i) in data_content.data" :key="i">
                                    <td class="ps-4 text-center border-bottom-1">
                                        <span class="text-dark fw-bold">
                                            {{ (data_content.current_page - 1) * data_content.per_page + i + 1 }}
                                        </span>
                                    </td>
                                    <td>
                                        <div class="position-relative" style="height: 35px; width: 35px;">
                                            <div :style="'background-image: url(' + data.user_profile.image + ')'"
                                                 class="profile-img" v-if="data.user_profile">
                                            </div>
                                            <div class="profile-img" v-if="!data.user_profile">
                                            </div>
                                            <div class="position-absolute bg-theme dot-online">
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {{ data.name }}
                                    </td>
                                    <td>
                                        <span v-if="data.user_profile">{{ data.user_profile.organization }}</span>
                                    </td>
                                    <td>
                                        <span v-if="data.user_profile">{{ data.user_profile.address }}</span>
                                    </td>
                                    <td>
                                        <span v-if="data.user_profile">
                                            {{ data.user_profile.major }} <br>
                                            <small>{{ data.user_profile.graduated_from }}</small>
                                        </span>
                                    </td>
                                    <td>
                                        <span v-if="data.user_profile">
                                            {{ data.user_profile.tags }}
                                        </span>
                                    </td>
                                    <td class="text-right" >
                                        <router-link :to="'/panel/partners/' + data.id"
                                                     class="m-1 btn btn-xs btn-outline-theme">
                                            Edit
                                        </router-link>
                                        <button class="m-1 btn btn-theme btn-xs" @click="deleteData(data)">
                                            Hapus
                                        </button>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="border-top-1 p-4 d-flex justify-content-end align-items-center">
                        <div class="me-3">
                            Total {{ data_content.total }}
                        </div>
                        <div>
                            <select class="form-control form-control-sm" v-model="filter.per_page"
                                    @change="loadDataContent">
                                <option value="10">10/Page</option>
                                <option value="25">25/Page</option>
                                <option value="50">50/Page</option>
                                <option value="100">100/Page</option>
                            </select>
                        </div>
                        <div class="mx-2">
                            <laravel-pagination :data="data_content" :limit="2"
                                                @paginationChangePage="loadDataContent"></laravel-pagination>
                        </div>
                        <div class="me-3">
                            Go to
                        </div>
                        <div>
                            <select class="form-control form-control-sm select-center" style="min-width: 80px;"
                                    v-model="filter.page">
                                <option :value="i" v-for="i in data_content.last_page">{{ i }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loader: false,
            disabled: false,
            edit_mode: false,
            pswd_show: false,
            value: '',
            selected_radio: 1,
            form: new form({
                id: '',
                name: '',
                email: '',
                role_id: '',
                institution_id: '',
                password: '',
                password_confirmation: '',
            }),
            data_content: {},
            data_raw: {
                categories: [],
            },
            filter: {
                page: 1,
                per_page: 10,
                type: 'member',
                order: 'DESC',
                order_field: 'id',
                name: '',
                status: 1,
                subscribed: 'all',
            },
            title: 'Mitra Konsultan'
        }
    },
    methods: {
        loadDataContent(page = 1) {
            this.filter.page = page
            this.loader = true
            this.authGet('panel/partners', this.filter)
                .then((data) => {
                    this.data_content = data
                    this.loader = false
                })
        },
        deleteData(data) {
            if (confirm('Delete data?')) {
                this.disabled = true;
                this.form.delete(this.base_api + 'panel/partners/' + data.id, this.setHeader())
                    .then(({data}) => {
                        this.disabled = false;
                        if (data.status) {
                            this.loadDataContent()
                        }
                    })
            }
        },
        pswdTogle() {
            this.pswd_show = !this.pswd_show
        },
    },
    created() {
        this.loadDataContent()
    },
    watch: {
        'filter.status'(val) {
            this.loadDataContent()
        }
    }
}
</script>

<style scoped>


.profile-img {
    width: 35px;
    height: 35px;
    background: #111;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.dot-online {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    top: 3px;
    right: 2px
}
</style>