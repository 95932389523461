import {
    uniAngleRight,
    uniArrowRight,
    uniArrowCircleLeft,
    uniArrowCircleLeftThinline,
    uniApps,
    uniBars,
    uniBalanceScale,
    uniBuilding,
    uniCarWash,
    uniClipboardNotes,
    uniCircle,
    uniCheckCircle,
    uniComment,
    uniDashboard,
    uniEye,
    uniEyeSlash,
    uniEdit,
    uniExchange,
    uniFileContract,
    uniInfoCircle,
    uniLayerGroupMonochrome,
    uniLockOpenAlt,
    uniLockAccess,
    uniMoneyWithdraw,
    uniPlusSquare,
    uniPlus,
    uniPadlock,
    uniPhone,
    uniRssInterface,
    uniServerAlt,
    uniSearch,
    uniShoppingCart,
    uniSetting,
    uniTimes,
    uniTrash,
    uniUsersAlt,
    uniUser,
    uniUserPlus,
    uniUserExclamation,
    uniVideo,
    uniWallet,
} from 'vue-unicons/dist/icons'

export default [
    uniAngleRight,
    uniArrowRight,
    uniArrowCircleLeft,
    uniArrowCircleLeftThinline,
    uniApps,
    uniBars,
    uniBalanceScale,
    uniBuilding,
    uniCarWash,
    uniClipboardNotes,
    uniCircle,
    uniCheckCircle,
    uniComment,
    uniDashboard,
    uniEye,
    uniEyeSlash,
    uniEdit,
    uniExchange,
    uniFileContract,
    uniInfoCircle,
    uniLayerGroupMonochrome,
    uniLockOpenAlt,
    uniLockAccess,
    uniMoneyWithdraw,
    uniPlusSquare,
    uniPlus,
    uniPadlock,
    uniPhone,
    uniRssInterface,
    uniServerAlt,
    uniSearch,
    uniShoppingCart,
    uniSetting,
    uniTimes,
    uniTrash,
    uniUsersAlt,
    uniUser,
    uniUserPlus,
    uniUserExclamation,
    uniVideo,
    uniWallet,
]