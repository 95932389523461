<template>
    <div class="card mb-5 mb-xl-8">
        <div class="card-body py-8">
            <page-loader v-model:active="loader" loader="dots" :is-full-page="false"/>
            <div class="d-flex justify-content-between">
                <div>
                    <h5>Daftar Produk dan Layanan</h5>
                    <small>Total: {{ package_details.length }} item</small>
                </div>
                <div>
                    <button class="btn btn-sm btn-ouline-theme" @click="createModal">
                        Tambah Item
                    </button>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-4 my-1" v-for="detail in package_details">
                    <div style="border-radius: 10px; border: 1px solid orange"
                         class="p-5 d-flex justify-content-between">
                        <div>
                            <div class="fw-bold fs-6">{{ detail.name }}</div>
                            <div class="mt-2 fs-2 fw-bold text-theme"  v-if="detail.quota < 0">
                                Unlimited
                            </div>
                            <div class="mt-2 fs-2 fw-bold"  v-if="detail.quota > 0">
                                <span class="text-theme">{{detail.quota}}</span>
                            </div>
                        </div>
                        <div>
                            <div class="dropdown">
                                <button class="btn btn-sm btn-secondary dropdown-toggle" type="button"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                </button>
                                <ul class="dropdown-menu">
                                    <li>
                                        <button class="dropdown-item" @click="editModal(detail)">Edit</button>
                                    </li>
                                    <li>
                                        <button class="dropdown-item text-danger" @click="deleteData(detail.id)">Hapus
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
<!--            <div class="mt-5">-->
<!--                <div class="form-check form-switch">-->
<!--                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"-->
<!--                           checked>-->
<!--                    <label class="form-check-label" for="flexSwitchCheckChecked">-->
<!--                        Tampilkan tag "termasuk paket sebelumnya."-->
<!--                    </label>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div class="modal fade" style="display: none" id="modal_edit_add" data-backdrop="static" tabindex="-1"
             aria-hidden="true">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header py-3 px-5" id="kt_modal_add_customer_header">
                        <h3 class="fw-bold" v-if="!edit_mode">Tambah Item</h3>
                        <h3 class="fw-bold" v-if="edit_mode">Edit Item</h3>
                        <button data-bs-dismiss="modal"
                                class="btn close btn-icon btn-sm btn-active-icon-primary">
                                    <span class="svg-icon svg-icon-1">
                                        <unicon name="times"></unicon>
                                    </span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="">Nama</label>
                            <input type="text" class="form-control" v-model="form.name">
                            <span class="error-text" v-if="parseErrors('name')">{{ parseErrors('name', 'm') }}</span>
                        </div>
                        <div class="form-group">
                            <label for="">Kuota</label>
                            <input type="number" class="form-control" v-model="form.quota" v-show="!data_raw.unlimited">
                            <span class="error-text" v-if="parseErrors('quota')">{{ parseErrors('quota', 'm') }}</span>
                        </div>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" v-model="data_raw.unlimited" role="switch" id="unlimited">
                            <label class="form-check-label" for="unlimited"> Unlimited </label>
                        </div>
                        <div class="form-group">
                            <label for="">Nama Alt</label>
                            <input type="text" class="form-control" v-model="form.product_name">
                            <span class="error-text" v-if="parseErrors('product_name')">{{ parseErrors('product_name', 'm') }}</span>
                        </div>
                        <div class="form-group">
                            <label for="">Deskripsi</label>
                            <textarea class="form-control" v-model="form.desc"></textarea>
                            <span class="error-text" v-if="parseErrors('desc')">{{ parseErrors('desc', 'm') }}</span>
                        </div>
                        <div class="form-group">
                            <label for="">Relasi Produk</label>
                            <select class="form-control" v-model="form.product_id">
                                <option value="">Tidak Berelasi</option>
                                <option :value="prod.id" v-for="prod in data_raw.products">{{ prod.name }}</option>
                            </select>
                            <span class="error-text" v-if="parseErrors('product_id')">{{
                                    parseErrors('product_id', 'm')
                                }}</span>
                        </div>
                        <button class="btn btn-sm btn-theme btn-block" v-if="!edit_mode" :disabled="disabled"
                                @click="createData">
                            <span v-if="!disabled">Tambah</span>
                            <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
                        </button>
                        <button class="btn btn-sm btn-theme btn-block" v-if="edit_mode" :disabled="disabled"
                                @click="updateData">
                            <span v-if="!disabled">Perbarui</span>
                            <PulseLoader :loading="disabled" color="#fff" size="10px"></PulseLoader>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['package_details', 'package_id'],
    data() {
        return {
            disabled: false,
            edit_mode: false,
            loader: false,
            data_raw: {
                products: [],
                unlimited: false
            },
            form: {
                id: '',
                package_id: '',
                name: '',
                desc: '',
                product_id: '',
                product_name: '',
                quota: '',
            },
            errors: []
        }
    },
    methods: {
        createModal() {
            $('#modal_edit_add').modal('show')
            this.edit_mode = false
            this.form = {
                id: '',
                package_id: '',
                name: '',
                desc: '',
                product_id: '',
                product_name: '',
                quota: '',
            }
            this.data_raw.unlimited = false
        },
        loadProducts() {
            this.authGet('panel/products?section=package')
                .then((data) => {
                    this.data_raw.products = data.data
                })
        },
        editModal(data) {
            this.edit_mode = true;
            $('#modal_edit_add').modal('show')
            this.form = data
            this.data_raw.unlimited = (data.quota < 0)
        },
        updateData() {
            this.disabled = true;
            this.form.package_id = this.package_id;

            if(this.data_raw.unlimited) this.form.quota = -1

            this.authPatch('panel/package-details/' + this.form.id, this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({title: data.message})
                        this.errors = [];
                        this.$emit('reload-package', this.package_id)
                        $('#modal_edit_add').modal('hide')
                        this.$parent.loadDataContent()
                    } else {
                        this.errors = data.errors
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        createData() {
            this.disabled = true;
            this.form.package_id = this.package_id

            if(this.data_raw.unlimited) this.form.quota = -1

            this.authPost('panel/package-details', this.form)
                .then((data) => {
                    this.disabled = false;
                    if (data.status) {
                        this.toaster({title: data.message})
                        this.errors = {};
                        this.$emit('reload-package', this.package_id)
                        $('#modal_edit_add').modal('hide')
                        this.$parent.loadDataContent()
                    } else {
                        this.errors = data.errors
                    }
                }).catch(() => {
                this.disabled = false;
            })
        },
        parseErrors(field, type = 'status') {
            let has = false;
            let message = '';
            if (this.errors[field]) {
                message = this.errors[field][0];
                has = true;
            }

            if (type === 'status') {
                return has;
            } else {
                return message;
            }
        },
        deleteData(id) {
            if (confirm('Delete data?')) {
                this.disabled = true;
                this.authDelete('panel/package-details/' + id)
                    .then((data) => {
                        this.disabled = false;
                        if (data.status) {
                            this.toaster({title: data.message})
                            this.$emit('reload-package', this.package_id)
                            this.$parent.loadDataContent()
                        } else {
                            this.errors = data.errors
                        }
                    })
            }
        },
    },
    emits: ['reload-package'],
    created() {
        this.loadProducts()
    }
}
</script>