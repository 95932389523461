<template>
    <div>
        <h5>Legal 2 Pro</h5>
        <div v-for="item in package_group" class="mb-2">
            <div class="mb-1 text-bold">{{ item.relation_name }}</div>
            <div class="progress">
                <div class="progress-bar bg-theme text-white" :style="'width: '+ item.percent +'%'"
                     role="progressbar" aria-valuenow="50"
                     aria-valuemin="50" aria-valuemax="100">
                    {{ item.total }}
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            package_group: [],
            total: 0,
        }
    },
    methods: {
        loadPackageGroup() {
            this.authGet('panel/dashboard-package-group')
                .then((data) => {
                    this.package_group = data.result

                    this.package_group.forEach(item => {
                        this.total += parseInt(item.total)
                    })

                    this.package_group.forEach(item => {
                        item.percent = item.total / this.total * 100
                    })
                })
        },
    },
    created() {
        this.loadPackageGroup()
    }
}
</script>