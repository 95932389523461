<template>
    <div class="d-flex flex-column flex-column-fluid">
        <div class="row">
            <div class="col-md-8">
                <div class="p-6">
                    <div class="form-group">
                        <label>Kategori</label>
                        <br>
                        <input type="radio" id="html" name="fav_language" value="HTML">
                        <label for="html" class="ms-2">Publik</label>
                        <br>
                        <input type="radio" id="css" name="fav_language" value="CSS">
                        <label for="css" class="ms-2">Member</label>
                        <br>
                        <input type="radio" id="javascript" name="fav_language" value="JavaScript">
                        <label for="javascript" class="ms-2">Pro</label>
                    </div>
                    <div class="form-group">
                        <label>Tipe Akses</label>
                        <br>
                        <input type="radio" id="html" name="fav_language" value="HTML">
                        <label for="html" class="ms-2">Publik</label>
                        <br>
                        <input type="radio" id="css" name="fav_language" value="CSS">
                        <label for="css" class="ms-2">Member</label>
                        <br>
                        <input type="radio" id="javascript" name="fav_language" value="JavaScript">
                        <label for="javascript" class="ms-2">Pro</label>
                    </div>
                    <div class="form-group">
                        <label>Judul</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="form-group">
                        <label>Penulis</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="form-group">
                        <label>Abstrak/Intisari</label>
                        <textarea type="text" class="form-control"></textarea>
                    </div>
                    <div class="form-group">
                        <label>Kata Kunci</label>
                        <input type="text" class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-6 border-secondary" style="border-left: 1px solid grey; min-height: calc(100vh - 60px);">
                <div>
                    <b>Pengaturan</b>
                </div>
                <div class="form-group">
                    <label>Tipe Jurnal</label>
                    <select class="form-control">
                        <option value="article">Artikel</option>
                        <option value="infographic">Info Grafik</option>
                        <option value="legal_analysis">Analisis Hukum</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>No Issue</label>
                    <input type="text" class="form-control">
                </div>
                <div class="form-group">
                    <label>No Issue</label>
                    <input type="text" class="form-control">
                </div>
                <div class="form-group">
                    <label>p-ISSN</label>
                    <input type="text" class="form-control">
                </div>
                <div class="form-group">
                    <label>p-ISSN</label>
                    <input type="text" class="form-control">
                </div>
                <div class="form-group">
                    <label>Info Penerbit</label>
                    <textarea type="text" class="form-control"></textarea>
                </div>
                <div class="form-group">
                    <label>Tautan Asal</label>
                    <input type="text" class="form-control">
                </div>
                <div class="form-group">
                    <label>Tautan Penulis</label>
                    <input type="text" class="form-control">
                </div>
                <div class="form-group">
                    <label>File</label>
                    <input type="file" class="form-control">
                </div>

                <div class="text-right">
                    <button @click="$router.go(-1)" class="btn btn-sm btn-outline-theme mx-1">Batal</button>
                    <button class="btn btn-sm btn-theme mx-1">Simpan</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VueEditor } from "vue3-editor";
export default {
    data() {
        return {
            edit_mode: false,
            form: {
                id: '',
                title: '',
                body: '',
            },
        }
    },
    methods: {
        loadData() {
            this.authGet('panel/posts/' + this.$route.params.post_id)
                .then((data) => {
                    this.form = data.result
                });
        },
        loadType() {

        },
        handleImageAdded(file, Editor, cursorLocation, resetUploader) {
            var formData = new FormData();
            formData.append("file", file);
            formData.append("save_method", 'upload');

            this.authPost('panel/docs', formData)
                .then((data) => {
                    const url = data.result.link; // Get url from response
                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                })
                .catch(err => {
                    console.log(err);
                });
        },
        updateData() {

        },
        createData() {

        }
    },
    created() {
        let param = this.$route.params.post_id
        if (param != 'create') {
            this.loadData();
        }

    },
    components: { VueEditor }
}
</script>
<style>
.text-sm {
    font-size: 12px;
    font-weight: 600;
}

.text-sm span {
    color: rgb(119, 119, 119);
}
</style>